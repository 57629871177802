

import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../../../assets/loaders";


const LazyComponent = lazy(() => import("./IdcMatrix"));
export default function MasterCTD() {
    return (
      <Suspense fallback={<LazyLoader />}>
        <LazyComponent />
      </Suspense>
    );
  }