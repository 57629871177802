import { Button } from "react-bootstrap";
import "./Buttons.scss";

export const PrimaryButton = (props) => {
  return (
    <Button
      {...props}
      className={`tms-primary-btn ${props.className ? props.className : ""}`}
    >
      {props.title}
    </Button>
  );
};
