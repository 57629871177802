import React from "react";
import { Dropdown } from "react-bootstrap";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import { useNavigate } from "react-router-dom";
import options from "../../../assets/images/icons/options.svg";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import useConfig from "../../../hooks/useConfig";
import './ProjectDropdown.scss'

export default function ProjectsDropdown({ projectId, page, projectType }) {
  const { grantPermission } = PermissionFunctions();
  const navigate = useNavigate();
  const { downloadProgressReport } = MainServices();
  const config = useConfig();

  const handleProgressReportDownloadClick = async () => {
    const details = {
      projectId,
    };

    await axios
      .all([downloadProgressReport(details)])
      .then((response) => {
        if (response.length > 0) {
          const data = response[0].data;
          const fileName = data.response["fileName"];
          console.log(fileName);
          if (fileName) {
            const path = `${config.exportsUrl}engineer/${fileName}`;
            console.log(path);
            downloadProgressFile(path);
          } else {
            console.error("File path not found in response.");
          }
        } else {
          console.error("File path not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  const downloadProgressFile = (filePath) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", "progress_report.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  return (
    <Dropdown className="trainer-type-dropdown-main project-dropdown">
      <Dropdown.Toggle className="trainer-type-dropdown">
        <span>
          <img
            src={options}
            title="see more"
            style={{ width: "25px", height: "25px" }}
          />
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        { projectType === "non standard" && grantPermission("CTRDeliverable_read") && (
          <Dropdown.Item
            onClick={() =>
              navigate({
                pathname: "/ctr-deliverable",
                search: `projectId=${projectId}`,
              })
            }
          >
            <span> CTR</span>
          </Dropdown.Item>
        )}
        {page === "ctr" && (
          <>
            {grantPermission("ProjectCtd_read") && (
              <Dropdown.Item
                onClick={() =>
                  navigate({
                    pathname: "/project-management/projects/project-ctd",
                    search: `projectId=${projectId}`,
                  })
                }
              >
                <span> CTD</span>
              </Dropdown.Item>
            )}
            {grantPermission("ProjectActivity_read") && (
              <Dropdown.Item
                onClick={() =>
                  navigate({
                    pathname: "/project-management/project-ctr-activity",
                    search: `projectId=${projectId}`,
                  })
                }
              >
                <span>CTR Activity</span>
              </Dropdown.Item>
            )}

            {grantPermission("ProjectTps_read") && (
              <Dropdown.Item
                onClick={() =>
                  navigate({
                    pathname: "/project-management/projects/project-tps",
                    search: `projectId=${projectId}`,
                  })
                }
              >
                <span>TPS</span>
              </Dropdown.Item>
            )}
          </>
        )}

        {grantPermission("Document_read") && (
          <Dropdown.Item
            onClick={() =>
              navigate({
                pathname: "/documents/document-ddr-list",
                search: `?projectId=${projectId}`,
              })
            }
          >
            <span>DDR</span>
          </Dropdown.Item>
        )}

        {grantPermission("JobCircular_read")  && (
          <Dropdown.Item
            onClick={() => {
              navigate({
                pathname: "/project-management/projects/job-circular",
                search: `projectId=${projectId}`,
              });
            }}
          >
            <span>Job Circular</span>
          </Dropdown.Item>
        )}

        {grantPermission("Document_read") && (
          <Dropdown.Item
            onClick={() =>
              navigate({
                pathname: "/documents/document-list",
                search: `?projectId=${projectId}`,
              })
            }
          >
            <span>Document List</span>
          </Dropdown.Item>
        )}

        {grantPermission("Project_read") &&  projectType === "non standard" && (
          <Dropdown.Item
            onClick={() =>
              navigate({
                pathname: "/project-management/projects/project-summary",
                search: `?projectId=${projectId}`,
              })
            }
          >
            <span>Summary</span>
          </Dropdown.Item>
        )}

        {grantPermission("Project_read") && (
          <Dropdown.Item onClick={handleProgressReportDownloadClick}>
            <span title="Download File">Progress Report</span>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
