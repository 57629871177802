import React, { useEffect, useState } from "react";
import MainServices from "../../../services/MainServices";
import PageLoader from "../../../assets/loaders/PageLoading";
import "./addidcmatrix.scss";
import { Label } from "reactstrap";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Button } from "react-bootstrap";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";
import Cross from "../../../assets/images/icons/cross.svg";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { PrimaryInput } from "../../../assets/FormElements";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import ItemsDroppable from "../../../assets/dragDrop/ItemsDroppable";
import EachDragItem from "../../../assets/dragDrop/EachDragItem";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import StrictModeDroppable from "../../../assets/dnd/StrictModeDroppable";
import { squareBackIcon } from "../../../assets/images/icons/icons";
import axios from "axios";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";

export default function AddIDCMatrix() {
  const { getDepartmentsMaster, getEmployeesMaster } = MainServices();
  const [pageLoading, setPageLoading] = useState(false);
  const [discplines, setDisciplines] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);

  const [workflowModal, setWorkflowModal] = useState(false);
  const { AddIDCMasters, getIDCMasters, updateIDCMaster } = MainServices();
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedIDCMatrix, setSelectedIDCMatrix] = useState();
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("publicId");

  useEffect(() => {
    if (publicId) {
      getIDCMastersById();
    }
  }, [publicId]);

  const getIDCMastersById = async () => {
    setPageLoading(true);
    const details = {
      filters: [{ key: "publicId", eq: publicId }],
    };
    await axios
      .all([getIDCMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            setSelectedIDCMatrix(resData.data[0]);
            onManageWorkflow();
            setPageLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting IDC Matrix");
      });
  };

  const addIDCSchema = Yup.object().shape({
    workflow_name: Yup.string()
      .test(
        "not-only-whitespace",
        " Name  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("WorkFlow Name is Required"),
    workflows: Yup.mixed().required("WorkFlows is Required"),
  });

  useEffect(() => {
    getDepartments();
    getEmployeesByDiscipline({ value: "all" });
  }, []);

  const onManageWorkflow = () => {
    setWorkflowModal((prev) => !prev);
  };

  const getDepartments = async () => {
    const details = {
      pageSize: 100,
      pageNumber: 1,
    };
    setPageLoading(true);
    await getDepartmentsMaster(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data;
          resData = resData.map((each) => {
            return {
              ...each,
              label: each.deptName,
              value: each.publicId,
            };
          });
          resData.unshift({ label: "All", value: "all" });
          setDisciplines(resData);
          setPageLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };

  const getEmployeesByDiscipline = async (e) => {
    const details = {
      pageSize: 100,
      pageNumber: 1,
      ...(e.value !== "all" && {
        filters: [{ key: "deptId", eq: e.publicId }],
      }),
    };

    getEmployeesMaster(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data.map((each) => ({
            ...each,
            label: each.empName,
            value: each.publicId,
          }));

          setEmployees(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDragItem = ({ item, setFieldValue, workflow, values }) => {
    let workflows = values.workflows || [];
    workflows = workflows.map((eachW) => {
      if (eachW.workflow === workflow) {
        let users = eachW.users || [];
        let match = users.find((each) => each.value === item.value);
        if (!match) {
          users.push(item);
        }
        return {
          ...eachW,
          users,
        };
      }
      return eachW;
    });
    console.log(workflows);
    setFieldValue(workflows);
  };

  const onDragEnd = (result, set, values) => {
    const { source, destination, type } = result;

    // If dropped outside a droppable area
    if (!destination) {
      return;
    }

    // If the drag type is 'SECTION'

    const update_values = reorder(
      values.workflows,
      source.index,
      destination.index
    );
    set("workflows", update_values);

    // If the drag type is 'ITEM' within the same section
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const addIDC = async (values) => {
    let data = [];
    data =
      values.workflows &&
      values.workflows.length > 0 &&
      values.workflows[0].users;

    data = data.map((user) => {
      return {
        publicId: user.publicId,
        label: user.label,
        value: user.publicId,
        discipline: user?.deptartment?.deptName,
        designatiion: user?.designation?.desgName,
      };
    });

    const details = {
      name: values.workflow_name,
      data,
    };

    await axios
      .all([AddIDCMasters(details)])
      .then((res) => {
        if (res[0].success) {
          notifySuccess("WorkFlow Added Successfully");
          navigate(-1);
        } else {
          notifyWarn("Error in Adding WorkFlow");
        }
      })
      .catch((err) => console.log(err));
  };

  const updateIDC = async (values) => {
    let data = [];
    data =
      values.workflows &&
      values.workflows.length > 0 &&
      values.workflows[0].users;

    data = data.map((user) => {
      return {
        publicId: user.publicId,
        label: user.label,
        value: user.publicId,
        discipline: user?.deptartment
          ? user?.deptartment?.deptName
          : user?.discipline,
        designatiion: user?.designation?.desgName
          ? user?.designation?.desgName
          : user?.designatiion,
      };
    });

    const details = {
      name: values.workflow_name,
      data,
    };
    console.log("details", details);
    await axios
      .all([
        updateIDCMaster(
          details,
          selectedIDCMatrix?.publicId,
          selectedIDCMatrix?.concurrencyStamp
        ),
      ])
      .then((res) => {
        if (res[0].success) {
          notifySuccess("WorkFlow Updated Successfully");
          navigate(-1);
        } else {
          notifyWarn("Error in Updating WorkFlow");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div className="addidcmatrix-main">
          <div className="back-button">
            <img src={ArrowBack} alt="" onClick={() => navigate(-1)} />
          </div>

          <Formik
            initialValues={{
              workflow_name: selectedIDCMatrix?.name || "",

              workflows: [
                {
                  workflow: "Selected Employees List",
                  users: selectedIDCMatrix?.data || [],
                },
              ],
            }}
            onSubmit={(values) => {
              selectedIDCMatrix ? updateIDC(values) : addIDC(values);
            }}
            validationSchema={addIDCSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <>
                <div className="workflow-manager">
                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      className="p-0 content-header-main"
                      style={{ fontWeight: "700", fontSize: "24px" }}
                    >
                      ADD WorkFlow
                    </p>
                    <Button
                      onClick={onManageWorkflow}
                      className="theme-btn-main"
                    >
                      Manage WorkFlow
                    </Button>
                  </div>
                </div>
                <hr />

                <div className="workflows-list">
                  {values.workflows &&
                    values.workflows.length > 0 &&
                    values.workflows.map((each_work, index) => (
                      <div className="each-work-flow mt-3">
                        <div className="workflow-head d-flex justify-content-between">
                          <div>
                            <span
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {each_work.workflow}
                            </span>
                          </div>
                        </div>

                        <div className="users mt-2 px-3">
                          {each_work.users &&
                            each_work.users.length > 0 &&
                            each_work.users.map((user, subI) => (
                              <div className="each-user d-flex justify-content-between mt-2">
                                <div>
                                  <span>{subI + 1}.</span>
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {user.label}
                                  </span>
                                </div>
                                <div>
                                  <img
                                    alt="Cross"
                                    src={Cross}
                                    className="delete-topic"
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      let data = values?.workflows || [];
                                      let users = data[index].users || [];
                                      users = users.filter(
                                        (each, i) => subI !== i
                                      );
                                      data[index].users = users;
                                      setFieldValue("workflows", data);
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
                {values.workflows[0].users.length === 0 && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      width: "200px",
                      height: "200px",
                      backgroundColor: "white",
                      padding: "5px",
                    }}
                  >
                    Will Appear Here
                  </div>
                )}

                <div className="form-group align-items-center row mt-3">
                  <label
                    className="form-label col-4"
                    style={{ fontWeight: "600", fontSize: "16px" }}
                    for="document_name"
                  >
                    Workflow Name :<span className="required-star"> *</span>
                  </label>
                  <div className="form-control-wrap col-4">
                    <PrimaryInput
                      className="form-control"
                      id="workflow_name"
                      name="workflow_name"
                      placeholder="Enter workflow name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.workflow_name}
                    />
                    <CustomErrorMessage
                      name="workflow_name"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <hr />

                <div className="d-flex justify-content-end gap-3 mt-3">
                  <button
                    className="col-2 btn btn-primary"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-primary col-2"
                    onClick={handleSubmit}
                  >
                    {buttonLoading ? (
                      <ButtonLoading />
                    ) : selectedIDCMatrix ? (
                      "Update WorkFlow"
                    ) : (
                      "Add WorkFlow "
                    )}
                  </button>
                </div>

                <Modal
                  centered
                  show={workflowModal}
                  onHide={() => onManageWorkflow()}
                  className="add-department-modal-main"
                  size="xl"
                >
                  <Modal.Header
                    closeButton
                    className="add-department-modal-header"
                  >
                    <Modal.Title className="add-department-modal-title">
                      Manage Workflow
                    </Modal.Title>
                  </Modal.Header>

                  <Modal.Body className="add-department-modal-body">
                    <Formik
                      initialValues={{
                        workflows: values.workflows,
                        workflow: "",
                      }}
                      onSubmit={(m_values) => {
                        setFieldValue("workflows", m_values.workflows);
                        onManageWorkflow();
                      }}
                      key=""
                    >
                      {({
                        handleSubmit: m_submit,
                        setFieldValue: m_set,
                        values: m_values,
                        handleChange: m_change,
                        handleBlur: m_blur,
                      }) => (
                        <>
                          {console.log("workflows", m_values.workflows)}
                          <div className="addidcmatrix-main">
                            <div className="addidcmatrix-discpline col-12">
                              <div className="row align-items-center form-group mt-3 mb-3">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                  <Label>Discipline</Label>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                  <Select
                                    className="sop-custom-form-select"
                                    id="deptartment"
                                    name="deptartment"
                                    placeholder="---Select Discipline---"
                                    onChange={(e) => {
                                      setSelectedOption(e);
                                      getEmployeesByDiscipline(e);
                                    }}
                                    value={selectedOption}
                                    options={discplines}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="d-flex gap-2"
                            style={{ width: "100%" }}
                          >
                            <div
                              style={{
                                width: "49%",
                                height: 500,
                                overflowY: "scroll",
                              }}
                            >
                              <h6
                                className="mt-3"
                                style={{ fontWeight: "700" }}
                              >
                                WorkFlows
                              </h6>
                              <div className="d-flex gap-2 mb-3">
                                <DragDropContext
                                  onDragEnd={(result) =>
                                    onDragEnd(result, m_set, m_values)
                                  }
                                >
                                  <StrictModeDroppable
                                    droppableId="workflows"
                                    direction="vertical"
                                  >
                                    {(sectionsProvided) => (
                                      <div
                                        style={{
                                          width: "100%",
                                        }}
                                        {...sectionsProvided.droppableProps}
                                        ref={sectionsProvided.innerRef}
                                      >
                                        {m_values.workflows.map(
                                          (section, sectionIndex) => (
                                            <Draggable
                                              key={section.workflow}
                                              draggableId={section.workflow}
                                              index={sectionIndex}
                                              style={{ width: "100%" }}
                                            >
                                              {(sectionProvided) => (
                                                <div
                                                  {...sectionProvided.draggableProps}
                                                  {...sectionProvided.dragHandleProps}
                                                  ref={sectionProvided.innerRef}
                                                >
                                                  <ItemsDroppable
                                                    onDrag={(props) =>
                                                      onDragItem({
                                                        ...props,
                                                        workflow:
                                                          section.workflow,
                                                        values: m_values,
                                                      })
                                                    }
                                                    setFieldValue={m_set}
                                                    dataType="Available"
                                                  >
                                                    <div className="each-section my-3">
                                                      <div className="d-flex justify-content-between">
                                                        <h6
                                                          style={{
                                                            textTransform:
                                                              "capitalize",
                                                            fontWeight: "600",
                                                          }}
                                                        >
                                                          Drag and Drop
                                                          Employees Here
                                                        </h6>
                                                      </div>
                                                      <div className="users mt-2 px-3">
                                                        {section.users &&
                                                          section.users.length >
                                                            0 &&
                                                          section.users.map(
                                                            (user, subI) => (
                                                              <div className="each-user d-flex justify-content-between mt-2">
                                                                <div>
                                                                  <span>
                                                                    {subI + 1}.
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                  >
                                                                    {user.label}
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <img
                                                                    alt="Cross"
                                                                    src={Cross}
                                                                    className="delete-topic"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      let data =
                                                                        m_values?.workflows ||
                                                                        [];
                                                                      let users =
                                                                        data[
                                                                          sectionIndex
                                                                        ]
                                                                          .users ||
                                                                        [];
                                                                      users =
                                                                        users.filter(
                                                                          (
                                                                            each,
                                                                            i
                                                                          ) =>
                                                                            subI !==
                                                                            i
                                                                        );
                                                                      data[
                                                                        sectionIndex
                                                                      ].users =
                                                                        users;
                                                                      m_set(
                                                                        "workflows",
                                                                        data
                                                                      );
                                                                    }}
                                                                  />
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                    </div>
                                                  </ItemsDroppable>
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                        {sectionsProvided.placeholder}
                                      </div>
                                    )}
                                  </StrictModeDroppable>
                                </DragDropContext>
                              </div>
                            </div>
                            <div className="available" style={{ width: "49%" }}>
                              <ItemsDroppable
                                onDrag={onDragItem}
                                setFieldValue={setFieldValue}
                                dataType="Available"
                                style={{ height: 500 }}
                              >
                                <h5>Employees</h5>
                                {employees &&
                                  employees.length > 0 &&
                                  employees.map((_, index) => (
                                    <EachDragItem props={_} type="availble" />
                                  ))}
                              </ItemsDroppable>
                            </div>
                          </div>

                          <div className="mt-3">
                            <div className="d-flex gap-2">
                              <Button
                                className="back-icon-btn-main"
                                onClick={() => {
                                  onManageWorkflow();
                                }}
                              >
                                <span>{squareBackIcon}</span>
                                <span className="ms-2">Back</span>
                              </Button>
                              <Button
                                className="theme-btn-main"
                                onClick={m_submit}
                              >
                                {buttonLoading ? <ButtonLoading /> : "Submit"}
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Formik>
                  </Modal.Body>
                </Modal>
              </>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}
