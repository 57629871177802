import React, { useState, useEffect, useRef } from "react";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/remove.svg";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import { Table, Label } from "reactstrap";
import IconButton from "../../../assets/elements/IconButton";
import Pagination from "../../../assets/elements/CustomPagination";
import SuccessModal from "../../../assets/elements/SuccessModal";
import "./CheckLists.scss";
import { InputGroup, Form, Button, Modal, Dropdown } from "react-bootstrap";
import { PrimaryInput } from "../../../assets/FormElements";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { squareBackIcon } from "../../../assets/images/icons/icons";
import useCurrentPage from "../../../hooks/useCurrentPage";
import axios from "axios";
import { notifySuccess } from "../../../assets/elements/Toast";
import { notifyWarn } from "../../../assets/elements/Toast";
import MainServices from "../../../services/MainServices";
import moment from "moment";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import PageLoader from "../../../assets/loaders/PageLoading";
import { useLocation, useNavigate } from "react-router-dom";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import { PrimaryButton } from "../../../assets/buttons";
import plusIcon from "../../../assets/images/icons/add.svg";
import useAuth from "../../../hooks/useAuth";
import { ButtonLoader } from "../../../assets/loaders";

const allStatus = [
  { label: "All", value: "" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const CheckListItem = () => {
  const [successModal, setSuccessModal] = useState();
  const [successType, setSuccessType] = useState();
  const [addChecklistModal, setAddChecklistModal] = useState(false);
  const [deleteChecklistModal, setDeleteChecklistModal] = useState(false);
  const { updateCurrentPage } = useCurrentPage();
  const [checklistMasters, setChecklistMasters] = useState([]);
  const [checklistitemMasters, setChecklistitemMasters] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);
  const [totalChecklist, setTotalChecklist] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [disciplineChange, setDisciplineChange] = useState(false);
  const {
    getChecklistItem,
    addChecklistItem,
    updateChecklistItemMaster,
    deleteCheckListItemMaster,
    getChecklistMasters,
    importCheckListItems,
    getQueueStatus,
  } = MainServices();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const { user } = useAuth();
  const itemsTimeOutRef = useRef(null);
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const [documentDepartments, setDocumentDepartments] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const { deBounce } = TimingFunctions();
  const { grantPermission } = PermissionFunctions();
  const [checkList, setCheckList] = useState();
  const [importCheckListItemsModal, setImportCheckListItemsModal] =
    useState(false);
  const [importLoading, setImportLoading] = useState(false);

  const checklistSchema = Yup.object().shape({
    checkListItemName: Yup.string()
      .test(
        "not-only-whitespace",
        " Checklist Name  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Checklist Name is Required"),
    // deptId: Yup.mixed().required("Department Selection is Required"),
  });

  //url Params
  const location = useLocation();
  const publicId = new URLSearchParams(location.search).get("publicId");
  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  useEffect(() => {
    updateCurrentPage({
      title: "Master",
      subtitle: "Document Types",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setSelectedStatus(filtersObj?.status?.status || "");
    setFilters(filtersObj);
    setSearchString(filtersObj?.name?.name);
    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 50,
    });

    getCheckListItemByStatus({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 50,
      filters: [{ key: "CheckListMasterId", eq: publicId }, ...filters],
    });
    // eslint-disable-next-line
  }, [changedParams.page, changedParams.limit, changedParams.filters]);

  useEffect(() => {
    getCheckListsByStatus();
  }, [publicId]);

  //apis

  const getCheckListsByStatus = async () => {
    setPageLoading(true);
    const details = { filters: [{ key: "publicId", eq: publicId }] };
    await axios
      .all([getChecklistMasters(details)])
      .then((res) => {
        let resData = res[0];
        console.log("dataaaaa", resData);
        if (resData.success) {
          let checkListMaster = resData.data;
          console.log("IIIEEE", checkListMaster);

          // checkListMaster = checkListMaster.map((each) => {
          //   return {
          //     ...each,
          //     label: each.checkListName,
          //     value: each.publicId,
          //   };
          // });
          setChecklistMasters(checkListMaster);
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Checklists");
      });
  };

  const getCheckListItemByStatus = async ({ page, limit, filters }) => {
    setPageLoading(true);
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
    };
    await axios
      .all([getChecklistItem(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let doctypes = resData.data ? resData.data : [];
            let total = resData?.totalCount || 0;
            setTotalChecklist(total);
            doctypes = doctypes.map((_, index) => {
              return {
                ..._,
                sNo: limit * (page - 1) + index + 1,
              };
            });
            console.log("ITEMM", doctypes);
            setChecklistitemMasters(doctypes);
            setPageLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Checklists Item");
      });
  };

  const addCheckList = async (values) => {
    setButtonLoading(true);
    const details = {
      checkListItemName: values.checkListItemName,
      checkListMasterId: publicId,
      status: "active",
    };
    await axios
      .all([addChecklistItem(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getCheckListItemByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters: [{ key: "checkListMasterId", eq: publicId }],
            });
            onAddChecklist();
            notifySuccess("Checklist Item Added Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding Checklist Master");
      });
  };

  const updateCheckList = async (values) => {
    setButtonLoading(true);
    const details = {
      checkListItemName: values.checkListItemName,
      checkListMasterId: publicId,
    };
    await axios
      .all([
        updateChecklistItemMaster(
          details,
          selectedChecklist.publicId,
          selectedChecklist.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getCheckListItemByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters: [{ key: "checkListMasterId", eq: publicId }],
            });
            onAddChecklist();
            notifySuccess("Checklist Master Updated Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Checklist Master");
      });
  };

  const deleteCheckList = async () => {
    setButtonLoading(true);
    const details = {
      status: "inactive",
    };
    await axios
      .all([
        deleteCheckListItemMaster(
          details,
          selectedChecklist.publicId,
          selectedChecklist.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getCheckListItemByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters: [{ key: "checkListMasterId", eq: publicId }],
            });
            onDeleteChecklist();
            notifySuccess("Checklist Master Deleted Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in deleting  Master");
      });
  };

  //functions
  const onAddChecklist = (selected) => {
    if (selected) {
      setSelectedChecklist(selected);
    } else {
      setSelectedChecklist("");
    }
    setAddChecklistModal(!addChecklistModal);
  };

  const onDeleteChecklist = (selected) => {
    if (selected) {
      setSelectedChecklist(selected);
    } else {
      setSelectedChecklist("");
    }
    setDeleteChecklistModal(!deleteChecklistModal);
  };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };
  //pagination
  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
    });
    navigate({ search });
  };

  const searchDocumentType = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      checkListItemName: { checkListItemName: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: 50,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  //import checklist items
  const onImportCheckListItems = () => {
    setImportCheckListItemsModal(!importCheckListItemsModal);
  };

  const checklistItemsImportingSchema = Yup.object().shape({
    fileName: Yup.string().required("Select file to be import"),
  });

  const uploadFile = async (values) => {
    setButtonLoading(true);
    const details = {
      checklistMaster:publicId
    };
    const documents = values.fileName;


    await axios
      .all([importCheckListItems(documents, details, user?.token)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          onImportCheckListItems();
          setButtonLoading(false);

          let jobId = res[0]?.data?.data?.jobId;
          if (jobId) {
            setImportLoading(true);
            itemsTimeOutRef.current = setTimeout(() => {
              getUploadStatus(jobId);
            }, 1000);
          }
        }
      })
      .catch(function (res) {
        setButtonLoading(false);
        if (res.message) console.log("An error occurred in file upload");
      });
  };

  const getUploadStatus = async (jobId) => {
    const details = {};

    await axios
      .all([getQueueStatus(details, jobId)])
      .then((res) => {
        if (res[0]) {
          let status = res[0]?.data?.import_status;

          if (status === "completed") {
            notifySuccess("Checklist Items imported successfully");
            clearTimeout(itemsTimeOutRef.current);
            getCheckListItemByStatus({
              page: pagination?.page || 1,
              limit: pagination.limit || 50,
              filters: [{ key: "CheckListMasterId", eq: publicId }],
            });
             
            setImportLoading(false);
          } else {
            itemsTimeOutRef.current = setTimeout(() => {
              getCheckListItemByStatus({
                page: pagination?.page || 1,
                limit: pagination.limit || 50,
                filters: [{ key: "CheckListMasterId", eq: publicId }],
              });
              getUploadStatus(jobId);
            }, 3000);
          }
        
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="doctype-main">
      <SuccessModal
        modal={successModal}
        setModal={setSuccessModal}
        message="Feature Service"
        type={successType}
      />

      <div className="header">
        <div className="d-flex gap-3">
          <img
            src={ArrowBack}
            alt=""
            width={"20px"}
            onClick={() => navigate(-1)}
          />
          <p className="content-header-main">
            {checklistMasters.length > 0 && checklistMasters[0]?.checkListName}
          </p>
        </div>
        <div className="d-flex gap-3">
        {importLoading && <ButtonLoader  color="black" />}
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchDocumentType(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>

          <div className="product-list-header-flex" style={{ width: "8rem" }}>
            <select
              class="form-control form-select custom-status-select"
              id="fv-topics"
              name="status"
              data-placeholder="Select a option"
              required
              onChange={onStatusChange}
              value={selectedStatus}
            >
              <option label="Select Status" value=""></option>
              {allStatus.map((each) => (
                <option value={each.value} key={each.value}>
                  {each.label}
                </option>
              ))}
            </select>
          </div>

          {/* {grantPermission("CheckListMaster_write") && (
            <IconButton
              iconType="add"
              title="Add Checklist Item"
              onClick={() => onAddChecklist("")}
            />
          )} */}
          {grantPermission("CheckListMaster_write") && (
            <Dropdown className="trainer-type-dropdown-main">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="trainer-type-dropdown"
              >
                <span className="me-2">
                  <img src={plusIcon} alt="plus icon" />
                </span>
                <span>CheckList</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onAddChecklist()}>
                  Add Checklist
                </Dropdown.Item>
                <Dropdown.Item onClick={onImportCheckListItems}>Import Checklist</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : checklistitemMasters && checklistitemMasters.length > 0 ? (
        <div className="content">
          <div className="table-main">
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr>
                  <th>S.No.</th>
                  <th>Checklist Item Name</th>
                  <th>Created Date</th>
                  <th>Status</th>
                  {(grantPermission("CheckListMaster_delete") ||
                    grantPermission("CheckListMaster_update")) && (
                    <th>Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {checklistitemMasters.map((each) => {
                  return (
                    <tr>
                      <td>{each?.sNo}</td>
                      <td>{each?.checkListItemName}</td>
                      <td>{moment(each?.createdAt).format("DD-MM-YYYY")}</td>
                      <td>{each?.status}</td>
                      {(grantPermission("CheckListMaster_delete") ||
                        grantPermission("CheckListMaster_update")) && (
                        <td className="options">
                          <div className="d-flex gap-2">
                            {grantPermission("CheckListMaster_update") &&
                              each?.status === "active" && (
                                <div
                                  onClick={() => onAddChecklist(each)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={editicon} alt="Edit icon" />
                                </div>
                              )}
                            {grantPermission("CheckListMaster_delete") && (
                              <div
                                onClick={() => onDeleteChecklist(each)}
                                style={{ cursor: "pointer" }}
                              >
                                <img src={removeicon} alt="Remove icon" />
                              </div>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="no-data-found-main">No Checklist Item Found</div>
      )}
      {checklistitemMasters && checklistitemMasters.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalChecklist}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
              onRowsPerPage={onRowsPerPage}
            />
          </div>
        </div>
      )}

      <Modal
        centered
        show={addChecklistModal}
        onHide={onAddChecklist}
        className="add-department-modal-main"
        selected={selectedChecklist}
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Add Checklist Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <p className="mb-4 add-department-modal-content-text">
            Please Enter details for adding checklist Item
          </p>
          <Formik
            initialValues={{
              checkListItemName: selectedChecklist?.checkListItemName || "",
              checkListMasterId: selectedChecklist?.checkListMasterId || "",
            }}
            validationSchema={checklistSchema}
            onSubmit={(values) =>
              selectedChecklist ? updateCheckList(values) : addCheckList(values)
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="form-group mt-3">
                  <PrimaryInput
                    placeholder="Enter Checklist Item Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.checkListItemName}
                    name="checkListItemName"
                    id="checkListItemName"
                    style={{
                      border: "none",
                      background: "#f8f8fa",
                      borderRadius: "8px",
                    }}
                  />
                  <CustomErrorMessage
                    name="checkListItemName"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="mt-3">
                  <div className="row">
                    <div className="col-6">
                      <Button
                        className="back-icon-btn-main"
                        onClick={onAddChecklist}
                        style={{ width: "100%" }}
                      >
                        <span>{squareBackIcon}</span>
                        <span className="ms-2">Back</span>
                      </Button>
                    </div>
                    <div className="col-6">
                      <PrimaryButton
                        className="theme-btn-main"
                        type="submit"
                        title={
                          buttonLoading ? (
                            <ButtonLoading />
                          ) : selectedChecklist ? (
                            "Update changes"
                          ) : (
                            "Save changes"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={deleteChecklistModal}
        onHide={onDeleteChecklist}
        className="add-department-modal-main"
        selected={selectedChecklist}
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Inactivate Check List Item
          </Modal.Title>
        </Modal.Header>

        {deleteChecklistModal && (
          <Modal.Body className="add-department-modal-body">
            <p>
              Do you want to make this Check List Item{" "}
              <span style={{ color: "#9d72ff", textDecoration: "underline" }}>
                {selectedChecklist.checkListName}
              </span>{" "}
              Inactive ?
            </p>

            <div className="mt-3">
              <div className="d-flex gap-2">
                <Button
                  className="back-icon-btn-main"
                  onClick={onDeleteChecklist}
                >
                  <span>{squareBackIcon}</span>
                  <span className="ms-2">Back</span>
                </Button>
                <PrimaryButton
                  className="theme-btn-main"
                  onClick={deleteCheckList}
                  title={buttonLoading ? <ButtonLoading /> : "Inactivate"}
                />
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
      <Modal
        centered
        show={importCheckListItemsModal}
        onHide={onImportCheckListItems}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Import Checklist Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              fileName: "",
            }}
            validationSchema={checklistItemsImportingSchema}
            onSubmit={(values) => uploadFile(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="row align-items-center form-group mb-3">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <Label>Checklist Items</Label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                    <PrimaryInput
                      type="file"
                      onChange={(e) => {
                        setFieldValue("fileName", e?.target?.files[0]);
                      }}
                      onBlur={handleBlur}
                      accept=".xlsx,.xls"
                      name=" fileName"
                      id="fileName"
                    />
                    <CustomErrorMessage
                      name="fileName"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <div className="row">
                    <div className="col-6">
                      <Button
                        className="back-icon-btn-main"
                        onClick={onImportCheckListItems}
                        style={{ width: "100%" }}
                      >
                        <span className="ms-2">Back</span>
                      </Button>
                    </div>
                    <div className="col-6">
                      <PrimaryButton
                        className="theme-btn-main"
                        type="submit"
                        title={buttonLoading ? <ButtonLoading /> : "Submit"}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CheckListItem;
