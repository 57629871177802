import React, { useEffect, useState } from "react";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import "./projectSummary.scss";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import { Table } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import export1 from "../../../assets/images/icons/export1.svg";
import options from "../../../assets/images/icons/options.svg";
import ProjectsDropdown from "./ProjectDropdown";

export default function ProjectSummary() {
  const location = useLocation();
  const navigate = useNavigate();
  const projectId = new URLSearchParams(location.search).get("projectId");
  const [selectedSummary, setSelectedSummary] = useState("hourSummary");
  const [hourSummaryReport, setHourSummaryReport] = useState([]);
  const [resourceSummaryReport, setResourceSummaryReport] = useState([]);
  const [costSummaryReport, setCostSummaryReport] = useState([]);
  const [resourceCost, setResourceCost] = useState();
  const { grantPermission } = PermissionFunctions();
  const [project, setProject] = useState();
  const [pageLoading, setPageLoading] = useState(false);

  const {
    getProjectDetails,
    downloadProgressReport,
    getProjectHourSummary,
    getProjectResourceSummary,
    getProjectCostSummary,
    exportProjectCTR,
  } = MainServices();

  const summaries = [
    { label: "Hour Summary", value: "hourSummary" },
    { label: "Cost Summary", value: "costSummary" },
    { label: "Resource Summary", value: "resourceSummary" },
  ];

  useEffect(() => {
    if (selectedSummary === "hourSummary") {
      getProjectHourSummaryByProjectId();
    }
    if (selectedSummary === "resourceSummary") {
      getProjectResourceSummaryByProjectId();
    }
    if (selectedSummary === "costSummary") {
      getProjectCostSummaryByProjectId();
    }
  }, [selectedSummary]);

  useEffect(() => {
    if (projectId) getProjectDetailsPage();

    // eslint-disable-next-line
  }, []);

  const getProjectDetailsPage = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getProjectDetails(details, projectId)])
      .then(function (res) {
        if (res) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : {};
            setProject(resData);

            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Projects details");
      });
  };

  const handleProgressReportDownloadClick = async () => {
    const details = {
      projectId,
    };

    await axios
      .all([downloadProgressReport(details)])
      .then((response) => {
        if (response.length > 0) {
          const data = response[0].data;
          const fileName = data.response["fileName"];
          console.log(fileName);
          if (fileName) {
            const path = `https://files.appsarizona.com/galfar-apis/server/exports/engineer/${fileName}`;
            console.log(path);
            downloadProgressFile(path);
          } else {
            console.error("File path not found in response.");
          }
        } else {
          console.error("File path not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  const downloadProgressFile = (filePath) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", "progress_report.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  const getProjectCostSummaryByProjectId = async () => {
    const details = {};
    await axios
      .all([getProjectCostSummary(details, projectId)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0]?.data?.data;

          let costData = resData?.costSummary;
          costData = costData.map((cost, index) => {
            return {
              ...cost,
              sNo: index + 1,
            };
          });
          setCostSummaryReport(costData);
          let resourceCost = resData?.resourceCost;
          setResourceCost(resourceCost);
        }
      })
      .catch((res) => console.log(res));
  };

  const getProjectResourceSummaryByProjectId = async () => {
    const details = {};
    await axios
      .all([getProjectResourceSummary(details, projectId)])
      .then((res) => {
        let resData = res.length > 0 ? res[0].data : [];
        let resourceData = resData?.data;
        resourceData = resourceData.map((resource, index) => {
          return {
            ...resource,
            sNo: index + 1,
          };
        });
        setResourceSummaryReport(resourceData);
      })
      .catch((res) => console.log(res));
  };

  const getProjectHourSummaryByProjectId = async () => {
    const details = {};
    await axios
      .all([getProjectHourSummary(details, projectId)])
      .then((res) => {
        let resData = res.length > 0 ? res[0].data : [];
        let hourData = resData?.data;

        hourData = hourData.map((hour, index) => {
          return {
            ...hour,
            sNo: index + 1,
          };
        });
        setHourSummaryReport(hourData);
      })
      .catch((res) => console.log(res));
  };

  const handleExportClick = async () => {
    const details = {
      projectId,
    };
    await axios
      .all([exportProjectCTR(details)])
      .then((response) => {
        if (response.length > 0) {
          const {
            file: { filePath },
          } = response[0].data;
          if (filePath) {
            downloadFile(filePath);
          } else {
            console.error("File path not found in response.");
          }
        } else {
          console.error("File path not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };
  const downloadFile = (filePath) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", "project_ctr.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  return (
    <div className="project-summary-main">
      <div className="d-flex justify-content-between ">
        <div className="d-flex gap-3">
          <div className="back-btn-function">
            <img src={ArrowBack} alt="" onClick={() => navigate(-1)} />
          </div>
          <div style={{ fontWeight: "700" }}> Project Summary</div>
        </div>
        <div className="d-flex gap-3 align-items-center">
          {grantPermission("Document_read") && (
            <div
              // iconType="add"
              title="Export File"
              onClick={handleExportClick}
              className="folder-pic"
            >
              <img
                src={export1}
                alt=""
                style={{ width: "20px", height: "25px" }}
              />
            </div>
          )}
          <div style={{ minWidth: "200px" }}>
            <Select
              className="sop-custom-form-select"
              id="project-summary"
              name="project-summary"
              //placeholder="---Select Summary---"
              onChange={(e) => {
                console.log("e", e.value);
                setSelectedSummary(e.value);
              }}
              //  value={selectedOption}
              options={summaries}
              defaultValue={summaries[0]}
            />
          </div>
          <ProjectsDropdown
            projectId={project?.publicId}
            projectType={project?.projectType}
            page="summary"
          />
        </div>
      </div>
      {selectedSummary === "hourSummary" && (
        <div className="mt-3 summary-table-main">
          <div>
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <th colSpan="1" rowSpan="2">
                    S.No.
                  </th>
                  <th colSpan="1" rowSpan="2">
                    Discipline
                  </th>
                  <th colSpan="1" rowSpan="2">
                    Discipline Code
                  </th>

                  <th colSpan="2" rowSpan="1">
                    Deliverables
                  </th>

                  <th colSpan="1" rowSpan="2">
                    Activities
                    <br /> Manhours
                  </th>
                  <th colSpan="1" rowSpan="2">
                    Total Hours
                  </th>
                  <th colSpan="1" rowSpan="2">
                    Remarks
                  </th>
                </tr>
                <tr>
                  <th colSpan="1">Hour</th>
                  <th colSpan="1">Nos</th>
                </tr>
              </thead>
              <tbody>
                {hourSummaryReport.map((each) => {
                  return (
                    <tr>
                      <td>{each.sNo}</td>

                      <td>{each?.discipline || "-"}</td>

                      <td>{each?.disciplineCode || "-"}</td>
                      <td> {each?.deliverables?.Hour || "-"} </td>
                      <td> {each?.deliverables?.Nos || "-"} </td>
                      <td>{each?.activitiesManhours}</td>
                      <td>{each?.totalHours}</td>
                      <td>{each?.Remarks || "-"}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}

      {selectedSummary === "resourceSummary" && (
        <div className="mt-3 summary-table-main">
          <div>
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr style={{ textAlign: "center", verticalAlign: "middle" }}>
                  <th colSpan="1" rowSpan="1">
                    S.No.
                  </th>
                  <th colSpan="1" rowSpan="1">
                    Discipline
                  </th>
                  <th colSpan="1" rowSpan="1">
                    Discipline
                    <br /> Code
                  </th>

                  <th colSpan="1" rowSpan="1"></th>

                  <th colSpan="1" rowSpan="1">
                    B02-01
                  </th>
                  <th colSpan="1" rowSpan="1">
                    B02-02
                  </th>
                  <th colSpan="1" rowSpan="1">
                    B02-03
                  </th>
                  <th colSpan="1" rowSpan="1">
                    B02-04
                  </th>
                  <th colSpan="1" rowSpan="1">
                    B02-05
                  </th>
                  <th colSpan="1" rowSpan="1">
                    B02-06
                  </th>
                  <th colSpan="1" rowSpan="1">
                    B02-07
                  </th>

                  <th colSpan="1" rowSpan="1">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {resourceSummaryReport.map((each, index) => (
                  <React.Fragment key={index}>
                    <tr style={{ verticalAlign: "middle" }}>
                      <td rowSpan="4">{each.sNo}</td>
                      <td rowSpan="4">{each?.Discipline || "-"}</td>
                      <td rowSpan="4">{each["Discipline Code"] || "-"}</td>
                      <td>Deliverable</td>
                      <td>{each?.Deliverable["B02-01"].toFixed(2)}</td>
                      <td>{each?.Deliverable["B02-02"].toFixed(2)}</td>
                      <td>{each?.Deliverable["B02-03"].toFixed(2)}</td>
                      <td>{each?.Deliverable["B02-04"].toFixed(2)}</td>
                      <td>{each?.Deliverable["B02-05"].toFixed(2)}</td>
                      <td>{each?.Deliverable["B02-06"].toFixed(2)}</td>
                      <td>{each?.Deliverable["B02-07"].toFixed(2)}</td>
                      <td>{each?.Deliverable["TOTAL"].toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Activity</td>
                      <td>{each?.Activity["B02-01"].toFixed(2)}</td>
                      <td>{each?.Activity["B02-02"].toFixed(2)}</td>
                      <td>{each?.Activity["B02-03"].toFixed(2)}</td>
                      <td>{each?.Activity["B02-04"].toFixed(2)}</td>
                      <td>{each?.Activity["B02-05"].toFixed(2)}</td>
                      <td>{each?.Activity["B02-06"].toFixed(2)}</td>
                      <td>{each?.Activity["B02-07"].toFixed(2)}</td>
                      <td>{each?.Activity["TOTAL"].toFixed(2)}</td>
                    </tr>
                    <tr style={{ backgroundColor: "green" }}>
                      <td style={{ backgroundColor: "#B3DDB3" }}>Total</td>
                      <td style={{ backgroundColor: "#B3DDB3" }}>
                        {each?.Total["B02-01"].toFixed(2)}
                      </td>
                      <td style={{ backgroundColor: "#B3DDB3" }}>
                        {each?.Total["B02-02"].toFixed(2)}
                      </td>
                      <td style={{ backgroundColor: "#B3DDB3" }}>
                        {each?.Total["B02-03"].toFixed(2)}
                      </td>
                      <td style={{ backgroundColor: "#B3DDB3" }}>
                        {each?.Total["B02-04"].toFixed(2)}
                      </td>
                      <td style={{ backgroundColor: "#B3DDB3" }}>
                        {each?.Total["B02-05"].toFixed(2)}
                      </td>
                      <td style={{ backgroundColor: "#B3DDB3" }}>
                        {each?.Total["B02-06"].toFixed(2)}
                      </td>
                      <td style={{ backgroundColor: "#B3DDB3" }}>
                        {each?.Total["B02-07"].toFixed(2)}
                      </td>
                      <td style={{ backgroundColor: "#B3DDB3" }}>
                        {each?.Total["TOTAL"].toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Resource Ratio</td>
                      <td>{each?.resourceRatio["B02-01"]} %</td>
                      <td>{each?.resourceRatio["B02-02"]} %</td>
                      <td>{each?.resourceRatio["B02-03"]} %</td>
                      <td>{each?.resourceRatio["B02-04"]} %</td>
                      <td>{each?.resourceRatio["B02-05"]} %</td>
                      <td>{each?.resourceRatio["B02-06"]} %</td>
                      <td>{each?.resourceRatio["B02-07"]} %</td>
                      <td>{each?.resourceRatio["TOTAL"]} %</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      {selectedSummary === "costSummary" && (
        <div className="mt-3 summary-table-main">
          <div>
            <Table responsive bordered className="table table-bordered">
              <thead style={{ verticalAlign: "middle", textAlign: "center" }}>
                <tr>
                  <th rowSpan="2">SNo</th>
                  <th rowSpan="1">Disipline</th>
                  <th rowSpan="1">Disipline</th>
                  <th rowSpan="1">B02-01</th>
                  <th rowSpan="1">B02-02</th>
                  <th rowSpan="1">B02-03</th>
                  <th rowSpan="1">B02-04</th>
                  <th rowSpan="1">B02-05</th>
                  <th rowSpan="1">B02-06</th>
                  <th rowSpan="1">B02-07</th>
                  <th rowSpan="1">TOTAL(OMR)</th>
                </tr>
                <tr>
                  <th>Contract Rates</th>
                  <th>OMR/hr</th>
                  <th>{resourceCost?.B02_01}</th>
                  <th>{resourceCost?.B02_02}</th>
                  <th>{resourceCost?.B02_03}</th>
                  <th>{resourceCost?.B02_04}</th>
                  <th>{resourceCost?.B02_05}</th>
                  <th>{resourceCost?.B02_06}</th>
                  <th>{resourceCost?.B02_07}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {costSummaryReport.map((each, index) => {
                  return (
                    <>
                      <tr style={{ textAlign: "center" }}>
                        <td>{each?.sNo}</td>
                        <td>{each?.discipline}</td>
                        <td>{each?.disciplineCode}</td>
                        <td>{each["B02-01"]}</td>
                        <td>{each["B02-02"]}</td>
                        <td>{each["B02-03"]}</td>
                        <td>{each["B02-04"]}</td>
                        <td>{each["B02-05"]}</td>
                        <td>{each["B02-06"]}</td>
                        <td>{each["B02-07"]}</td>
                        <td>{each?.TOTAL}</td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}
