import React, { useEffect, useRef, useState } from "react";
import "./projectCTRActivity.scss";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";
import { InputGroup, Form, Button, Modal, Dropdown } from "react-bootstrap";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import plusIcon from "../../../assets/images/icons/add.svg";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import { Formik } from "formik";
import * as Yup from "yup";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import Select from "react-select";
import { PrimaryInput } from "../../../assets/FormElements";
import { Table } from "reactstrap";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/remove.svg";
import { toast } from "react-toastify";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import Pagination from "../../../assets/elements/CustomPagination";
import PageLoader from "../../../assets/loaders/PageLoading";
import { ButtonLoader, LazyLoader } from "../../../assets/loaders";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import useCurrentPage from "../../../hooks/useCurrentPage";
import useAuth from "../../../hooks/useAuth";
import AsyncSelect from "react-select/async";
import viewicon from "../../../assets/images/icons/viewicon.svg";
import moment from "moment";
import options from '../../../assets/images/icons/options.svg'
import ProjectsDropdown from "../projects/ProjectDropdown";


export default function ProjectCTRActivity() {
  const location = useLocation();
  const navigate = useNavigate();
  const projectIdd = new URLSearchParams(location.search).get("projectId");
  const [filters, setFilters] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const {
    getProjectDetails,
    downloadProgressReport,
    getProjectCTRActivity,
    getCTRActivityMasters,
    addProjectCTRActivity,
    updateProjectCTRActivity,
    deleteProjectCTRActivity,
    getDepartmentsMaster,
    importProjectCTRActivity,
    getQueueStatus,
    getRevisionProjectCTRActivity,
  } = MainServices();
  const { grantPermission } = PermissionFunctions();
  const [selectedProjectCTRActivity, setSelectedProjectCTRActivity] =
    useState();
  const [onAddProjectCTRActivity, setOnAddProjectCTRActivity] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [CTRActivityList, setCTRActivityList] = useState([]);
  const [discplines, setDisciplines] = useState([]);
  const [projectCTRActivityList, setProjectCTRActivityList] = useState([]);
  const [onDeleteProjectCTRActivity, setOnDeleteProjectCTRActivity] =
    useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { deBounce } = TimingFunctions();
  const { updateCurrentPage } = useCurrentPage();
  const [projectId, setProjectId] = useState(projectIdd);
  const params = new URLSearchParams(location.search);
  const { user } = useAuth();

  const changedParams = Object.fromEntries(params);
  const [importProjectCTRActivityModal, setImportProjectCTRActivityModal] =
    useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [onRevision, setOnRevision] = useState(false);
  const [revisionProjectCTRActivity, setRevisionProjectCTRActivity] =
    useState();

    const [project, setProject] = useState();
  let PCTATimeOutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (PCTATimeOutRef.current) {
        clearTimeout(PCTATimeOutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    updateCurrentPage({
      title: " Project CTR Activity",
      subtitle: "",
    });
    // eslint-disable-next-line
  }, []);

  const CTRProjectActitySchema = Yup.object().shape({
    ctrActivityId: Yup.mixed().required("CTR Activity is Required"),
    discipline: Yup.mixed().required("Discipline is Required"),
    b02_01: Yup.string().required("B02_01 is required"),
    b02_02: Yup.string().required("B02_02 is required"),
    b02_03: Yup.string().required("B02_03 is required"),
    b02_04: Yup.string().required("B02_04 is required"),
    b02_05: Yup.string().required("B02_05 is required"),
    b02_06: Yup.string().required("B02_06 is required"),
    b02_07: Yup.string().required("B02_07 is required"),
    remarks: Yup.string().required("Remarks is required"),
  });

  const ProjectCTRActityFileSchema = Yup.object().shape({
    fileName: Yup.mixed().required("FileName is Required"),
  });

  useEffect(() => {
    getCTRActivityMastersList({
      page: 1,
      limit: 100,
      filters: [],
      initial: true,
    });
    getDepartments();
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);

    setFilters(filtersObj);

    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 10,
    });

    getProjectCTRActivityByProjectId({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 10,
      filters,
    });
    // eslint-disable-next-line
  }, [changedParams.page, changedParams.limit, changedParams.filters]);

  useEffect(() => {
    if (projectId) getProjectDetailsPage();

    // eslint-disable-next-line
  }, []);


  const getProjectDetailsPage = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getProjectDetails(details, projectId)])
      .then(function (res) {
        if (res) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : {};
            setProject(resData);

            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Projects details");
      });
  };

  const handleProgressReportDownloadClick = async () => {
    const details = {
      projectId
    }

    await axios.all([downloadProgressReport(details)]).then(response => {
      if (response.length > 0) {
        const data = response[0].data
        const fileName = data.response['fileName']
        console.log(fileName);
        if (fileName) {

          const path = `https://files.appsarizona.com/galfar-apis/server/exports/engineer/${fileName}`
          console.log(path)
          downloadProgressFile(path);
        } else {
          console.error('File path not found in response.');
        }
      } else {
        console.error('File path not found in response.');
      }
    }).catch(error => {
      console.error('Error fetching file:', error);
    });
  }

  const downloadProgressFile = (filePath) => {
    const link = document.createElement('a');
    link.href = filePath;
    link.setAttribute('download', 'progress_report.xlsx'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };


  const getDepartments = async () => {
    const details = {
      pageSize: 100,
      pageNumber: 1,
    };
    setPageLoading(true);
    await getDepartmentsMaster(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data;
          resData = resData.map((each) => {
            return {
              ...each,
              label: each.deptName,
              value: each.publicId,
            };
          });

          setDisciplines(resData);
          setPageLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };
  const getCTRActivityMastersList = async ({
    page,
    limit,
    filters,
    initial,
  }) => {
    let data = [];
    const details = {
      pageSize: limit || 100,
      pageNumber: page || 1,
      filters,
    };

    await getCTRActivityMasters(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((_) => {
              return {
                ..._,

                label: _.name,
                value: _.publicId,
              };
            });

            data = resData;
            initial && setCTRActivityList(resData);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  };

  const getProjectCTRActivityByProjectId = async ({ page, limit, filters }) => {
    setPageLoading(true);
    if (filters && Array.isArray(filters)) {
      filters = [
        ...filters,
        { key: "status", eq: "active" },
        { key: "projectId", eq: projectId },
      ];
    } else {
      filters = [
        { key: "status", eq: "active" },
        { key: "projectId", eq: projectId },
      ];
    }

    const details = {
      pageNumber: page || 1,
      pageSize: limit || 10,
      filters,
      sorting: [{ key: "created_at", direction: "asc" }],
    };

    await axios
      .all([getProjectCTRActivity(details)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0].data ? res[0].data : [];

          resData = resData.map((each, index) => {
            return {
              ...each,
              sNo: index + 1 + (page - 1) * limit,
            };
          });
          setProjectCTRActivityList(resData);
          const totalProjectCTRActivity = res[0].totalCount;
          setTotalCount(totalProjectCTRActivity);
        }
        setPageLoading(false);
      })
      .catch((err) => {
        setPageLoading(false);
        console.log(err);
      });
  };

  const onDeleteProjectCTRActivityModal = (selected) => {
    if (selected) {
      setSelectedProjectCTRActivity(selected);
    } else {
      setSelectedProjectCTRActivity("");
    }
    setOnDeleteProjectCTRActivity(!onDeleteProjectCTRActivity);
  };

  const onAddProjectCTRActivityModal = (selected) => {
    if (selected) {
      setSelectedProjectCTRActivity(selected);
    } else {
      setSelectedProjectCTRActivity("");
    }
    setOnAddProjectCTRActivity(!onAddProjectCTRActivity);
  };

  const addProjectCTRActivityByProjectId = async (values) => {
    setButtonLoading(true);
    const details = {
      ctrActivityId: values?.ctrActivityId?.publicId,
      projectId,
      b02_01: values?.b02_01,
      b02_02: values?.b02_02,
      b02_03: values?.b02_03,
      b02_04: values?.b02_04,
      b02_05: values?.b02_05,
      b02_06: values?.b02_06,
      b02_07: values?.b02_07,
      remarks: values?.remarks,
      discipline: values?.discipline?.publicId,
    };
    await axios
      .all([addProjectCTRActivity(details)])
      .then((res) => {
        console.log(res);
        onAddProjectCTRActivityModal();
        toast.success("Project CTR Added Successfully");
        getProjectCTRActivityByProjectId({ page: 1, limit: 10, filters });
        setButtonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const updateProjectCTRActivityByProjectId = async (values) => {
    setButtonLoading(true);
    const details = {
      ctrActivityId: values?.ctrActivityId?.publicId,
      projectId,
      b02_01: values?.b02_01,
      b02_02: values?.b02_02,
      b02_03: values?.b02_03,
      b02_04: values?.b02_04,
      b02_05: values?.b02_05,
      b02_06: values?.b02_06,
      b02_07: values?.b02_07,
      remarks: values?.remarks,
      discipline: values?.discipline?.publicId,
    };
    const publicId = selectedProjectCTRActivity?.publicId;
    const concurrencyStamp = selectedProjectCTRActivity?.concurrencyStamp;
    await axios
      .all([updateProjectCTRActivity(details, publicId, concurrencyStamp)])
      .then((res) => {
        console.log(res);
        onAddProjectCTRActivityModal();
        getProjectCTRActivityByProjectId({ page: 1, limit: 10, filters });
        toast.success("Project CTR Updated Successfully");
        setButtonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const deleteProjectCTRActivityByProjectId = async () => {
    const details = {
      status: "inactive",
    };
    setButtonLoading(true);
    const publicId = selectedProjectCTRActivity?.publicId;
    const concurrencyStamp = selectedProjectCTRActivity?.concurrencyStamp;
    await axios
      .all([deleteProjectCTRActivity(details, publicId, concurrencyStamp)])
      .then((res) => {
        getProjectCTRActivityByProjectId({ page: 1, limit: 10, filters });
        toast.success("Project CTR Deleted Successfully");
        onDeleteProjectCTRActivityModal();
        setButtonLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const customStylesForCTRSelect = {
    control: (provided) => ({
      ...provided,
      border: "1px solid black",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#ffffff",
      //padding: "6px 5px",
      marginBottom: "0",
      paddingLeft: "5px",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f5f2ed" : "white",
      color: "#000000",
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingInline: "5px",
    }),
  };

  const loadDebouncCTR = (inputValue, callback) => {
    deBounce(() => loadOptionsForCTR(inputValue, callback), 1000);
  };

  const loadOptionsForCTR = async (inputValue, callback) => {
    if (!inputValue) {
      callback(CTRActivityList);
    }
    let filter = [
      {
        key: "name",
        iLike: inputValue,
      },
    ];
    let options = await getCTRActivityMastersList({
      page: 1,
      limit: 100,
      filters: filter,
      initial: false,
    });

    callback(options);
  };

  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({ search });
  };

  const searchProjectCTRActivity = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
  };
  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      name: { name: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };
  const onImportProjectCTRActivity = () => {
    setImportProjectCTRActivityModal(!importProjectCTRActivityModal);
  };

  const getUploadStatus = async (jobId) => {
    let details = {};
    await axios
      .all([getQueueStatus(details, jobId)])
      .then((res) => {
        let status = res[0].data.import_status;

        if (status === "completed") {
          clearTimeout(PCTATimeOutRef.current);
          setImportLoading(false);
          getProjectCTRActivityByProjectId({ page: 1, limit: 10, filters });
          toast.success("Project CTR Master imported successfully");
        } else {
          getProjectCTRActivityByProjectId({ page: 1, limit: 10, filters });
          PCTATimeOutRef.current = setTimeout(() => {
            getUploadStatus(jobId);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        setImportLoading(false);
      });
  };

  const upload = async (values) => {
    setButtonLoading(true);
    const details = {
      projectId,
    };
    await axios
      .all([importProjectCTRActivity(values.fileName, details, user.token)])
      .then((res) => {
        setButtonLoading(false);
        onImportProjectCTRActivity();
        let jobId = res[0]?.data?.data?.jobId;
        if (jobId) {
          setImportLoading(true);
          PCTATimeOutRef.current = setTimeout(() => getUploadStatus(jobId), 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const downloadFile = (filePath) => {
    const link = document.createElement("a");
    link.href =
      "https://files.appsarizona.com/galfar-apis/server/exports/demo/Project-CTR-Activity.xlsx";
    link.setAttribute("download", "sample_tps_file.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };
  const getRevisionProjectCTRActivityById = async (selected) => {
    const details = {};
    await axios
      .all([getRevisionProjectCTRActivity(details, selected?.publicId)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0].data ? res[0].data : [];
          resData.unshift(selected);
          resData = resData.map((each, index) => {
            return {
              ...each,
              sNo: index + 1,
            };
          });
          setRevisionProjectCTRActivity(resData);
        }
      });
  };

  const onRevisionModal = (selected) => {
    if (selected) {
      setSelectedProjectCTRActivity(selected);
      getRevisionProjectCTRActivityById(selected);
    } else {
      setSelectedProjectCTRActivity("");
    }
    setOnRevision(!onRevision);
  };

  return (
    <div className="project-ctr-activity-main">
      <div className="header">
        <div className="d-flex gap-1 align-items-center">
          <div className="back-btn-function">
            <img src={ArrowBack} alt="" onClick={() => navigate(-1)} />
          </div>
          <div className="header-content">
            Project CTR Activity ({`${totalCount}`})
          </div>
        </div>
        <div className="d-flex gap-3">
          {importLoading && <ButtonLoader color="black" />}
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchProjectCTRActivity(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>

          {grantPermission("ProjectActivity_write") && (
            <Dropdown className="trainer-type-dropdown-main">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="trainer-type-dropdown"
              >
                <span className="me-2">
                  <img src={plusIcon} alt="plus icon" />
                </span>
                <span>Project CTR Activity</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onAddProjectCTRActivityModal()}>
                  Add Project CTR Activity
                </Dropdown.Item>
                <Dropdown.Item onClick={onImportProjectCTRActivity}>
                  Import Project CTR Activity
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
         
         <ProjectsDropdown
            projectId={project?.publicId}
            projectType={project?.projectType}
            page="ctr_activity"
          />
        </div>
      </div>
      <div className="project-ctr-activity-table-main mt-3">
        {projectCTRActivityList && projectCTRActivityList?.length > 0 ? (
          <div>
            <Table responsive bordered className="table table-bordered">
              <thead style={{ textAlign: "center" }}>
                <tr>
                  <th>S.No.</th>
                  <th>CTR Activity</th>
                  <th>Discipline</th>
                  <th>B02-01</th>
                  <th>B02-02</th>
                  <th>B02-03</th>
                  <th>B02-04</th>
                  <th>B02-05</th>
                  <th>B02-06</th>
                  <th>B02-07</th>
                  <th>Revisions</th>

                  {(grantPermission("ProjectActivity_delete") ||
                    grantPermission("ProjectActivity_update")) && (
                    <th>Actions</th>
                  )}
                </tr>
                {projectCTRActivityList.map((each) => {
                  return (
                    <tr>
                      <td>{each.sNo}</td>
                      <td>{each?.masterCtrActivity?.name}</td>
                      <td>{each?.deptartment?.deptCode}</td>
                      <td>{each?.b0201}</td>
                      <td>{each?.b0202}</td>
                      <td>{each?.b0203}</td>
                      <td>{each?.b0204}</td>
                      <td>{each?.b0205}</td>
                      <td>{each?.b0206}</td>
                      <td>{each?.b0207}</td>
                      <td>
                        {each?.isRevision ? (
                          <img
                            src={viewicon}
                            onClick={() => onRevisionModal(each)}
                          />
                        ) : (
                          "-"
                        )}
                      </td>

                      <td>
                        {(grantPermission("ProjectActivity_delete") ||
                          grantPermission("ProjectActivity_update")) && (
                          <div className="d-flex gap-2">
                            {grantPermission("ProjectActivity_update") &&
                              each?.status === "active" && (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    onAddProjectCTRActivityModal(each)
                                  }
                                >
                                  <img src={editicon} alt="edit icon" />
                                </div>
                              )}
                            {grantPermission("ProjectActivity_delete") && (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  onDeleteProjectCTRActivityModal(each)
                                }
                              >
                                <img src={removeicon} alt="delete icon" />
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </thead>
            </Table>
          </div>
        ) : (
          <div>No Project CTR Activity Found</div>
        )}
      </div>
      {projectCTRActivityList && projectCTRActivityList.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalCount}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
            />
          </div>
        </div>
      )}
      <Modal
        show={onAddProjectCTRActivity}
        onHide={onAddProjectCTRActivityModal}
        className="add-department-modal-main"
        size="lg"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            {`${
              selectedProjectCTRActivity ? "Update" : "Add"
            } Project CTR Activity`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter Project CTR Activity Details </p>
          <Formik
            initialValues={{
              ctrActivityId:
                CTRActivityList.find(
                  (ctrActivity) =>
                    ctrActivity?.publicId ===
                    selectedProjectCTRActivity?.ctrActivityId
                ) || "",
              discipline:
                discplines.find(
                  (discipline) =>
                    discipline?.publicId ===
                    selectedProjectCTRActivity?.discipline
                ) || "",
              b02_01: selectedProjectCTRActivity?.b0201 || "",
              b02_02: selectedProjectCTRActivity?.b0202 || "",
              b02_03: selectedProjectCTRActivity?.b0203 || "",
              b02_04: selectedProjectCTRActivity?.b0204 || "",
              b02_05: selectedProjectCTRActivity?.b0205 || "",
              b02_06: selectedProjectCTRActivity?.b0206 || "",
              b02_07: selectedProjectCTRActivity?.b0207 || "",
              remarks: selectedProjectCTRActivity?.remarks || "",
            }}
            validationSchema={CTRProjectActitySchema}
            onSubmit={(values) => {
              selectedProjectCTRActivity
                ? updateProjectCTRActivityByProjectId(values)
                : addProjectCTRActivityByProjectId(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                  <div className="form-group col-5 mt-3">
                    <AsyncSelect
                      styles={customStylesForCTRSelect}
                      className="select-role async-select"
                      placeholder={
                        selectedProjectCTRActivity
                          ? CTRActivityList.find(
                              (ctrActivity) =>
                                ctrActivity?.publicId ===
                                selectedProjectCTRActivity?.ctrActivityId
                            )?.label
                          : "---Select CTR Activity---"
                      }
                      loadOptions={loadDebouncCTR}
                      defaultOptions={CTRActivityList}
                      onChange={(e) => {
                        setFieldValue("ctrActivityId", e);
                      }}
                      name="ctrActivityId"
                      cacheOptions
                    />
                    {/* <Select
                                            className="sop-custom-form-select"
                                            id="ctrActivityId"
                                            name="ctrActivityId"
                                            placeholder={selectedProjectCTRActivity ? CTRActivityList.find(
                                                (ctrActivity) => ctrActivity?.publicId === selectedProjectCTRActivity?.ctrActivityId)?.label : '---Select CTR Activity---'}
                                            onChange={(e) => {
                                                setFieldValue('ctrActivityId', e)

                                            }}
                                            //  value={selectedOption}
                                            options={CTRActivityList}
                                        /> */}

                    <CustomErrorMessage
                      name="ctrActivityId"
                      errors={errors}
                      touched={touched}
                    />
                  </div>

                  <div className="form-group col-5 mt-3">
                    <Select
                      className="sop-custom-form-select"
                      id="discipline"
                      name="discipline"
                      placeholder={
                        selectedProjectCTRActivity
                          ? discplines.find(
                              (discipline) =>
                                discipline?.publicId ===
                                selectedProjectCTRActivity?.discipline
                            )?.label
                          : "---Select Discipline---"
                      }
                      onChange={(e) => {
                        setFieldValue("discipline", e);
                      }}
                      //  value={selectedOption}
                      options={discplines}
                    />

                    <CustomErrorMessage
                      name="discipline"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="row ">
                  <div className="form-group  mt-3" style={{ width: "14%" }}>
                    <PrimaryInput
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.b02_01}
                      name="b02_01"
                      id="b02_01"
                      placeholder="B02_01"
                    />
                    <CustomErrorMessage
                      name="b02_01"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className="form-group  mt-3" style={{ width: "14%" }}>
                    <PrimaryInput
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.b02_02}
                      name="b02_02"
                      id="b02_02"
                      placeholder="B02_02"
                    />
                    <CustomErrorMessage
                      name="b02_02"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className="form-group  mt-3" style={{ width: "14%" }}>
                    <PrimaryInput
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.b02_03}
                      name="b02_03"
                      id="b02_03"
                      placeholder="B02_03"
                    />
                    <CustomErrorMessage
                      name="b02_03"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className="form-group  mt-3" style={{ width: "14%" }}>
                    <PrimaryInput
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.b02_04}
                      name="b02_04"
                      id="b02_04"
                      placeholder="B02_04"
                    />
                    <CustomErrorMessage
                      name="b02_04"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className="form-group  mt-3" style={{ width: "14%" }}>
                    <PrimaryInput
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.b02_05}
                      name="b02_05"
                      id="b02_05"
                      placeholder="B02_05"
                    />
                    <CustomErrorMessage
                      name="b02_05"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className="form-group  mt-3" style={{ width: "14%" }}>
                    <PrimaryInput
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.b02_06}
                      name="b02_06"
                      id="b02_06"
                      placeholder="B02_06"
                    />
                    <CustomErrorMessage
                      name="b02_06"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className="form-group  mt-3" style={{ width: "14%" }}>
                    <PrimaryInput
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.b02_07}
                      name="b02_07"
                      id="b02_07"
                      placeholder="B02_07"
                    />
                    <CustomErrorMessage
                      name="b02_07"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>

                <div className="form-group col-12 mt-3">
                  <PrimaryInput
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.remarks}
                    name="remarks"
                    id="remarks"
                    placeholder="Enter Remarks"
                  />
                  <CustomErrorMessage
                    name="remarks"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="mt-3">
                  <div className="d-flex gap-2">
                    <Button
                      className="back-icon-btn-main"
                      onClick={onAddProjectCTRActivityModal}
                    >
                      <span className="ms-2">Back</span>
                    </Button>
                    <Button className="theme-btn-main" type="submit">
                      {buttonLoading ? (
                        <ButtonLoading />
                      ) : selectedProjectCTRActivity ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        show={onDeleteProjectCTRActivity}
        onHide={onDeleteProjectCTRActivityModal}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            {`Delete Project CTR Activity`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{`Are You Sure You Want To Delete This Record`} </p>
          <div className="d-flex gap-2 mt-3">
            <Button
              className="back-icon-btn-main"
              onClick={onDeleteProjectCTRActivityModal}
            >
              <span className="ms-2">Back</span>
            </Button>
            <Button
              className="theme-btn-main"
              type="submit"
              onClick={deleteProjectCTRActivityByProjectId}
            >
              {buttonLoading ? <ButtonLoading /> : "Delete"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={importProjectCTRActivityModal}
        onHide={onImportProjectCTRActivity}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            {`Import  Project CTR Activity`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <p>Select Project CTR Activity File</p>
          <Formik
            initialValues={{
              fileName: "",
            }}
            validationSchema={ProjectCTRActityFileSchema}
            onSubmit={(values) => {
              upload(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-8 mt-3">
                    <PrimaryInput
                      type="file"
                      onChange={(e) =>
                        setFieldValue("fileName", e.target.files[0])
                      }
                      onBlur={handleBlur}
                      value={values.file}
                      name="fileName"
                      id="fileName"
                      placeholder="Enter file"
                      accept=".xlsx,.xls"
                    />
                    <CustomErrorMessage
                      name="fileName"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <span
                      onClick={() => downloadFile()}
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Download Sample File
                    </span>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex gap-2">
                      <Button
                        className="back-icon-btn-main"
                        onClick={onImportProjectCTRActivity}
                      >
                        <span className="ms-2">Back</span>
                      </Button>
                      <Button className="theme-btn-main" type="submit">
                        {buttonLoading ? <ButtonLoading /> : "Import File"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        show={onRevision}
        onHide={onRevisionModal}
        className="add-department-modal-main"
        size="xl"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Revisions of {selectedProjectCTRActivity?.masterCtrActivity?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-between mt-3 mb-3">
            <div>
              <span style={{ fontWeight: "700" }}>CTR Activity:</span>{" "}
              {selectedProjectCTRActivity?.masterCtrActivity?.name}
            </div>
            <div>
              <span style={{ fontWeight: "700" }}>Discipline:</span>{" "}
              {selectedProjectCTRActivity?.deptartment?.deptCode}
            </div>
          </div>
          <Table responsive bordered className="table table-bordered">
            <thead>
              <tr>
                {/* <th>S.No.</th> */}
                <th>B02-01</th>
                <th>B02-02</th>
                <th>B02-03</th>
                <th>B02-04</th>
                <th>B02-05</th>
                <th>B02-06</th>
                <th>B02-07</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {revisionProjectCTRActivity &&
                revisionProjectCTRActivity.length > 0 &&
                revisionProjectCTRActivity.map((each) => (
                  <tr>
                    {/* <td>{each?.sNo}</td> */}
                    <td>{each?.b0201}</td>
                    <td>{each?.b0202}</td>
                    <td>{each?.b0203}</td>
                    <td>{each?.b0204}</td>
                    <td>{each?.b0205}</td>
                    <td>{each?.b0206}</td>
                    <td>{each?.b0207}</td>
                    <td>
                      {moment(each?.createdAt).format("DD-MM-YYYY hh:mm")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div>
            <button className="btn btn-primary" onClick={onRevisionModal}>
              Back
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
