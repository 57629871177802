import React, { lazy, Suspense } from "react";
import { LazyLoader } from "../../assets/loaders";

const LazyComponent = lazy(() => import("./Chart"));

export default function Chart(props) {
  return (
    <Suspense fallback={<LazyLoader />}>
      <LazyComponent {...props} />
    </Suspense>
  );
}
