import React, { useState, useEffect } from "react";
import "./idcmatrix.scss";
import { InputGroup, Form, Modal } from "react-bootstrap";
import { Label, Button } from "reactstrap";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import IconButton from "../../../assets/elements/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../../../assets/loaders/PageLoading";
import { Table } from "reactstrap";
import moment from "moment";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/remove.svg";
import Pagination from "../../../assets/elements/CustomPagination";
import useCurrentPage from "../../../hooks/useCurrentPage";
import axios from "axios";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import { Formik } from "formik";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { PrimaryInput } from "../../../assets/FormElements";
import Select from "react-select";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import * as Yup from "yup";
import TimingFunctions from "../../../assets/functions/TimingFunctions";

import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import MainServices from "../../../services/MainServices";

export default function IDCMatrix() {
  const { grantPermission } = PermissionFunctions();
  const [pageLoading, setPageLoading] = useState(false);
  const [IDCMaster, setIDCMaster] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { updateCurrentPage } = useCurrentPage();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [searchString, setSearchString] = useState("");
  const [totalIDCMaster, setTotalIDCMaster] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [createCTRModal, setCreateCTRModal] = useState(false);
  const [seletedIDC, setSeletedIDC] = useState();
  const { getIDCMasters, deleteIDCMaster } = MainServices();
  const [documentDepartments, setDocumentDepartments] = useState([]);
  const [selectedDeptId, setSelectedDeptId] = useState("");
  const { deBounce } = TimingFunctions();
  const [deleteIDCModal, setDeleteIDCModal] = useState(false);
  const [isDisciplineIdChanging, setDisciplineIdChanging] = useState(false);

  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const allStatus = [
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  useEffect(() => {
    updateCurrentPage({
      title: "Master ",
      subtitle: "Workflow",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let statusKey = false;
    filters.forEach((filter) => {
      if (filter.key === "status") {
        statusKey = true;
      }
    });
    if (!statusKey) {
      filters = [...filters, { key: "status", eq: "active" }];
    }
    filters = filters.filter(
      (filter) => !(filter.key === "status" && filter.eq === "all")
    );

    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setSelectedStatus(filtersObj?.status?.status || "");
    setFilters(filtersObj);
    setSearchString(changedParams?.searchString);
    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 50,
    });

    getIDCMastersByStatus({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 50,
      filters,
      searchString: changedParams?.searchString || "",
    });
    // eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.filters,
    changedParams.searchString,
  ]);

  const getIDCMastersByStatus = async ({
    page,
    limit,
    filters,
    searchString,
  }) => {
    setPageLoading(true);
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
    };
    await axios
      .all([getIDCMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let IDCMasters = resData.data ? resData.data : [];
            let total = resData?.totalCount || 0;
            setTotalIDCMaster(total);
            IDCMasters = IDCMasters.map((_, index) => {
              return {
                ..._,
                sNo: limit * (page - 1) + index + 1,
              };
            });
            setIDCMaster(IDCMasters);
            setPageLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting IDC Matrix");
      });
  };

  const onDeleteIDC = (selected) => {
    if (selected) {
      setSeletedIDC(selected);
    } else {
      setSeletedIDC("");
    }
    setDeleteIDCModal(!deleteIDCModal);
  };

  const deleteIDCMatrix = async () => {
    setButtonLoading(true);
    const details = {
      status: "inactive",
    };

    await axios
      .all([
        deleteIDCMaster(
          details,
          seletedIDC.publicId,
          seletedIDC.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getIDCMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
              searchString,
            });
            onDeleteIDC();
            notifySuccess("IDC Matrix Deleted Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in deleting CTR Master");
      });
  };

  const searchIDCMaster = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      name: { name: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: 50,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      searchString,
    });
    navigate({
      search,
    });
  };

  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      searchString,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
      searchString,
    });
    navigate({ search });
  };

  return (
    <div className="ctr-list-main">
      <div className="ctr-list-header-section">
        <div className="ctr-list-heading">
          Work Flow {`(${totalIDCMaster || 0})`}
        </div>
        <div className="d-flex gap-3">
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchIDCMaster(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>

          <div className="product-list-header-flex" style={{ width: "8rem" }}>
            <select
              class="form-control form-select custom-status-select"
              id="fv-topics"
              name="status"
              data-placeholder="Select a option"
              required
              onChange={onStatusChange}
              value={selectedStatus}
            >
              <option label="Select Status" value=""></option>
              {allStatus.map((each) => (
                <option value={each.value} key={each.value}>
                  {each.label}
                </option>
              ))}
            </select>
          </div>

          {grantPermission("Document_write") && (
            <IconButton
              iconType="add"
              title="WorkFlow"
              onClick={() => navigate({ pathname: "/documents/add-workflow" })}
            />
          )}
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : IDCMaster && IDCMaster.length > 0 ? (
        <div className="content mt-3">
          <div className="table-main">
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr>
                  <th>#</th>
                  <th>Name</th>

                  {(grantPermission("Document_delete") ||
                    grantPermission("Document_update")) && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {IDCMaster.map((each) => {
                  return (
                    <tr>
                      <td>{each?.sNo}</td>
                      <td
                        onClick={() =>
                          navigate({
                            pathname: "/masters/workflow/workflow-details",
                            search: `?publicId=${each?.publicId}`,
                          })
                        }
                        style={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          fontWeight: "500",
                          color: "#E65C4F",
                        }}
                      >
                        {each?.name}
                      </td>

                      {(grantPermission("Document_delete") ||
                        grantPermission("Document_update")) && (
                        <td className="options">
                          <div className="d-flex gap-2">
                            {grantPermission("Document_update") &&
                              each?.status === "active" && (
                                <div
                                  onClick={() =>
                                    navigate({
                                      pathname: "/documents/add-workflow",
                                      search: `publicId=${each?.publicId}`,
                                    })
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={editicon} alt="Edit icon" />
                                </div>
                              )}
                            {grantPermission("Document_delete") && (
                              <div
                                onClick={() => onDeleteIDC(each)}
                                style={{ cursor: "pointer" }}
                              >
                                <img src={removeicon} alt="Remove icon" />
                              </div>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="no-data-found-main">No IDC Found</div>
      )}
      {IDCMaster && IDCMaster.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalIDCMaster}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
              onRowsPerPage={onRowsPerPage}
            />
          </div>
        </div>
      )}

      <Modal
        centered
        show={deleteIDCModal}
        onHide={onDeleteIDC}
        className="add-department-modal-main"
        selected={seletedIDC}
        size="md"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Delete IDC
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <div className="mt-3">
            <div
              style={{ fontWeight: 600 }}
            >{`Are You Sure you want to delete " ${seletedIDC?.name}"`}</div>
            <div className="d-flex gap-2 justify-content-end mt-3">
              <Button className="back-icon-btn-main" onClick={onDeleteIDC}>
                <span className="ms-2">Back</span>
              </Button>
              <div className="mt-5"></div>
              <Button className="theme-btn-main" onClick={deleteIDCMatrix}>
                {buttonLoading ? <ButtonLoading /> : "Delete"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
