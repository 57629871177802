import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import './ctractivity.scss';
import PageLoading from '../../../assets/loaders/PageLoading';
import MainServices from '../../../services/MainServices';
import { InputGroup, Form, Button, Modal, Dropdown } from "react-bootstrap";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import plusIcon from "../../../assets/images/icons/add.svg";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import { Formik } from "formik";
import * as Yup from "yup";
import { PrimaryInput } from "../../../assets/FormElements";
import TimingFunctions from "../../../assets/functions/TimingFunctions";

import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/remove.svg";
import { Table, Label } from "reactstrap";
import Pagination from "../../../assets/elements/CustomPagination";
import {
    paramsToFilters,
    paramsToFiltersObj,
    filtersToUri,
    filtersToUrlParams,
} from "../../../assets/functions/filters";
import { useLocation, useNavigate } from 'react-router-dom';
import useCurrentPage from "../../../hooks/useCurrentPage";
import useAuth from '../../../hooks/useAuth';
import { toast } from "react-toastify";
import { ButtonLoader, LazyLoader } from "../../../assets/loaders";
import viewicon from '../../../assets/images/icons/viewicon.svg'
import moment from 'moment';

export default function CTRActivity() {

    const [CTRActivityList, setCTRACtivityList] = useState([])
    const [pageLoading, setPageLoading] = useState(false)
    const {
        getCTRActivityMasters, addCTRActivityMaster,
        updateCTRActivityMaster, deleteCTRActivityMaster,
        importCTRActivity,
        getQueueStatus,
        getRevisionCTRActivity
    } = MainServices()
    const { updateCurrentPage } = useCurrentPage();

    const { grantPermission } = PermissionFunctions();
    const [onAddCTRActivity, setOnAddCTRActivity] = useState(false)
    const [selectedCTRActivity, setSelectedCTRActivity] = useState()
    const [buttonLoading, setButtonLoading] = useState(false)
    const [pagination, setPagination] = useState({ page: 1, limit: 10 })
    const [totalCTRActivity, setTotalCTRActivity] = useState(0)
    const [filters, setFilters] = useState([])
    const [onDeleteCTRActivity, setOnDeleteCTRActivity] = useState(false)
    const [importCTRActivityModal, setImportCTRActivityModal] = useState(false)
    const [importLoading, setImportLoading] = useState(false)
    const [onRevision, setOnRevision] = useState(false)
    const [revisionCTRActivity, setRevisionCTRActivity] = useState()

    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useAuth()
    const { deBounce } = TimingFunctions();
    const params = new URLSearchParams(location.search);

    const changedParams = Object.fromEntries(params);
    let mCTRTimeOutRef = useRef(null);

    const CTRActitySchema = Yup.object().shape({
        name: Yup.string().test(
            "not-only-whitespace",
            " Field  cannot be empty or only spaces",
            (value) => value && value.trim().length > 0
          ).required(" Name is Required"),
        description: Yup.string() .test(
            "not-only-whitespace",
            " Field  cannot be empty or only spaces",
            (value) => value && value.trim().length > 0
          ).required("Description is Required"),

    });
    const CTRActityFileSchema = Yup.object().shape({
        fileName: Yup.mixed().required("FileName is Required"),


    });

    useEffect(() => {
        updateCurrentPage({
            title: "CTR Activity",
            subtitle: "",
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        let filters = paramsToFilters(changedParams?.filters, "filters");
        let filtersObj = paramsToFiltersObj(changedParams?.filters);


        setFilters(filtersObj);

        setPagination({
            page: changedParams?.page || 1,
            limit: changedParams?.limit || 10,
        });

        getCTRActivityMastersList({
            page: changedParams?.page || 1,
            limit: changedParams.limit || 10,
            filters,
        });
        // eslint-disable-next-line
    }, [changedParams.page, changedParams.limit, changedParams.filters]);

    useEffect(() => {
        return () => {
          if (mCTRTimeOutRef.current) {
            clearTimeout(mCTRTimeOutRef.current);
          }
        };
      }, []);

    const getCTRActivityMastersList = async ({ page, limit, filters }) => {

        if(filters && Array.isArray(filters)){
            filters=[...filters,{key:'status',eq:'active'}]
        }else{
            filters=[{key:'status',eq:'active'}]
        }
        const details = {
            pageSize: limit || 10,
            pageNumber: page || 1,
            filters,
            sorting: [{ key: "created_at", direction: "asc" }],
        };
        setPageLoading(true)
        await axios
            .all([getCTRActivityMasters(details)])
            .then(res => {

                if (res[0]) {
                    let resData = res[0]?.data ? res[0]?.data : []
                    resData = resData.map((each, index) => {
                        return {
                            ...each,
                            sNo: index + 1 + (page - 1) * limit
                        }
                    })

                    setCTRACtivityList(resData)
                    let totalCount = res[0]?.totalCount ? res[0].totalCount : 0
                    setTotalCTRActivity(totalCount)
                    setPageLoading(false);


                } else {
                    setPageLoading(false)
                }

            })
            .catch(err => { setPageLoading(false); console.log(err) })

    }

    const onAddCTRActivityModal = (selected) => {
        if (selected) {
            setSelectedCTRActivity(selected)
        } else {
            setSelectedCTRActivity("")
        }
        setOnAddCTRActivity(!onAddCTRActivity)


    }

    const onDeleteCTRActivityModal = (selected) => {
        if (selected) {
            setSelectedCTRActivity(selected)
        } else {
            setSelectedCTRActivity("")
        }
        setOnDeleteCTRActivity(!onDeleteCTRActivity)


    }
    const addCTRActivity = async (values) => {
        setButtonLoading(true)

        const details = {
            name: values.name,
            description: values.description
        }
        await axios
            .all([addCTRActivityMaster(details)])

            .then(res => {

                setButtonLoading(false)
                onAddCTRActivityModal()
                getCTRActivityMastersList({ page: 1, limit: 10, filters })
            })
            .catch(err => { setButtonLoading(false); console.log(err) })
    }

    const updateCTRActivity = async (values) => {
        setButtonLoading(true)

        const details = {
            name: values.name,
            description: values.description
        }
        const publicId = selectedCTRActivity?.publicId;
        const concurrencyStamp = selectedCTRActivity?.concurrencyStamp
        await axios
            .all([updateCTRActivityMaster(details, publicId, concurrencyStamp)])

            .then(res => {

                setButtonLoading(false)
                onAddCTRActivityModal()
                getCTRActivityMastersList({ page: 1, limit: 10, filters })
            })
            .catch(err => { setButtonLoading(false); console.log(err) })
    }

    const DeleteCTRActivity = async () => {
        setButtonLoading(true)

        const details = {
            status: 'inactive'
        }
        const publicId = selectedCTRActivity?.publicId;
        const concurrencyStamp = selectedCTRActivity?.concurrencyStamp
        await axios
            .all([deleteCTRActivityMaster(details, publicId, concurrencyStamp)])

            .then(res => {

                setButtonLoading(false)
                onDeleteCTRActivityModal()
                getCTRActivityMastersList({ page: 1, limit: 10, filters })
            })
            .catch(err => { setButtonLoading(false); console.log(err) })
    }

    const onPage = (page) => {
        let mfilters = filtersToUri(filters);
        let search = filtersToUrlParams({
            page,
            limit: pagination.limit,
            filters: mfilters,
        });
        navigate({ search });
    };


    const searcnCTRActivity = (e) => {
        deBounce(() => onSearchFilter(e), 1000);
    }
    const onSearchFilter = (e) => {
        let mfilters = filtersToUri({
            ...filters,
            name: { name: e.target.value },
        });
        let search = filtersToUrlParams({
            page: 1,
            limit: pagination.limit,
            filters: mfilters,
        });
        navigate({
            search,
        });
    };

    const onImportCTRActivity = () => {
        setImportCTRActivityModal(!importCTRActivityModal)
    }

    const getUploadStatus = async (jobId) => {
        let details = {

        }
        await axios
            .all([getQueueStatus(details, jobId)])
            .then(res => {
                let status = res[0].data.import_status;

                if (status === 'completed') {
                    setImportLoading(false)
                    getCTRActivityMastersList({ page: 1, limit: 10, filters })
                    toast.success("Master CTR Activity imported successfully");
                    clearTimeout(mCTRTimeOutRef.current)
                }
                else {
                    getCTRActivityMastersList({ page: 1, limit: 10, filters })
                    mCTRTimeOutRef.current = setTimeout(() => { getUploadStatus(jobId) }, 3000)
                }
            })
            .catch((err) => { console.log(err); setImportLoading(false) })
    }

    const upload = async (values) => {

        setButtonLoading(true)
        const details = {

        }
        await axios
            .all([importCTRActivity(values.fileName, details, user.token)])
            .then(res => {
                setButtonLoading(false)
                onImportCTRActivity()
                let jobId = res[0]?.data?.data?.jobId;
                if (jobId) {
                    setImportLoading(true);
                  mCTRTimeOutRef.current =  setTimeout(() => {
                        getUploadStatus(jobId)
                    }, 1000);


                }

            })
            .catch(err => { console.log(err); setButtonLoading(false) })
    }


    const downloadFile = (filePath) => {
        const link = document.createElement('a');
        link.href = 'https://files.appsarizona.com/galfar-apis/server/exports/demo/Master-CTR-Activity.xlsx';
        link.setAttribute('download', 'sample_master_ctr_activity.xlsx'); // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Remove the <a> tag after download
    };
    const getRevisionCTRActivityById = async (selected) => {
        const details = {}
        await axios
            .all([getRevisionCTRActivity(details, selected?.publicId)])
            .then(res => {
                if (res[0]) {
                    let resData = res[0].data ? res[0].data : []
                    resData.unshift(selected)
                    resData = resData.map((each, index) => {
                        return {
                            ...each,
                            sNo: index + 1
                        }
                    })
                    setRevisionCTRActivity(resData)
                    console.log('revision ctr acti', resData)
                }
            })
    }

    const onRevisionModal = (selected) => {
        if (selected) {
            setSelectedCTRActivity(selected)
            getRevisionCTRActivityById(selected)
        } else {
            setSelectedCTRActivity("")
        }
        setOnRevision(!onRevision)
    }
    return (

        <div className='ctr-activity-main'>
            <div className="header ">
                <p className="content-header-main">
                    CTR Activity {`(${totalCTRActivity || 0})`}
                </p>
                <div className="d-flex gap-3">
                    {importLoading && <ButtonLoader color='black' />}
                    <InputGroup style={{ width: "auto" }}>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="search"
                            className="search-input-main"
                            onChange={(e) => searcnCTRActivity(e)}
                        />
                        <InputGroup.Text id="search" className="search-input-main">
                            <img src={searchicon} alt="search icon" />
                        </InputGroup.Text>
                    </InputGroup>

                    {grantPermission("MasterCtrActivity_write") && (
                        <Dropdown className="trainer-type-dropdown-main">
                            <Dropdown.Toggle
                                id="dropdown-basic"
                                className="trainer-type-dropdown"
                            >
                                <span className="me-2">
                                    <img src={plusIcon} alt="plus icon" />
                                </span>
                                <span>CTR Activity</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => onAddCTRActivityModal()}
                                >
                                    Add CTR Activity
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={onImportCTRActivity}
                                >
                                    Import CTR Activity
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )}


                </div>
            </div>
            {pageLoading
                ? <PageLoading />
                :
                (
                    <div className='ctr-actvity-table-main mt-3'>
                        {CTRActivityList && CTRActivityList.length > 0
                            ?
                            (
                                <Table responsive bordered className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>SNo.</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Revisions</th>

                                            {(grantPermission("MasterCtrActivity_delete") ||
                                                grantPermission("MasterCtrActivity_update")) && (
                                                    <th>Actions</th>
                                                )}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {CTRActivityList.map(each => {
                                            return (

                                                <tr>
                                                    <td>{each.sNo}</td>
                                                    <td>{each.name}</td>
                                                    <td>{each.description}</td>
                                                    <td>{each?.isRevision
                                                        ? <img src={viewicon} onClick={() => onRevisionModal(each)} />
                                                        : '-'
                                                    }
                                                    </td>

                                                    {(grantPermission("MasterCtrActivity_delete") ||
                                                        grantPermission("MasterCtrActivityS_update")) && (
                                                            <td className="options">
                                                                <div className="d-flex gap-2">
                                                                    {grantPermission("MasterCtrActivity_update") &&
                                                                        each?.status === "active" && (
                                                                            <div
                                                                                onClick={() =>
                                                                                    onAddCTRActivityModal(each)
                                                                                }
                                                                                style={{ cursor: "pointer" }}
                                                                            >
                                                                                <img src={editicon} alt="Edit icon" />
                                                                            </div>
                                                                        )}
                                                                    {grantPermission("MasterCtrActivity_delete") && (
                                                                        <div
                                                                            onClick={() => onDeleteCTRActivityModal(each)}
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            <img src={removeicon} alt="Remove icon" />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        )}

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            ) : (
                                <div style={{ fontWeight: '700' }} className='d-flex justify-content-center'>
                                    NO CTR Activity Found
                                </div>
                            )}
                    </div>
                )}
            {CTRActivityList && CTRActivityList.length > 0 &&
                <div class="custom-pagination-main">
                    <div class="card-inner">
                        <Pagination
                            totalCount={totalCTRActivity}
                            currentPage={pagination.page}
                            rowsPerPage={pagination.limit}
                            onNext={onPage}
                            onPrev={onPage}
                            onPageClick={onPage}

                        />
                    </div>
                </div>
            }
            <Modal
                show={onAddCTRActivity}
                onHide={onAddCTRActivityModal}
                className="add-department-modal-main"
            >
                <Modal.Header closeButton className="add-department-modal-header">
                    <Modal.Title className="add-department-modal-title">
                        {`${selectedCTRActivity ? 'Update' : 'Add'} CTR Activity`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-department-modal-body">
                    <p>Enter CTR Activity Details</p>
                    <Formik
                        initialValues={{
                            name: selectedCTRActivity?.name || '',
                            description: selectedCTRActivity?.description || "",

                        }}
                        validationSchema={CTRActitySchema}

                        onSubmit={(values) => {
                            selectedCTRActivity ? updateCTRActivity(values) : addCTRActivity(values)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form className="form" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="form-group col-8 mt-3">
                                        <PrimaryInput
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                            name="name"
                                            id="name"
                                            placeholder="Enter Name"
                                        />
                                        <CustomErrorMessage
                                            name="name"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="form-group col-8 mt-3">
                                        <PrimaryInput
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.description}
                                            name="description"
                                            id="description"
                                            placeholder="Enter Description"
                                        />
                                        <CustomErrorMessage
                                            name="description"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>




                                    <div className="mt-3">
                                        <div className="d-flex gap-2">
                                            <Button
                                                className="back-icon-btn-main"
                                                onClick={onAddCTRActivityModal}
                                            >

                                                <span className="ms-2">Back</span>
                                            </Button>
                                            <Button className="theme-btn-main" type="submit">
                                                {buttonLoading ?
                                                    <ButtonLoading /> :
                                                    selectedCTRActivity ? "Update" : "Save"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>

            </Modal>
            <Modal
                centered
                show={onDeleteCTRActivity}
                onHide={onDeleteCTRActivityModal}
                className="add-department-modal-main"
                selected={selectedCTRActivity}
            >
                <Modal.Header closeButton className="add-department-modal-header">
                    <Modal.Title className="add-department-modal-title">
                        Delete CTR Activity
                    </Modal.Title>
                </Modal.Header>


                <Modal.Body className="add-department-modal-body">
                    <p>{`Are You Sure You Want to Delete ${selectedCTRActivity?.name}`}</p>
                    <div className="mt-3">
                        <div className="d-flex gap-2">
                            <Button
                                className="back-icon-btn-main"
                                onClick={onDeleteCTRActivityModal}
                            >

                                <span className="ms-2">Back</span>
                            </Button>
                            <Button className="theme-btn-main" type="submit"
                                onClick={DeleteCTRActivity}
                            >
                                {buttonLoading ? <ButtonLoading /> : "Delete"}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

            <Modal
                show={importCTRActivityModal}
                onHide={onImportCTRActivity}
                className="add-department-modal-main"
            >
                <Modal.Header closeButton className="add-department-modal-header">
                    <Modal.Title className="add-department-modal-title">
                        {`Import CTR Activity`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-department-modal-body">
                    <p>Select CTR Activity File</p>
                    <Formik
                        initialValues={{
                            fileName: ''

                        }}
                        validationSchema={CTRActityFileSchema}

                        onSubmit={(values) => {
                            upload(values)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue
                        }) => (
                            <Form className="form" onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className="form-group col-8 mt-3">
                                        <PrimaryInput
                                            type="file"
                                            onChange={(e => setFieldValue('fileName', e.target.files[0]))}
                                            onBlur={handleBlur}
                                            value={values.file}
                                            name="fileName"
                                            id="fileName"
                                            placeholder="Enter file"
                                            accept=".xlsx,.xls"
                                        />
                                        <CustomErrorMessage
                                            name="fileName"
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>

                                    <div className="d-flex justify-content-end mt-3">
                                        <span
                                            onClick={() => downloadFile()}
                                            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                        >Download Sample File</span>
                                    </div>



                                    <div className="mt-3">
                                        <div className="d-flex gap-2">
                                            <Button
                                                className="back-icon-btn-main"
                                                onClick={onImportCTRActivity}
                                            >

                                                <span className="ms-2">Back</span>
                                            </Button>
                                            <Button className="theme-btn-main" type="submit">
                                                {buttonLoading ?
                                                    <ButtonLoading /> :
                                                    "Import File"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>

            </Modal>

            <Modal
                show={onRevision}
                onHide={onRevisionModal}
                className='add-department-modal-main'
                size='xl'
            >
                <Modal.Header closeButton className='add-department-modal-header'>
                    <Modal.Title className='add-department-modal-title'>
                        Revisions of {selectedCTRActivity?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive bordered className='table table-bordered'>
                        <thead>
                            <tr>
                                <td>S.No.</td>
                                <td>Description</td>
                                <td>Created At</td>
                            </tr>
                        </thead>
                        <tbody>
                            {revisionCTRActivity && revisionCTRActivity.length > 0 &&
                                revisionCTRActivity.map(each => {
                                    return <tr>
                                        <td>{each?.sNo}</td>
                                        <td>{each?.description}</td>
                                        <td>{moment(each?.createdAt).format("DD-MM-YYYY hh:mm")}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </Modal.Body>


            </Modal>
        </div>
    )
}