import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import "./resetPassword.scss";
import { Form, Formik } from "formik";
import { PasswordInput, PrimaryInput } from "../../../assets/FormElements";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { PrimaryButton } from "../../../assets/buttons";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import * as Yup from "yup";
import { Label } from "reactstrap";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import { useNavigate } from "react-router-dom";
import * as Storage from '../../../store/LocalStorage'

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is Required")
    .min(8, "Atleast Eight(8) Characters")
    .max(12, "Atmost Twelve(12) Characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,12}$/,
      "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character"
    ),
});
export default function ResetPassword() {
  let { user } = useAuth();
  const { getUserDetails, resetpassword } = MainServices();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getUserByUserId();
  }, []);

  const getUserByUserId = async () => {
    const details = {};
    const token = user.token;
    await axios.all([getUserDetails(details, token)]).then(function (res) {
      if (res[0]) {
        let resData = res[0];
        let message = resData.message;
        if (resData.success) {
          setUserDetails(resData.data);
        }
      }
    });
  };

  const onPasswordChange = async (values) => {
    setButtonLoading(true);
    console.log("userDetails", userDetails);
    const publicId = userDetails?.publicId;
    const concurrencyStamp = userDetails?.concurrencyStamp;
    const details = {
      password: values.password,
    };
    await resetpassword(details, publicId, concurrencyStamp)
      .then((res) => {
        if (res.success) {
          notifySuccess("Password Changed Successfully");
          Storage.clear()
          window.location.reload()
        } else {
          notifyWarn("Error Occured!");
        }
      })
      .catch((err) => console.error(err));

    setButtonLoading(false);
  };
  return (
    <div className="reset-password-main">
      <div className="reset-password-box">
        <div style={{ fontWeight: "700", marginBottom: "10px" }}>
          Reset Your Password
        </div>
        <hr />

        <div>
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={resetPasswordSchema}
            onSubmit={(values) => {
              onPasswordChange(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <Label>Enter New Password</Label>
                  <PasswordInput
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    name="password"
                  />
                  <CustomErrorMessage
                    name="password"
                    errors={errors}
                    touched={touched}
                  />
                </div>

                <div className="mt-5">
                  <PrimaryButton
                    type="submit"
                    title={
                      buttonLoading ? <ButtonLoading /> : "Change Password"
                    }
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
