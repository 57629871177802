import React from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../utils/ItemTypes";

export default function ItemsDroppable(props) {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: () => ({
      onDragItem: props.onDrag,
      type: props.dataType,
      setFieldValue: props.setFieldValue,
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = canDrop && isOver;
  const styles = {
    border: "2px solid",
    borderColor: isActive ? "#E65C4F70" : "#fff",
    padding: "10px",
    overflowY: "scroll",
    background: isActive ? "#E65C4F70" : "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    marginBottom: "10px",
    ...(props.style ? { ...props.style } : {}),
    overflowY: "scroll",
  };
  return (
    <div ref={drop} style={styles}>
      {props.children}
    </div>
  );
}
