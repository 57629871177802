import { useDrag } from "react-dnd";
import { ItemTypes } from "../../utils/ItemTypes";
import UserIcon from "../../assets/images/icons/profile-image.svg";
import useConfig from "../../hooks/useConfig";
import useAuth from "../../hooks/useAuth";

export default function EachDragItem({ props, type }) {
  const { authFiles } = useConfig();
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: props,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        dropResult.onDragItem({
          item,
          type: dropResult.type,
          setFieldValue: dropResult.setFieldValue,
        });
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });
  const styles = {
    padding: "4px 8px",
    cursor: "pointer",
    background: isDragging ? "#cccccc70" : "#ccc",
    color: "#000",
    marginBottom: "5px",
    borderRadius: 2,
  };
  return (
    <div key={props._id} _id={props._id} ref={drag} style={styles}>
      <div className="d-flex">
        <img
          src={
            props.empProfilePhoto ? authFiles + props.empProfilePhoto : UserIcon
          }
          alt="profile pic"
          width={36}
          height={36}
          style={{ borderRadius: "50%" }}
        />
        <p className="p-1" style={{ marginBottom: 0 }}>
          {props?.label}
        </p>{" "}
      </div>
      <div className="d-flex">
        <p style={{ marginBottom: 0 }}>{props?.deptartment?.deptName}</p>
        <p style={{ marginBottom: 0 }}>- {props?.designation?.desgName}</p>
      </div>
    </div>
  );
}
