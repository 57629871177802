import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./tpslist.scss";
import PageLoader from "../../../assets/loaders/PageLoading";
import { Table, Label } from "reactstrap";
import { PrimaryButton } from "../../../assets/buttons";

import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import viewicon from "../../../assets/images/icons/viewicon.svg";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/remove.svg";
import useCurrentPage from "../../../hooks/useCurrentPage";
import Pagination from "../../../assets/elements/CustomPagination";
import { InputGroup, Form, Button, Modal, Dropdown } from "react-bootstrap";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import { Formik } from "formik";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import plusIcon from "../../../assets/images/icons/add.svg";

import IconButton from "../../../assets/elements/IconButton";
import { PrimaryInput } from "../../../assets/FormElements";

import * as Yup from "yup";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";

import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { ButtonLoader, LazyLoader } from "../../../assets/loaders";
import moment from "moment/moment";

export default function TPSList() {
  const [pageLoading, setPageLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const { grantPermission } = PermissionFunctions();
  const {
    getTPSMaster,
    addTPSMaster,
    updateTPSMaster,
    deleteTPSMaster,
    importTPS,
    getQueueStatus,
    getRevisionTPS,
  } = MainServices();
  const [TPSList, setTPSList] = useState([]);
  const { updateCurrentPage } = useCurrentPage();
  const [onAddTPS, setOnAddTPS] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedTPS, setSelectedTPS] = useState();
  const [onDeleteTPS, setOnDeleteTPS] = useState(false);
  const [filters, setFilters] = useState([]);
  const location = useLocation();
  const { deBounce } = TimingFunctions();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [importTPSModal, setImportTPSModal] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [onRevision, setOnRevision] = useState(false);
  const [revisionTPS, setRevisionTPS] = useState();

  const changedParams = Object.fromEntries(params);
  const { user } = useAuth();

  let tpsTimeOutRef = useRef(null);

  const TPSSchema = Yup.object().shape({
    name: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required(" Name is Required"),
    description: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Description is Required"),
  });
  const TPSImportingSchema = Yup.object().shape({
    fileName: Yup.string().required("Select file to be import"),
  });

  const onAddTPSModal = (selected) => {
    if (selected) {
      setSelectedTPS(selected);
    } else {
      setSelectedTPS("");
    }
    setOnAddTPS(!onAddTPS);
  };

  useEffect(() => {
    return () => {
      if (tpsTimeOutRef.current) {
        clearTimeout(tpsTimeOutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    updateCurrentPage({
      title: "TPS",
      subtitle: "",
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);

    setFilters(filtersObj);

    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 10,
    });

    getTPSListMaster({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 10,
      filters,
    });
    // eslint-disable-next-line
  }, [changedParams.page, changedParams.limit, changedParams.filters]);

  const getTPSListMaster = async ({ page, limit, filters }) => {
    setPageLoading(true);
    if (filters && Array.isArray(filters)) {
      filters = [...filters, { key: "status", eq: "active" }];
    } else {
      filters = [{ key: "status", eq: "active" }];
    }
    const details = {
      pageSize: limit || 10,
      pageNumber: page || 1,
      filters,
      sorting: [{ key: "created_at", direction: "asc" }],
    };
    await axios
      .all([getTPSMaster(details)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0].data ? res[0].data : [];
          resData = resData.map((data, index) => {
            return {
              ...data,
              sNo: index + 1 + (page - 1) * limit,
            };
          });
          let totalCount = res[0].totalCount || 0;
          setTotalCount(totalCount);
          setTPSList(resData);
          setPageLoading(false);
        } else {
          setPageLoading(false);
        }
      })
      .catch((res) => console.log(res));
  };
  const addTPS = async (values) => {
    setButtonLoading(true);

    const details = {
      name: values.name,
      description: values.description,
      //    amount: values.amount
    };

    await axios
      .all([addTPSMaster(details)])
      .then((res) => {
        setButtonLoading(false);
        onAddTPSModal();
        getTPSListMaster({
          page: pagination.page,
          limit: pagination.limit,
          filters,
        });
      })
      .catch((res) => {
        setButtonLoading(false);
        console.log(res);
      });
  };
  const updateTPS = async (values) => {
    setButtonLoading(true);

    const details = {
      name: values.name,
      description: values.description,
      // amount: values.amount
    };
    const publicId = selectedTPS?.publicId;
    const concurrencyStamp = selectedTPS?.concurrencyStamp;

    await axios
      .all([updateTPSMaster(details, publicId, concurrencyStamp)])
      .then((res) => {
        setButtonLoading(false);
        onAddTPSModal();

        getTPSListMaster({
          page: pagination.page,
          limit: pagination.limit,
          filters,
        });
      })
      .catch((res) => {
        setButtonLoading(false);
        console.log(res);
      });
  };

  const onDeleteTPSModal = (selected) => {
    if (selected) {
      setSelectedTPS(selected);
    } else {
      setSelectedTPS("");
    }
    setOnDeleteTPS(!onDeleteTPS);
  };

  const DeleteTPS = async () => {
    setButtonLoading(true);
    const details = {
      status: "inactive",
    };
    const publicId = selectedTPS?.publicId;
    const concurrencyStamp = selectedTPS?.concurrencyStamp;

    await axios
      .all([deleteTPSMaster(details, publicId, concurrencyStamp)])
      .then((res) => {
        setButtonLoading(false);
        setOnDeleteTPS(!onDeleteTPS);
        getTPSListMaster({
          page: pagination.page,
          limit: pagination.limit,
          filters,
        });
      })
      .catch((err) => {
        setButtonLoading(false);
        console.log(err);
      });
  };

  const searchTPS = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
  };
  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      name: { name: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({
      search,
    });
  };

  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
    });
    navigate({ search });
  };

  const onImportTPS = () => {
    setImportTPSModal(!importTPSModal);
  };

  const getUploadStatus = async (jobId) => {
    const details = {};

    await axios
      .all([getQueueStatus(details, jobId)])
      .then((res) => {
        if (res[0]) {
          let status = res[0]?.data?.import_status;

          if (status === "completed") {
            toast.success("TPS imported successfully");
            clearTimeout(tpsTimeOutRef.current);
            getTPSListMaster({
              page: changedParams?.page || 1,
              limit: changedParams.limit || 10,
              filters,
            });

            setImportLoading(false);
          } else {
            tpsTimeOutRef.current = setTimeout(() => {
              getTPSListMaster({
                page: changedParams?.page || 1,
                limit: changedParams.limit || 10,
                filters,
              });
              getUploadStatus(jobId);
            }, 3000);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const uploadFile = async (values) => {
    setButtonLoading(true);
    const details = {};
    const documents = values.fileName;

    await axios
      .all([importTPS(documents, details, user?.token)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];

          onImportTPS();
          setButtonLoading(false);

          let jobId = res[0]?.data?.data?.jobId;
          if (jobId) {
            setImportLoading(true);
            tpsTimeOutRef.current = setTimeout(() => {
              getUploadStatus(jobId);
            }, 1000);
          }
        }
      })
      .catch(function (res) {
        setButtonLoading(false);
        if (res.message) console.log("An error occurred in file upload");
      });
  };

  const downloadFile = (filePath) => {
    const link = document.createElement("a");
    link.href =
      "https://files.appsarizona.com/galfar-apis/server/exports/demo/Master-TPS.xlsx";
    link.setAttribute("download", "sample_tps_file.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  const onRevisionModal = (selected) => {
    if (selected) {
      setSelectedTPS(selected);

      getRevisionTPSById(selected?.publicId);
    } else {
      setSelectedTPS("");
    }
    setOnRevision(!onRevision);
  };

  const getRevisionTPSById = async (publicId) => {
    let details = {};
    await axios
      .all([getRevisionTPS(details, publicId)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0].data ? res[0].data : 0;
          resData = resData.map((each, index) => {
            return {
              ...each,
              sNo: index + 1,
            };
          });

          setRevisionTPS(resData);

          console.log("revision TPS", resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="tps-list-main">
      <div className="header ">
        <p className="content-header-main">TPSList {`(${totalCount || 0})`}</p>
        <div className="d-flex gap-3">
          {importLoading && <ButtonLoader color="black" />}
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchTPS(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>

          {grantPermission("MasterTPS_write") && (
            <Dropdown className="trainer-type-dropdown-main">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="trainer-type-dropdown"
              >
                <span className="me-2">
                  <img src={plusIcon} alt="plus icon" />
                </span>
                <span>TPS</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onAddTPSModal()}>
                  Add TPS
                </Dropdown.Item>
                <Dropdown.Item onClick={onImportTPS}>Import TPS</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div className="tps-list-table-main mt-3">
          {TPSList && TPSList.length > 0 && (
            <Table responsive bordered className="table table-bordered">
              <thead>
                <tr>
                  <th>SNo.</th>
                  <th>Name</th>
                  <th>Description</th>
                  {/* <th>Amount</th> */}
                  {/* <th>Revisions</th> */}
                  {(grantPermission("MasterTPS_delete") ||
                    grantPermission("MasterTPS_update")) && <th>Actions</th>}
                </tr>
              </thead>

              <tbody>
                {TPSList.map((each) => {
                  return (
                    <tr>
                      <td>{each.sNo}</td>
                      <td>{each.name}</td>
                      <td>{each.description}</td>
                      {/* <td>{each.amount}</td> */}
                      {/* <td>{each?.isRevision
                                                ? <img src={viewicon} onClick={() => onRevisionModal(each)} />
                                                : '-'

                                            }
                                            </td> */}
                      {(grantPermission("MasterTPS_delete") ||
                        grantPermission("MasterTPS_update")) && (
                        <td className="options">
                          <div className="d-flex gap-2">
                            {grantPermission("MasterTPS_update") &&
                              each?.status === "active" && (
                                <div
                                  onClick={() => onAddTPSModal(each)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={editicon} alt="Edit icon" />
                                </div>
                              )}
                            {grantPermission("MasterTPS_delete") && (
                              <div
                                onClick={() => onDeleteTPSModal(each)}
                                style={{ cursor: "pointer" }}
                              >
                                <img src={removeicon} alt="Remove icon" />
                              </div>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </div>
      )}
      {TPSList && TPSList.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalCount}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
            />
          </div>
        </div>
      )}
      <Modal
        centered
        show={onAddTPS}
        onHide={onAddTPSModal}
        className="add-department-modal-main"
        selected={selectedTPS}
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            {selectedTPS ? "Update" : "Add"} TPS
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <p>Enter TPS Details</p>
          <Formik
            initialValues={{
              name: selectedTPS?.name || "",
              description: selectedTPS?.description || "",
              amount: selectedTPS?.amount || "",
            }}
            validationSchema={TPSSchema}
            onSubmit={(values) => {
              selectedTPS ? updateTPS(values) : addTPS(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="form-group col-8 mt-3">
                  <PrimaryInput
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    name="name"
                    id="name"
                    placeholder="Enter Name"
                  />
                  <CustomErrorMessage
                    name="name"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <div className="form-group col-8 mt-3">
                  <PrimaryInput
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    name="description"
                    id="description"
                    placeholder="Enter Description"
                  />
                  <CustomErrorMessage
                    name="description"
                    errors={errors}
                    touched={touched}
                  />
                </div>
                {/*  <div className="form-group  col-8 mt-3">
                                <PrimaryInput
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.amount}
                                    name="amount"
                                    id="amount"
                                    placeholder="Enter Amount"
                                />
                                <CustomErrorMessage
                                    name="amount"
                                    errors={errors}
                                    touched={touched}
                                />
                            </div>
                    */}

                <div className="mt-3">
                  <div className="d-flex gap-2">
                    <Button
                      className="back-icon-btn-main"
                      onClick={onAddTPSModal}
                    >
                      <span className="ms-2">Back</span>
                    </Button>
                    <Button className="theme-btn-main" type="submit">
                      {buttonLoading ? (
                        <ButtonLoading />
                      ) : selectedTPS ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={onDeleteTPS}
        onHide={onDeleteTPSModal}
        className="add-department-modal-main"
        selected={selectedTPS}
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Delete TPS
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <p>{`Are You Sure You Want to Delete ${selectedTPS?.name}`}</p>
          <div className="mt-3">
            <div className="d-flex gap-2">
              <Button className="back-icon-btn-main" onClick={onDeleteTPSModal}>
                <span className="ms-2">Back</span>
              </Button>
              <Button
                className="theme-btn-main"
                type="submit"
                onClick={DeleteTPS}
              >
                {buttonLoading ? <ButtonLoading /> : "Delete"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={importTPSModal}
        onHide={onImportTPS}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Import TPS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <p className="mb-4 add-department-modal-content-text">
            Please select TPS.
          </p>
          <Formik
            initialValues={{
              fileName: "",
            }}
            validationSchema={TPSImportingSchema}
            onSubmit={(values) => uploadFile(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="row align-items-center form-group mb-3">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <Label>TPS</Label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                    <PrimaryInput
                      type="file"
                      onChange={(e) => {
                        setFieldValue("fileName", e?.target?.files[0]);
                      }}
                      onBlur={handleBlur}
                      accept=".xlsx,.xls"
                      name=" fileName"
                      id="fileName"
                    />
                    <CustomErrorMessage
                      name="fileName"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <span
                    onClick={() => downloadFile()}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Sample File
                  </span>
                </div>

                <div className="mt-3">
                  <div className="row">
                    <div className="col-6">
                      <Button
                        className="back-icon-btn-main"
                        onClick={onImportTPS}
                        style={{ width: "100%" }}
                      >
                        <span className="ms-2">Back</span>
                      </Button>
                    </div>
                    <div className="col-6">
                      <PrimaryButton
                        className="theme-btn-main"
                        type="submit"
                        title={buttonLoading ? <ButtonLoading /> : "Submit"}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        show={onRevision}
        onHide={onRevisionModal}
        className="add-department-modal-main"
        size="lg"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Revisions of {selectedTPS?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive bordered className="table table-bordered">
            <thead>
              <tr>
                <td>S.No.</td>
                <td>Description</td>
                <td>Amount</td>
                <td>Created At</td>
              </tr>
            </thead>
            <tbody>
              {revisionTPS &&
                revisionTPS.length > 0 &&
                revisionTPS.map((each) => (
                  <tr>
                    <td>{each?.sNo}</td>
                    <td>{each?.description}</td>
                    <td>{each?.amount}</td>
                    <td>
                      {moment(each?.createdAt).format("DD-MM-YYYY hh:mm")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="mt-3">
            <button className="btn btn-primary" onClick={onRevisionModal}>
              Back
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
