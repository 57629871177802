import React from "react";
import "./IconButton.scss";
import { Button } from "reactstrap";
import plusicon from "../../assets/images/icons/add.svg";

export default function IconButton(props) {
  return (
    <Button className="btn-with-icon" {...props}>
      <span>{props.icon}</span>
      {props.iconType === "add" ? <img src={plusicon} alt="add"/> : ''}
      <span className="btn-with-icon-title">{props.title}</span>
    </Button>
  );
}
