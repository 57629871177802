import React,{lazy,Suspense} from "react";
import { LazyLoader } from "../../../assets/loaders";

const LazyComponent=lazy(()=>import('./ResourceCost'))


export default function ProjectTPS() {
    return (
      <Suspense fallback={<LazyLoader />}>
        <LazyComponent />
      </Suspense>
    );
  }
  


