import qs from "qs";
import useConfig from "../hooks/useConfig";
import * as Storage from "../store/LocalStorage";
import axios from "axios";
import useAuth from "../hooks/useAuth";
import { notifyWarn } from "../assets/elements/Toast";

const badRespnseCodes = [401,];

export default function FetchServices() {
  const config = useConfig();
  let { user } = useAuth();
  const AxiosServices = {
    AxiosService: ({
      API,
      payload,
      authNeeded = true,
      type = "POST",
      token,
      concurrencyStamp,
    }) => {
      let authData = {};
      if (authNeeded) {
        authData = {
          token: user?.token ?? token,
        };
      }
      let header = {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        Authorization: authData.token,
        ...(concurrencyStamp
          ? { "x-coreplatform-concurrencystamp": concurrencyStamp }
          : {}),
      };

      if (type === "GET") {
        header = {
          "content-type": "application/json",
          Authorization: authData.token,
          ...(concurrencyStamp
            ? { "x-coreplatform-concurrencystamp": concurrencyStamp }
            : {}),
        };
      }
      return axios({
        method: type,
        url: API,
        headers: header,
        params:
          type === "GET" || type === "DELETE"
            ? {
              ...payload,
            }
            : {},
        data: qs.stringify({
          ...payload,
        }),
      })
        .then(function (response) {
          var resData = response.data;
          let headers = response.headers;
          let totalCount =
            headers["x-coreplatform-total-records"] &&
            headers["x-coreplatform-total-records"];
          let publicId = headers["public-id"] && headers["public-id"];
          let fileName = headers["file-name"] && headers["file-name"];
          if (!resData) {
            const errorRes = {
              data: resData.data,
              code: resData.code,
              success: false,
              ...(totalCount ? { totalCount } : {}),
              ...(publicId ? { publicId } : {}),
              ...(fileName ? { fileName } : {}),
            };

            if (response.status === 201 || response.status === 204) {
              return {
                success: true,
                ...(totalCount ? { totalCount } : {}),
                ...(publicId ? { publicId } : {}),
                ...(fileName ? { fileName } : {}),
              };
            }
            if (authNeeded) {
              const sessionResp = checkSession(errorRes);
              return sessionResp;
            } else {
              return errorRes;
            }
          }
          const apiResponse = resData;
          return {
            status: true,
            data: apiResponse,
            success: true,
            ...(totalCount ? { totalCount } : {}),
            ...(publicId ? { publicId } : {}),
            ...(fileName ? { fileName } : {}),
          };
        })
        .catch(function (res) {
          if (res?.response?.status === 403) {
            if (res?.response?.data?.message) {

              notifyWarn(res?.response?.data?.message)
            }
          }
          if (
            res &&
            res?.response &&
            res?.response?.status &&
            badRespnseCodes.indexOf(res?.response?.status) > -1
          ) {
            checkResponseCode(res);
          }
          console.log(res);
          console.log("An error occurred in " + API + "  service");
          return {
            status: false,
            ...res?.response?.data,
          };
        });
    },
  };
  const checkResponseCode = (data) => {
    Storage.clear();
    // window.location.reload();
  };

  const checkSession = function (data) {
    let message = data.msg;

    if (typeof message === "string" || message instanceof String) {
      message = message ? message.toLowerCase() : "";
      message = message.trim();
      if (
        message === "id or token wrong." ||
        message === "email id or token wrong." ||
        message === "invalid signature"
      ) {
        const sessionKey = config.sessionKey;
        Storage.remove(sessionKey);
        window.location.reload();

        return { ...data, sessionExpired: true };
      }
    }

    return { ...data, sessionExpired: false };
  };

  return AxiosServices;
}
