import React, { useState, useEffect, useRef } from "react";
import "./ctrlist.scss";
import { InputGroup, Form, Modal, Dropdown } from "react-bootstrap";
import { Label, Button } from "reactstrap";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import IconButton from "../../../assets/elements/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../../../assets/loaders/PageLoading";
import { Table } from "reactstrap";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/remove.svg";
import Pagination from "../../../assets/elements/CustomPagination";
import useCurrentPage from "../../../hooks/useCurrentPage";
import axios from "axios";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import { Formik, Field } from "formik";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { PrimaryInput } from "../../../assets/FormElements";
import Select from "react-select";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import * as Yup from "yup";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import AsyncSelect from "react-select/async";
import plusIcon from "../../../assets/images/icons/add.svg";
import { PrimaryButton } from "../../../assets/buttons";
import { squareBackIcon } from "../../../assets/images/icons/icons";
import { toast } from "react-toastify";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";
import viewicon from "../../../assets/images/icons/viewicon.svg";

import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import MainServices from "../../../services/MainServices";
import useAuth from "../../../hooks/useAuth";
import { ButtonLoader, LazyLoader } from "../../../assets/loaders";
import moment from "moment";
import options from "../../../assets/images/icons/options.svg";
import ProjectsDropdown from "../projects/ProjectDropdown";

export default function CTRList() {
  const { grantPermission } = PermissionFunctions();
  const [pageLoading, setPageLoading] = useState(false);
  const [ProjectCTR, setProjectCTR] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { updateCurrentPage } = useCurrentPage();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [searchString, setSearchString] = useState("");
  const [totalProjectCTR, setTotalProjectCTR] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [createCTRModal, setCreateCTRModal] = useState(false);
  const [selectedCTR, setSelectedCTR] = useState();
  const {
    getProjectDetails,
    downloadProgressReport,
    getResourcePoolsByDeptId,
    getProjectMasters,
    getCTRMasters,
    getDepartmentsMaster,
    getProjectCTR,
    addProjectCTR,
    deleteProjectCTR,
    updateProjectCTR,
    importProjectCTRById,
    getRevisionProjectCTR,
    getQueueStatus,
    ddrGeneration,
  } = MainServices();
  const [documentDepartments, setDocumentDepartments] = useState([]);
  const [selectedDeptId, setSelectedDeptId] = useState("");
  const { deBounce } = TimingFunctions();
  const [deleteCTRModal, setDeleteCTRModal] = useState(false);
  const [isDisciplineIdChanging, setDisciplineIdChanging] = useState(false);
  const [CTRs, setCTRs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [isResPool, setIsResPool] = useState("");
  const [resourcePools, setResourcePools] = useState([]);
  const [isCTRChanging, setIsCTRChanging] = useState(false);
  const [importCtrModal, setImportCtrModal] = useState(false);
  const { user } = useAuth();
  const [importLoading, setImportLoading] = useState(false);
  const [onRevision, setOnRevision] = useState(false);
  const [revisionCTR, setRevisionCTR] = useState();
  const [project, setProject] = useState({});
  const [ddrLoading, setDDRLoading] = useState(false);

  let PCTRpCTRTimeOutRef = useRef(null);

  const handleResPoolChange = (e) => {
    setIsResPool(e.target.value);
    !e.target.value && setResourcePools([]);
  };

  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);
  const projectId = new URLSearchParams(location.search).get("projectId");

  const addCTRSchema = Yup.object().shape({
    ctrId: Yup.mixed().required("CTR  is Required"),
    isResPool: Yup.boolean().required("isResPool is Required"),
    // totalHours: isResPool && Yup.string().required("Total Hours is Required"),

    totalDeliverableNumber: Yup.number().required(
      "Total Deliverable Number is Required"
    ),
    totalHoursPerDeliverable: Yup.string().required(
      "Total Hours Per Deliverable is Required"
    ),
    remarks: Yup.string() .test(
      "not-only-whitespace",
      " Field  cannot be empty or only spaces",
      (value) => value && value.trim().length > 0
    ).required("Remarks is Required"),
    principalEngineer: Yup.string().required("B02-01 Percentage is Required"),
    seniorGroupA: Yup.string().required("B02-02 Percentage is Required"),
    seniorGroupB: Yup.string().required("B02-03 Percentage is Required"),
    engineerGroupA: Yup.string().required("B02-04 Percentageis Required"),
    engineerGroupB: Yup.string().required("B02-05 Percentageis Required"),
    designerGroupA: Yup.string().required("B02-06 Percentage is Required"),
    designerGroupB: Yup.string().required("B02-0 Percentage is Required"),
  });

  const updateCTRSchema = Yup.object().shape({
    // code: Yup.string().required("Code is Required"),
    // title: Yup.string().required("Title is Required"),
    // description: Yup.string().required("Description is Required"),
  });

  const ctrImportingSchema = Yup.object().shape({
    fileName: Yup.string().required("Select file to be import"),
  });

  useEffect(() => {
    getDepartmentsMasters();
  }, []);
  useEffect(() => {
    if (projectId) getProjectDetailsPage();

    // eslint-disable-next-line
  }, []);

  const handleProgressReportDownloadClick = async () => {
    const details = {
      projectId,
    };

    await axios
      .all([downloadProgressReport(details)])
      .then((response) => {
        if (response.length > 0) {
          const data = response[0].data;
          const fileName = data.response["fileName"];
          console.log(fileName);
          if (fileName) {
            const path = `https://files.appsarizona.com/galfar-apis/server/exports/engineer/${fileName}`;
            console.log(path);
            downloadProgressFile(path);
          } else {
            console.error("File path not found in response.");
          }
        } else {
          console.error("File path not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  const downloadProgressFile = (filePath) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", "progress_report.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  const getProjectDetailsPage = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getProjectDetails(details, projectId)])
      .then(function (res) {
        if (res) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : {};
            setProject(resData);

            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Projects details");
      });
  };

  const getResourcePoolByCTRId = async (deptId, setFieldValue) => {
    setPageLoading(true);
    const details = {
      filters: [{ key: "deptId", eq: deptId }],
    };
    await axios
      .all([getResourcePoolsByDeptId(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let resourcePools = resData.data ? resData.data : [];

            resourcePools = resourcePools.map((_, index) => {
              return {
                ..._,
                label: _?.deptartment.deptName,
                value: _.publicId,
              };
            });
            setResourcePools(resourcePools);
            setFieldValue("resPoolId", resourcePools[0]);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Resource Pool");
      });
  };
  const getDepartmentsMasters = async () => {
    setPageLoading(true);
    const details = {
      pageSize: 100,
      pageNumber: 1,
    };

    await axios
      .all([getDepartmentsMaster(details)])
      .then(function (res) {
        let resData = res[0].data ? res[0].data : [];
        resData = resData.map((_) => {
          return {
            ..._,
            value: _.publicId,
            label: _.deptName,
          };
        });

        setDocumentDepartments(resData);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const allStatus = [
    { label: "All", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  useEffect(() => {
    updateCurrentPage({
      title: "CTR List",
      subtitle: "",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let filter = [];

    getCTRList({
      filters: filter,
      initial: true,
    });
    getProjectList({
      filters: [{ key: "publicId", eq: projectId }],
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      if (PCTRpCTRTimeOutRef.current) {
        clearTimeout(PCTRpCTRTimeOutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setSelectedStatus(filtersObj?.status?.status || "");
    setFilters(filtersObj);
    setSearchString(filtersObj?.name?.name);
    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 50,
    });

    getProjectCTRByStatus({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 50,
      filters: [...filters, { key: "projectId", eq: projectId }],
    });
    // eslint-disable-next-line
  }, [changedParams.page, changedParams.limit, changedParams.filters]);

  const getProjectCTRByStatus = async ({ page, limit, filters }) => {
    if (filters && Array.isArray(filters)) {
      filters = [...filters, { key: "status", eq: "active" }];
    } else {
      filters = [{ key: "status", eq: "active" }];
    }
    setPageLoading(true);
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
      sorting: [{ key: "created_at", direction: "asc" }],
    };
    await axios
      .all([getProjectCTR(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let ProjectCTRs = resData.data ? resData.data : [];
            let total = resData?.totalCount || 0;
            setTotalProjectCTR(total);
            ProjectCTRs = ProjectCTRs.map((_, index) => {
              return {
                ..._,
                sNo: limit * (page - 1) + index + 1,
                // deptartment: {
                //   ..._.deptartment,
                //   label: _.deptartment.deptName,
                //   value: _.deptartment.publicId
                //  }
              };
            });
            setProjectCTR(ProjectCTRs);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Projects");
      });
  };

  const onCreateProjectCTR = (selected) => {
    if (selected) {
      const updatedSelected = { ...selected }; // Create a copy of the selected object to avoid mutating the original
      updatedSelected.masterCtr = {
        ...selected.masterCtr,
        label: selected.masterCtr.code,
        value: selected.masterCtr.publicId,
      };
      console.log("selected", updatedSelected);
      setSelectedCTR(updatedSelected);
    } else {
      setSelectedCTR("");
    }
    setCreateCTRModal(!createCTRModal);
  };

  const onDeleteProjectCTR = (selected) => {
    if (selected) {
      setSelectedCTR(selected);
    } else {
      setSelectedCTR("");
    }
    setDeleteCTRModal(!deleteCTRModal);
  };

  const addingProjectCTR = async (values) => {
    setButtonLoading(true);
    const details = {
      ctrId: values?.ctrId.publicId,
      projectId,
      isResPool: values?.isResPool,

      ...(values.isResPool && { resPoolId: values?.resPoolId?.publicId }),

      ...(!values.isResPool
        ? {
            totalHours:
              +values?.principalEngineer +
              +values?.seniorGroupA +
              +values?.seniorGroupB +
              +values?.engineerGroupA +
              +values?.engineerGroupB +
              +values?.designerGroupA +
              +values?.designerGroupB,
          }
        : { totalHours: values?.totalHours }),

      totalDeliverableNumber: values?.totalDeliverableNumber,
      totalHoursPerDeliverable: values?.totalHoursPerDeliverable,
      remarks: values?.remarks,

      principalEngineer: !values?.isResPool
        ? values?.principalEngineer
        : (resourcePools[0].principalEngineer * values.totalHours) / 100,
      seniorGroupA: !values?.isResPool
        ? values?.seniorGroupA
        : (resourcePools[0].seniorGroupA * values.totalHours) / 100,
      seniorGroupB: !values?.isResPool
        ? values?.seniorGroupB
        : (resourcePools[0].seniorGroupB * values.totalHours) / 100,
      engineerGroupA: !values?.isResPool
        ? values?.engineerGroupA
        : (resourcePools[0].engineerGroupA * values.totalHours) / 100,
      engineerGroupB: !values?.isResPool
        ? values?.engineerGroupB
        : (resourcePools[0].engineerGroupB * values.totalHours) / 100,
      designerGroupA: !values?.isResPool
        ? values?.designerGroupA
        : (resourcePools[0].designerGroupA * values.totalHours) / 100,
      designerGroupB: !values?.isResPool
        ? values?.designerGroupB
        : (resourcePools[0].designerGroupB * values.totalHours) / 100,
    };

    await axios
      .all([addProjectCTR(details)])
      .then(function (res) {
        console.log("res", res);

        if (res[0]) {
          let resData = res[0];

          if (resData.success) {
            notifySuccess("Project CTR  Added Successfully");

            getProjectCTRByStatus({
              page: 1,
              limit: 50,
              filters: [{ key: "projectId", eq: projectId }],
            });

            onCreateProjectCTR();
            setButtonLoading(false);
          } else if (resData.details && resData.details.length > 0) {
            notifyWarn(resData.details[0].message);
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in adding Project CTR");
      });
  };
  const generateDDR = async () => {
    setDDRLoading(true);
    const details = {};

    await axios
      .all([ddrGeneration(details, projectId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            notifySuccess(" DDR  Generated Successfully");
            setDDRLoading(false);
          } else if (resData.details && resData.details.length > 0) {
            notifyWarn(resData.details[0].message);
            setDDRLoading(false);
          } else {
            setDDRLoading(false);
          }
        } else {
          setDDRLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in  DDR generation");
      });
  };

  const updatingProjectCTR = async (values) => {
    setButtonLoading(true);
    const details = {
      ...(isCTRChanging && { ctrId: values?.ctrId.publicId }),
      projectId,
      isResPool: values?.isResPool,

      ...(values.isResPool && { resPoolId: values?.resPoolId?.publicId }),

      ...(!values.isResPool
        ? {
            totalHours:
              +values?.principalEngineer +
              +values?.seniorGroupA +
              +values?.seniorGroupB +
              +values?.engineerGroupA +
              +values?.engineerGroupB +
              +values?.designerGroupA +
              +values?.designerGroupB,
          }
        : { totalHours: values?.totalHours }),

      totalDeliverableNumber: values?.totalDeliverableNumber,
      totalHoursPerDeliverable: values?.totalHoursPerDeliverable,
      remarks: values?.remarks,

      principalEngineer: !values?.isResPool
        ? values?.principalEngineer
        : (resourcePools[0].principalEngineer * values.totalHours) / 100,
      seniorGroupA: !values?.isResPool
        ? values?.seniorGroupA
        : (resourcePools[0].seniorGroupA * values.totalHours) / 100,
      seniorGroupB: !values?.isResPool
        ? values?.seniorGroupB
        : (resourcePools[0].seniorGroupB * values.totalHours) / 100,
      engineerGroupA: !values?.isResPool
        ? values?.engineerGroupA
        : (resourcePools[0].engineerGroupA * values.totalHours) / 100,
      engineerGroupB: !values?.isResPool
        ? values?.engineerGroupB
        : (resourcePools[0].engineerGroupB * values.totalHours) / 100,
      designerGroupA: !values?.isResPool
        ? values?.designerGroupA
        : (resourcePools[0].designerGroupA * values.totalHours) / 100,
      designerGroupB: !values?.isResPool
        ? values?.designerGroupB
        : (resourcePools[0].designerGroupB * values.totalHours) / 100,
    };

    await axios
      .all([
        updateProjectCTR(
          details,
          selectedCTR.publicId,
          selectedCTR.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getProjectCTRByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters: [{ key: "projectId", eq: projectId }],
            });
            onCreateProjectCTR();
            notifySuccess("Project CTR  Updated Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in deleting Project CTR ");
      });
  };

  const deletingProjectCTR = async () => {
    setButtonLoading(true);
    const details = {
      status: "inactive",
    };

    await axios
      .all([
        deleteProjectCTR(
          details,
          selectedCTR.publicId,
          selectedCTR.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getProjectCTRByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters: [{ key: "projectId", eq: projectId }],
            });
            onDeleteProjectCTR();
            notifySuccess("Project CTR  Deleted Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in deleting Project CTR ");
      });
  };

  const getUploadStatus = async (jobId) => {
    const details = {};

    await axios
      .all([getQueueStatus(details, jobId)])
      .then((res) => {
        if (res[0]) {
          let status = res[0]?.data?.import_status;

          if (status === "completed") {
            toast.success("Project CTR imported successfully");
            clearTimeout(PCTRpCTRTimeOutRef.current);
            getProjectCTRByStatus({
              page: changedParams?.page || 1,
              limit: changedParams.limit || 50,
              filters: [{ key: "projectId", eq: projectId }],
            });

            setImportLoading(false);
          } else {
            PCTRpCTRTimeOutRef.current = setTimeout(() => {
              getProjectCTRByStatus({
                page: changedParams?.page || 1,
                limit: changedParams.limit || 50,
                filters: [{ key: "projectId", eq: projectId }],
              });
              getUploadStatus(jobId);
            }, 3000);
          }
        }
      })
      .catch((err) => console.log(err));
  };
  const uploadFile = async (values) => {
    setButtonLoading(true);
    const details = {
      projectId,
    };
    const documents = values.fileName;

    await axios
      .all([importProjectCTRById(documents, details, user?.token)])
      .then(async function (res) {
        if (res[0]) {
          let resData = res[0];
          onImportCtr();
          setButtonLoading(false);

          let jobId = res[0]?.data?.data?.jobId;
          if (jobId) {
            setImportLoading(true);
            PCTRpCTRTimeOutRef.current = setTimeout(() => {
              getUploadStatus(jobId);
            }, 1000);
          }
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in file upload");
      });
  };

  const searchProjectCTR = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      code: { code: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: 50,
      filters: mfilters,
      projectId,
    });
    navigate({
      search,
    });
  };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      projectId,
    });
    navigate({
      search,
    });
  };

  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      projectId,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
      projectId,
    });
    navigate({ search });
  };

  const onImportCtr = () => {
    setImportCtrModal(!importCtrModal);
  };

  const customStylesForCTRSelect = {
    control: (provided) => ({
      ...provided,
      border: "1px solid black",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#ffffff",
      //padding: "6px 5px",
      marginBottom: "0",
      paddingLeft: "5px",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f5f2ed" : "white",
      color: "#000000",
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingInline: "5px",
    }),
  };

  const loadDebouncCTR = (inputValue, callback) => {
    deBounce(() => loadOptionsForCTR(inputValue, callback), 1000);
  };

  const loadOptionsForCTR = async (inputValue, callback) => {
    if (!inputValue) {
      callback(CTRs);
    }
    let filter = [
      {
        key: "code",
        iLike: inputValue,
      },
    ];
    let options = await getCTRList({ filters: filter, initial: false });

    callback(options);
  };

  const getProjectList = async ({ filters }) => {
    const details = {
      filters,
    };
    await getProjectMasters(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((_) => {
              return {
                ..._,

                label: _.name,
                value: _.publicId,
              };
            });

            setProjects(resData);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCTRList = async ({ page, limit, filters, initial }) => {
    let data = [];
    const details = {
      pageSize: limit || 100,
      pageNumber: page || 1,
      filters,
    };
    await getCTRMasters(details)
      .then((res) => {
        if (res) {
          let resData = res;
          if (resData.success) {
            resData = resData.data ? resData.data : [];
            resData = resData.map((_) => {
              return {
                ..._,

                label: _.code,
                value: _.publicId,
              };
            });

            data = resData;
            initial && setCTRs(resData);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return data;
  };

  const downloadFile = (filePath) => {
    const link = document.createElement("a");
    link.href =
      "https://files.appsarizona.com/galfar-apis/server/exports/demo/CTR-Deliverable.xlsx";
    link.setAttribute("download", "sample_ctr_deliverable.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  const getRevisionCTRById = async (selected) => {
    let details = {};
    await axios
      .all([getRevisionProjectCTR(details, selected?.publicId)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0].data ? res[0].data : [];
          resData.unshift(selected);
          resData = resData.map((each, index) => {
            return {
              ...each,
              sNo: index + 1,
            };
          });
          console.log("revision pctr", resData);
          setRevisionCTR(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRevisionModal = (selected) => {
    if (selected) {
      setSelectedCTR(selected);
      getRevisionCTRById(selected);
    } else {
      setSelectedCTR("");
    }
    setOnRevision(!onRevision);
  };

  return (
    <div className="ctr-list-main">
      <div className="ctr-list-header-section">
        <div className="d-flex gap-1 align-items-center">
          <div className="back-btn-function">
            <img src={ArrowBack} alt="" onClick={() => navigate(-1)} />
          </div>
          <div className="ctr-list-heading">
            CTR Deliverable -{project?.name} ({`${totalProjectCTR}`})
          </div>
        </div>
        <div className="d-flex gap-3">
          {importLoading && <ButtonLoader color="black" />}
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchProjectCTR(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>

          <div className="product-list-header-flex" style={{ width: "8rem" }}>
            <select
              class="form-control form-select custom-status-select"
              id="fv-topics"
              name="status"
              data-placeholder="Select a option"
              required
              onChange={onStatusChange}
              value={selectedStatus}
            >
              <option label="Select Status" value=""></option>
              {allStatus.map((each) => (
                <option value={each.value} key={each.value}>
                  {each.label}
                </option>
              ))}
            </select>
          </div>

          {grantPermission("CTRDeliverable_write") && (
            <Dropdown className="trainer-type-dropdown-main">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="trainer-type-dropdown"
              >
                <span className="me-2">
                  <img src={plusIcon} alt="plus icon" />
                </span>
                <span>Project CTR</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onCreateProjectCTR()}>
                  Add Project CTR
                </Dropdown.Item>
                <Dropdown.Item onClick={onImportCtr}>
                  Import Project CTR
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {grantPermission("Document_write") &&
            ProjectCTR &&
            ProjectCTR.length > 0 && (
              <Dropdown className="trainer-type-dropdown-main">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="trainer-type-dropdown"
                  onClick={() => {
                    !ddrLoading && generateDDR();
                  }}
                >
                  {ddrLoading ? (
                    <ButtonLoader />
                  ) : (
                    <>
                      <span className="me-2">
                        <img src={plusIcon} alt="plus icon" />
                      </span>
                      <span>Generate DDR</span>{" "}
                    </>
                  )}
                </Dropdown.Toggle>
              </Dropdown>
            )}

          <ProjectsDropdown
            projectId={project?.publicId}
            projectType={project?.projectType}
            page="ctr"
          />
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : ProjectCTR && ProjectCTR.length > 0 ? (
        <div className="content mt-3">
          <div className="table-main">
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr>
                  <th>#</th>
                  {/* <th>Project</th> */}
                  <th>CTR Code</th>
                  <th>Total Hours</th>
                  <th>B02-01</th>
                  <th>B02-02</th>
                  <th>B02-03</th>
                  <th>B02-04</th>
                  <th>B02-05</th>
                  <th>B02-06</th>
                  <th>B02-07</th>
                  <th>No Of Deliverables</th>
                  <th>Revisions</th>

                  {(grantPermission("CTRDeliverable_delete") ||
                    grantPermission("CTRDeliverable_update")) &&
                    user.user_id === project.projectEngineer && (
                      <th>Actions</th>
                    )}
                </tr>
              </thead>
              <tbody>
                {ProjectCTR.map((each) => {
                  return (
                    <tr>
                      <td>{each?.sNo}</td>
                      {/* <td>{each?.project?.name}</td> */}
                      <td>{each?.masterCtr?.code}</td>
                      <td>{each?.totalHours}</td>
                      <td>{parseFloat(each?.principalEngineer).toFixed(2)}</td>
                      <td>{parseFloat(each?.seniorGroupA).toFixed(2)}</td>
                      <td>{parseFloat(each?.seniorGroupB).toFixed(2)}</td>
                      <td>{parseFloat(each?.engineerGroupA).toFixed(2)}</td>
                      <td>{parseFloat(each?.engineerGroupB).toFixed(2)}</td>
                      <td>{parseFloat(each?.designerGroupA).toFixed(2)}</td>
                      <td>{parseFloat(each?.designerGroupB).toFixed(2)}</td>
                      <td>{each?.totalDeliverableNumber}</td>
                      <td>
                        {each?.isRevision ? (
                          <img
                            src={viewicon}
                            onClick={() => onRevisionModal(each)}
                          />
                        ) : (
                          "-"
                        )}
                      </td>

                      {(grantPermission("CTRDeliverable_delete") ||
                        grantPermission("CTRDeliverable_update")) &&
                        user.user_id === project.projectEngineer && (
                          <td className="options">
                            <div className="d-flex gap-2">
                              {grantPermission("CTRDeliverable_update") &&
                                each?.status === "active" && (
                                  <div
                                    onClick={() => onCreateProjectCTR(each)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={editicon} alt="Edit icon" />
                                  </div>
                                )}
                              {grantPermission("CTRDeliverable_delete") && (
                                <div
                                  onClick={() => onDeleteProjectCTR(each)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={removeicon} alt="Remove icon" />
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="no-data-found-main">No CTR Found</div>
      )}
      {ProjectCTR && ProjectCTR.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalProjectCTR}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
              onRowsPerPage={onRowsPerPage}
            />
          </div>
        </div>
      )}
      <Modal
        centered
        show={createCTRModal}
        onHide={onCreateProjectCTR}
        className="add-department-modal-main"
        selected={selectedCTR}
        size="xl"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Create A New Project CTR
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              ctrId: selectedCTR?.masterCtr || "",

              totalHours: selectedCTR?.totalHours || "",
              totalDeliverableNumber: selectedCTR?.totalDeliverableNumber || "",
              totalHoursPerDeliverable:
                selectedCTR?.totalHoursPerDeliverable || "",
              remarks: selectedCTR?.remarks || "",
              isResPool: selectedCTR?.isResPool || "",
              resPoolId: selectedCTR?.resPoolId || "",
              principalEngineer: selectedCTR?.principalEngineer || "",
              seniorGroupA: selectedCTR?.seniorGroupA || "",
              seniorGroupB: selectedCTR?.seniorGroupB || "",
              engineerGroupA: selectedCTR?.engineerGroupA || "",
              engineerGroupB: selectedCTR?.engineerGroupB || "",
              designerGroupA: selectedCTR?.designerGroupA || "",
              designerGroupB: selectedCTR?.designerGroupB || "",
            }}
            validationSchema={selectedCTR ? updateCTRSchema : addCTRSchema}
            onSubmit={(values) => {
              selectedCTR
                ? updatingProjectCTR(values)
                : addingProjectCTR(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setErrors,
              setTouched,
              setFieldValue,
            }) => (
              <div>
                <div className="add-project-card-main mt-3">
                  <Form className="form" onSubmit={handleSubmit}>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Project Name</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          type="text"
                          placeholder=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={projects[0].name}
                          name="projects"
                          id="projects"
                          disabled
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                        <CustomErrorMessage
                          name="projects"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>CTR</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <AsyncSelect
                          styles={customStylesForCTRSelect}
                          className="select-role async-select"
                          placeholder={`${
                            selectedCTR
                              ? selectedCTR?.masterCtr.code
                              : "Select CTR"
                          }`}
                          loadOptions={loadDebouncCTR}
                          defaultOptions={CTRs}
                          onChange={(e) => {
                            setFieldValue("ctrId", e);
                            getResourcePoolByCTRId(
                              e?.deptartment.publicId,
                              setFieldValue
                            );
                            setIsCTRChanging(true);
                          }}
                          name="ctrId"
                          cacheOptions
                        />
                        <CustomErrorMessage
                          name="ctrId"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Title</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          type="text"
                          placeholder="Enter Title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.ctrId?.title}
                          name="title"
                          id="title"
                          disabled
                          style={{ backgroundColor: "#e9ecef" }}
                        />
                        <CustomErrorMessage
                          name="title"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Resource Pool</Label>
                      </div>

                      <div className="col-12 col-sm-12 col-md-7 col-lg-7 d-flex gap-5">
                        <label>
                          <Field
                            type="radio"
                            name="isResPool"
                            value="true"
                            checked={values.isResPool === true}
                            onChange={(e) => {
                              handleResPoolChange(e);
                              setFieldValue("isResPool", true);
                              setFieldValue(
                                "principalEngineer",
                                resourcePools[0].principalEngineer
                              );
                              setFieldValue(
                                "seniorGroupA",
                                resourcePools[0].seniorGroupA
                              );
                              setFieldValue(
                                "seniorGroupB",
                                resourcePools[0].seniorGroupB
                              );
                              setFieldValue(
                                "engineerGroupA",
                                resourcePools[0].engineerGroupA
                              );
                              setFieldValue(
                                "engineerGroupB",
                                resourcePools[0].engineerGroupB
                              );
                              setFieldValue(
                                "designerGroupA",
                                resourcePools[0].designerGroupA
                              );
                              setFieldValue(
                                "designerGroupB",
                                resourcePools[0].designerGroupB
                              );
                            }}
                          />
                          Yes
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="isResPool"
                            value="false"
                            checked={values.isResPool === false}
                            onChange={(e) => {
                              handleResPoolChange(e);
                              setFieldValue("isResPool", false);
                            }}
                          />
                          No
                        </label>
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Resource Pool</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <Select
                          className="sop-custom-form-select"
                          id="resPoolId"
                          name="resPoolId"
                          placeholder="Select Resource Pool"
                          onChange={(e) => {
                            setFieldValue("resPoolId", e);
                          }}
                          value={values.resPoolId}
                          onBlur={handleBlur}
                          //options={resourcePools}
                          isDisabled={true}
                        />
                        <CustomErrorMessage
                          name="resPoolId"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Total Hours</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          placeholder="Enter Total Hours"
                          type="number"
                          onChange={(e) => {
                            handleChange(e);
                            let del_num = values.totalDeliverableNumber || 0;
                            let totalHours = e.target.value || 0;
                            if (del_num && totalHours) {
                              let del_hours = +(totalHours / del_num).toFixed(
                                2
                              );
                              setFieldValue(
                                "totalHoursPerDeliverable",
                                del_hours
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          value={
                            !values.isResPool
                              ? +values?.principalEngineer +
                                +values?.seniorGroupA +
                                +values?.seniorGroupB +
                                +values?.engineerGroupA +
                                +values?.engineerGroupB +
                                +values?.designerGroupA +
                                +values?.designerGroupB
                              : values.totalHours
                          }
                          name="totalHours"
                          id="totalHours"
                          disabled={!values.isResPool}
                          style={{
                            backgroundColor: !values.isResPool
                              ? "#e9ecef"
                              : "inherit",
                          }}
                        />
                        <CustomErrorMessage
                          name="totalHours"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Total Deliverable Number</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          type="number"
                          placeholder="Enter Total Deliverable Number"
                          onChange={(e) => {
                            handleChange(e);
                            let del_num = e.target.value || 0;
                            let totalHours = !values.isResPool
                              ? +values?.principalEngineer +
                                +values?.seniorGroupA +
                                +values?.seniorGroupB +
                                +values?.engineerGroupA +
                                +values?.engineerGroupB +
                                +values?.designerGroupA +
                                +values?.designerGroupB
                              : values.totalHours;
                            if (del_num && totalHours) {
                              let del_hours = +(totalHours / del_num).toFixed(
                                2
                              );
                              console.log(del_hours);
                              setFieldValue(
                                "totalHoursPerDeliverable",
                                del_hours
                              );
                            }
                          }}
                          onBlur={handleBlur}
                          value={values.totalDeliverableNumber}
                          name="totalDeliverableNumber"
                          id="totalDeliverableNumber"
                        />
                        <CustomErrorMessage
                          name="totalDeliverableNumber"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Total Hours Per Deliverable</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          type="text"
                          placeholder="Enter Total Hours Per Deliverable"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.totalHoursPerDeliverable}
                          name="totalHoursPerDeliverable"
                          id="totalHoursPerDeliverable"
                          disabled={true}
                        />
                        <CustomErrorMessage
                          name="totalHoursPerDeliverable"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Remarks</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          type="text"
                          placeholder="Enter Remarks"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.remarks}
                          name="remarks"
                          id="remarks"
                        />
                        <CustomErrorMessage
                          name="remarks"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row mt-5 mb-5">
                      <div style={{ width: "14%" }}>
                        <div className="">
                          <Label>B02-01</Label>
                        </div>
                        <div className="">
                          <PrimaryInput
                            type="text"
                            placeholder="0"
                            onChange={(e) => {
                              handleChange(e);
                              let del_num = values.totalDeliverableNumber || 0;
                              let totalHours =
                                +(e.target.value || 0) +
                                +values?.seniorGroupA +
                                +values?.seniorGroupB +
                                +values?.engineerGroupA +
                                +values?.engineerGroupB +
                                +values?.designerGroupA +
                                +values?.designerGroupB;
                              if (del_num && totalHours) {
                                let del_hours = +(totalHours / del_num).toFixed(
                                  2
                                );
                                console.log(del_hours);
                                setFieldValue(
                                  "totalHoursPerDeliverable",
                                  del_hours
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            value={
                              (values.totalHours &&
                                values.isResPool &&
                                `${
                                  (values.principalEngineer *
                                    values.totalHours) /
                                  100
                                }(${values.principalEngineer}%)`) ||
                              values.principalEngineer
                            }
                            name="principalEngineer"
                            id="principalEngineer"
                            className=""
                            disabled={values.isResPool}
                            style={{
                              backgroundColor: values.isResPool
                                ? "#e9ecef"
                                : "inherit",
                              fontWeight: 700,
                            }}
                          />
                          <CustomErrorMessage
                            name="principalEngineer"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div style={{ width: "14%" }}>
                        <div className="">
                          <Label>B02-02</Label>
                        </div>
                        <div className="">
                          <PrimaryInput
                            type="text"
                            placeholder="0"
                            onChange={(e) => {
                              handleChange(e);
                              let del_num = values.totalDeliverableNumber || 0;
                              let totalHours =
                                +values.principalEngineer +
                                +(e.target.value || 0) +
                                +values?.seniorGroupB +
                                +values?.engineerGroupA +
                                +values?.engineerGroupB +
                                +values?.designerGroupA +
                                +values?.designerGroupB;
                              if (del_num && totalHours) {
                                let del_hours = +(totalHours / del_num).toFixed(
                                  2
                                );
                                console.log(del_hours);
                                setFieldValue(
                                  "totalHoursPerDeliverable",
                                  del_hours
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            value={
                              (values.totalHours &&
                                values.isResPool &&
                                `${
                                  (values.seniorGroupA * values.totalHours) /
                                  100
                                }(${values.seniorGroupA}%)`) ||
                              values.seniorGroupA
                            }
                            name="seniorGroupA"
                            id="seniorGroupA"
                            className=""
                            disabled={values.isResPool}
                            style={{
                              backgroundColor: values.isResPool
                                ? "#e9ecef"
                                : "inherit",
                              fontWeight: 700,
                            }}
                          />
                          <CustomErrorMessage
                            name="seniorGroupA"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>

                      <div style={{ width: "14%" }}>
                        <div className="">
                          <Label>B02-03</Label>
                        </div>
                        <div className="">
                          <PrimaryInput
                            type="text"
                            placeholder="0"
                            onChange={(e) => {
                              handleChange(e);
                              let del_num = values.totalDeliverableNumber || 0;
                              let totalHours =
                                +values.principalEngineer +
                                +values.seniorGroupA +
                                +(e.target.value || 0) +
                                +values?.engineerGroupA +
                                +values?.engineerGroupB +
                                +values?.designerGroupA +
                                +values?.designerGroupB;
                              if (del_num && totalHours) {
                                let del_hours = +(totalHours / del_num).toFixed(
                                  2
                                );
                                console.log(del_hours);
                                setFieldValue(
                                  "totalHoursPerDeliverable",
                                  del_hours
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            value={
                              (values.totalHours &&
                                values.isResPool &&
                                `${
                                  (values.seniorGroupB * values.totalHours) /
                                  100
                                }(${values.seniorGroupB}%)`) ||
                              values.seniorGroupB
                            }
                            name="seniorGroupB"
                            id="seniorGroupB"
                            className=""
                            disabled={values.isResPool}
                            style={{
                              backgroundColor: values.isResPool
                                ? "#e9ecef"
                                : "inherit",
                              fontWeight: 700,
                            }}
                          />
                          <CustomErrorMessage
                            name="seniorGroupB"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div style={{ width: "14%" }}>
                        <div className="">
                          <Label>B02-04</Label>
                        </div>
                        <div className="">
                          <PrimaryInput
                            type="text"
                            placeholder="0"
                            onChange={(e) => {
                              handleChange(e);
                              let del_num = values.totalDeliverableNumber || 0;
                              let totalHours =
                                +values.principalEngineer +
                                +values.seniorGroupA +
                                +values.seniorGroupB +
                                +(e.target.value || 0) +
                                +values?.engineerGroupB +
                                +values?.designerGroupA +
                                +values?.designerGroupB;
                              if (del_num && totalHours) {
                                let del_hours = +(totalHours / del_num).toFixed(
                                  2
                                );
                                console.log(del_hours);
                                setFieldValue(
                                  "totalHoursPerDeliverable",
                                  del_hours
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            value={
                              (values.totalHours &&
                                values.isResPool &&
                                `${
                                  (values.engineerGroupA * values.totalHours) /
                                  100
                                }(${values.engineerGroupA}%)`) ||
                              values.engineerGroupA
                            }
                            name="engineerGroupA"
                            id="engineerGroupA"
                            className=""
                            disabled={values.isResPool}
                            style={{
                              backgroundColor: values.isResPool
                                ? "#e9ecef"
                                : "inherit",
                              fontWeight: 700,
                            }}
                          />
                          <CustomErrorMessage
                            name="engineerGroupA"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div style={{ width: "14%" }}>
                        <div className="">
                          <Label>B02-05</Label>
                        </div>
                        <div className="">
                          <PrimaryInput
                            type="text"
                            placeholder="0"
                            onChange={(e) => {
                              handleChange(e);
                              let del_num = values.totalDeliverableNumber || 0;
                              let totalHours =
                                +values.principalEngineer +
                                +values.seniorGroupA +
                                +values.seniorGroupB +
                                +values?.engineerGroupA +
                                +(e.target.value || 0) +
                                +values?.designerGroupA +
                                +values?.designerGroupB;
                              if (del_num && totalHours) {
                                let del_hours = +(totalHours / del_num).toFixed(
                                  2
                                );
                                console.log(del_hours);
                                setFieldValue(
                                  "totalHoursPerDeliverable",
                                  del_hours
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            value={
                              (values.totalHours &&
                                values.isResPool &&
                                `${
                                  (values.engineerGroupB * values.totalHours) /
                                  100
                                }(${values.engineerGroupB}%)`) ||
                              values.engineerGroupB
                            }
                            name="engineerGroupB"
                            id="engineerGroupB"
                            className=""
                            disabled={values.isResPool}
                            style={{
                              backgroundColor: values.isResPool
                                ? "#e9ecef"
                                : "inherit",
                              fontWeight: 700,
                            }}
                          />
                          <CustomErrorMessage
                            name="engineerGroupB"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div style={{ width: "14%" }}>
                        <div className="">
                          <Label>B02-06</Label>
                        </div>
                        <div className="">
                          <PrimaryInput
                            type="text"
                            placeholder="0"
                            onChange={(e) => {
                              handleChange(e);
                              let del_num = values.totalDeliverableNumber || 0;
                              let totalHours =
                                +values.principalEngineer +
                                +values.seniorGroupA +
                                +values.seniorGroupB +
                                +values?.engineerGroupA +
                                +values?.engineerGroupB +
                                +(e.target.value || 0) +
                                +values?.designerGroupB;
                              if (del_num && totalHours) {
                                let del_hours = +(totalHours / del_num).toFixed(
                                  2
                                );
                                console.log(del_hours);
                                setFieldValue(
                                  "totalHoursPerDeliverable",
                                  del_hours
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            value={
                              (values.totalHours &&
                                values.isResPool &&
                                `${
                                  (values.designerGroupA * values.totalHours) /
                                  100
                                }(${values.designerGroupA}%)`) ||
                              values.designerGroupA
                            }
                            name="designerGroupA"
                            id="designerGroupA"
                            className=""
                            disabled={values.isResPool}
                            style={{
                              backgroundColor: values.isResPool
                                ? "#e9ecef"
                                : "inherit",
                              fontWeight: 700,
                            }}
                          />
                          <CustomErrorMessage
                            name="designerGroupA"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div style={{ width: "14%" }}>
                        <div className="">
                          <Label>B02-07</Label>
                        </div>
                        <div className="">
                          <PrimaryInput
                            type="text"
                            placeholder="0"
                            onChange={(e) => {
                              handleChange(e);
                              let del_num = values.totalDeliverableNumber || 0;
                              let totalHours =
                                +values.principalEngineer +
                                +values.seniorGroupA +
                                +values.seniorGroupB +
                                +values?.engineerGroupA +
                                +values?.engineerGroupB +
                                +values?.designerGroupA +
                                +(e.target.value || 0);
                              if (del_num && totalHours) {
                                let del_hours = +(totalHours / del_num).toFixed(
                                  2
                                );
                                console.log(del_hours);
                                setFieldValue(
                                  "totalHoursPerDeliverable",
                                  del_hours
                                );
                              }
                            }}
                            onBlur={handleBlur}
                            value={
                              (values.totalHours &&
                                values.isResPool &&
                                `${
                                  (values.designerGroupB * values.totalHours) /
                                  100
                                }(${values.designerGroupB}%)`) ||
                              values.designerGroupB
                            }
                            name="designerGroupB"
                            id="designerGroupB"
                            className=""
                            disabled={values.isResPool}
                            style={{
                              backgroundColor: values.isResPool
                                ? "#e9ecef"
                                : "inherit",
                              fontWeight: 700,
                            }}
                          />
                          <CustomErrorMessage
                            name="designerGroupB"
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <div className="d-flex gap-2 justify-content-end">
                        <Button
                          className="back-icon-btn-main"
                          onClick={onCreateProjectCTR}
                        >
                          <span className="ms-2">Back</span>
                        </Button>
                        <div className="mt-5"></div>
                        <Button className="theme-btn-main" type="submit">
                          {buttonLoading ? (
                            <ButtonLoading />
                          ) : selectedCTR ? (
                            "Update"
                          ) : (
                            "Save"
                          )}{" "}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={deleteCTRModal}
        onHide={onDeleteProjectCTR}
        className="add-department-modal-main"
        selected={selectedCTR}
        size="md"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Delete Project CTR
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <div className="mt-3">
            <div
              style={{ fontWeight: 600 }}
            >{`Are You Sure you want to delete " ${selectedCTR?.code}"`}</div>
            <div className="d-flex gap-2 justify-content-end mt-3">
              <Button
                className="back-icon-btn-main"
                onClick={onDeleteProjectCTR}
              >
                <span className="ms-2">Back</span>
              </Button>
              <div className="mt-5"></div>
              <Button className="theme-btn-main" onClick={deletingProjectCTR}>
                {buttonLoading ? <ButtonLoading /> : "Delete"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={importCtrModal}
        onHide={onImportCtr}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Import CTR
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <p className="mb-4 add-department-modal-content-text">
            Please select project first.
          </p>
          <Formik
            initialValues={{
              fileName: "",
            }}
            validationSchema={ctrImportingSchema}
            onSubmit={(values) => uploadFile(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="row align-items-center form-group mb-3">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <Label>Project CTR</Label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                    <PrimaryInput
                      type="file"
                      onChange={(e) => {
                        setFieldValue("fileName", e?.target?.files[0]);
                      }}
                      onBlur={handleBlur}
                      accept=".xlsx,.xls"
                      name=" fileName"
                      id="fileName"
                    />
                    <CustomErrorMessage
                      name="fileName"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <span
                    onClick={() => downloadFile()}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Download Sample File
                  </span>
                </div>
                <div className="mt-3">
                  <div className="row">
                    <div className="col-6">
                      <Button
                        className="back-icon-btn-main"
                        onClick={onImportCtr}
                        style={{ width: "100%" }}
                      >
                        <span>{squareBackIcon}</span>
                        <span className="ms-2">Back</span>
                      </Button>
                    </div>
                    <div className="col-6">
                      <PrimaryButton
                        className="theme-btn-main"
                        type="submit"
                        title={buttonLoading ? <ButtonLoading /> : "Submit"}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        show={onRevision}
        onHide={onRevisionModal}
        className="add-department-modal-main"
        size="xl"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Revision of {selectedCTR?.masterCtr?.code}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive bordered className="table table-bordered">
            <thead>
              <tr>
                <td>S.No.</td>
                <th>Total Hours</th>
                <th>B02-01</th>
                <th>B02-02</th>
                <th>B02-03</th>
                <th>B02-04</th>
                <th>B02-05</th>
                <th>B02-06</th>
                <th>B02-07</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {revisionCTR &&
                revisionCTR.length > 0 &&
                revisionCTR.map((each) => {
                  return (
                    <tr>
                      <td>{each?.sNo}</td>
                      <td>{each?.totalHours}</td>
                      <td>{parseFloat(each?.principalEngineer).toFixed(2)}</td>
                      <td>{parseFloat(each?.seniorGroupA).toFixed(2)}</td>
                      <td>{parseFloat(each?.seniorGroupB).toFixed(2)}</td>
                      <td>{parseFloat(each?.engineerGroupA).toFixed(2)}</td>
                      <td>{parseFloat(each?.engineerGroupB).toFixed(2)}</td>
                      <td>{parseFloat(each?.designerGroupA).toFixed(2)}</td>
                      <td>{parseFloat(each?.designerGroupB).toFixed(2)}</td>
                      <td>
                        {moment(each?.createdAt).format("DD-MM-YYYY hh:mm")}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>
  );
}
