import React, { useEffect, useRef, useState } from "react";
import "./AddDocument.scss";
import useCurrentPage from "../../../hooks/useCurrentPage";
import { Form, Formik } from "formik";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { Label, Button } from "reactstrap";
import { PrimaryInput } from "../../../assets/FormElements";
import { PrimaryButton } from "../../../assets/buttons";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import TickSquare from "../../../assets/images/icons/TickSquare.svg";
import CloseGreyIcon from "../../../assets/images/icons/close-grey-icon.svg";
import NextWhiteIcon from "../../../assets/images/icons/next-icon-white.svg";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import moment from "moment";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import useAuth from "../../../hooks/useAuth";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import PageLoader from "../../../assets/loaders/PageLoading";

export default function AddDocument() {
  const { updateCurrentPage } = useCurrentPage();
  const [addDetailsTabs, setAddDetailsTabs] = useState("document-info");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const navigate = useNavigate();
  const { deBounce } = TimingFunctions();

  const {
    addDocumentMaster,
    getProjectMasters,
    updateDocumentMaster,
    getFolderStructure,
    getDepartmentMasters,
    getDocumentCategoryMasters,
    getDoctypeMasters,
    uploadProjectDocument,
    getDocuments,
    getUserDetails,
  } = MainServices();
  const location = useLocation();
  const projectId = new URLSearchParams(location.search).get("projectId");
  const documentId = new URLSearchParams(location.search).get("documentId");
  const [hierarchyModal, setHierarchyModal] = useState(false);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [hierarchy, setHierarchy] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState();
  const uploadRef = useRef(null);
  const [versionNumber, setVersionNumber] = useState();

  const { user } = useAuth();
  const [userDepartment, setUserDepatrment] = useState();

  useEffect(() => {
    updateCurrentPage({
      title: "Document",
      subtitle: "Add Document",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDocumentById();
  }, [documentId]);

  useEffect(() => {
    calculateVersionNumber();
  }, []);

  useEffect(() => {
    getUserDetailsById();
  }, [user]);

  const getUserDetailsById = async () => {
    const details = {};
    const token = user.token;
    await axios.all([getUserDetails(details, token)]).then(function (res) {
      if (res[0]) {
        let resData = res[0];
        let message = resData.message;

        if (resData.success) {
          let department = resData?.data?.deptartment;
          department.label = department.deptName;
          department.value = department.publicId;

          setUserDepatrment(department);
        }
      }
    });
  };
  const getDocumentById = async () => {
    setPageLoading(true);
    const details = {
      filters: [
        { key: "publicId", eq: documentId },
        { key: "ddr", eq: true },
      ],
    };
    await axios
      .all([getDocuments(details)])
      .then(function (res) {
        let resData = res[0]?.data || [];
        if (resData.length) {
          setSelectedDocument(resData[0]);
        }
        setPageLoading(false);

        //
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getStructures();
    getDepartmentMastersByStatus({});
    getDocumentCategories({});
    getDocumentTypes({});
  }, []);

  const getStructures = async () => {
    await axios
      .all([getFolderStructure({})])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let documents = resData.data ? resData.data : [];

            let folders = transformArray(documents);
            setHierarchy(folders);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Documents");
      });
  };

  //functions
  const onTabChange = ({ type, setErrors, setTouched, values }) => {
    if (type === "document-info") {
      setAddDetailsTabs(type);
    }
    let validate = onStepValidation(type, setErrors, setTouched, values);
    if (validate) {
      setErrors({});
      setTouched({});
      setAddDetailsTabs(type);
    }
  };

  function transformArray(array, parent) {
    return array.map((item) => {
      const newItem = {
        ...item,
        label: item.name,
        value: item.folderPath,
      };
      if (item.children && item.children.length > 0) {
        newItem.children = transformArray(item.children, item.name);
      }
      return newItem;
    });
  }

  const onStepValidation = (type, setErrors, setTouched, values) => {
    try {
      documentInfoSchema.validateSync(values, { abortEarly: false });
      return true;
    } catch (errors) {
      console.log(errors);
      let vErrors = {};
      let vTouched = {};
      errors.inner.forEach((error) => {
        vErrors[error.path] = error.message;
        vTouched[error.path] = true;
      });
      setTouched(vTouched);
      setErrors(vErrors);
      return false;
    }
  };

  const onSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  //validation
  const documentInfoSchema = Yup.object().shape({
    documentName: Yup.string()
      .test(
        "not-only-whitespace",
        " Document Name  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Document Name is Required"),
    documentNumber: Yup.string()
      .test(
        "not-only-whitespace",
        " Document Number  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Document number is Required"),
    //  documentVersion: Yup.string().required("Document VersionCode is Required"),
    // discipline: Yup.mixed().required("discipline  is Required"),
    documentDescription: Yup.string()
      .test(
        "not-only-whitespace",
        "  Description  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Document description is Required"),
    deliveryType: Yup.string().required("delivery Type is Required"),
    category: Yup.mixed().required("Category is Required"),
    documentType: Yup.mixed().required("Document Type is Required"),
    uploadFile: Yup.mixed().required("File  is Required"),
  });

  const uploadSchema = Yup.object().shape({
    uploadFile: Yup.mixed().required("File  is Required"),
  });

  // apis
  const getProjectDetials = async () => {
    const details = {
      filters: [
        {
          key: "publicId",
          eq: projectId,
        },
      ],
    };
    await axios
      .all([getProjectMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let projectDetails = resData.data ? resData.data : [];
            projectDetails = projectDetails[0] ? projectDetails[0] : {};
            projectDetails = {
              ...projectDetails,
              documentVersionRequest:
                projectDetails?.documentVersionRequest.slice(0, 10),
              initialEngineeringSubmission:
                projectDetails?.initialEngineeringSubmission.slice(0, 10),
              approvedEngineeringSubmission:
                projectDetails?.approvedEngineeringSubmission.slice(0, 10),
              engineeringPo: projectDetails?.engineeringPo.slice(0, 10),
            };
            console.log(projectDetails);
            setSelectedProject(projectDetails);
          } else {
            if (message) notifyWarn(message);
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Project Details");
      });
  };

  const getDepartmentMastersByStatus = async ({ page, limit, filters }) => {
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
    };
    let options = await axios
      .all([getDepartmentMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let departments = resData.data ? resData.data : [];
            departments = departments.map((_, index) => {
              return {
                ..._,
                label: _.deptName,
                value: _.publicId,
              };
            });
            setDepartments(departments);
            return departments;
          } else {
            return [];
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Departments");
      });
    return options;
  };
  const getDocumentTypes = async ({ page, limit, filters }) => {
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
    };
    let options = await axios
      .all([getDoctypeMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let types = resData.data ? resData.data : [];
            types = types.map((_, index) => {
              return {
                ..._,
                label: _.docTypeName,
                value: _.publicId,
              };
            });

            setTypes(types);
            return types;
          } else {
            return [];
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Document types");
      });
    return options;
  };
  const getDocumentCategories = async ({ page, limit, filters }) => {
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
    };
    let options = await axios
      .all([getDocumentCategoryMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let departments = resData.data ? resData.data : [];
            departments = departments.map((_, index) => {
              return {
                ..._,
                label: _.name,
                value: _.publicId,
              };
            });
            setCategories(departments);
            return departments;
          } else {
            return [];
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Categories");
      });

    return options;
  };

  const uploadDocument = async (values) => {
    setButtonLoading(true);
    const details = {
      deliverableType: values.deliveryType,
      projectId,
    };
    const file = values.uploadFile;
    await axios
      .all([uploadProjectDocument(file, details, user.token)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          console.log(resData);
          if (resData.success) {
            let data = resData.data || {};
            if (data?.filePath) {
              if (documentId) {
                updateDocument(values, data.filePath);
              } else {
                addDocument(values, data.filePath);
              }
            }
          } else {
            notifyWarn(resData?.data?.error);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in uploading document");
      });
  };
  const addDocument = async (values, filePath) => {
    setButtonLoading(true);
    const details = {
      documentName: values?.documentName,
      documentNumber: values?.documentNumber,
      documentType: values?.documentType?.value,
      documentVersion: values?.documentVersion,
      category: values?.category?.value,
      discipline: userDepartment?.value,
      deliverableType: values.deliveryType,
      documentDescription: values.documentDescription,
      projectId,
      filePath,
    };

    await axios
      .all([addDocumentMaster(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          console.log(resData);
          if (resData.success) {
            let data = resData.data || {};
            notifySuccess("Document Added Successfully");
            onSuccessModal();
            setButtonLoading(false);
            navigate({
              pathname: "/documents/document-details",
              search: `?&documentId=${data.publicId}`,
            });
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in adding Document");
      });
  };

  const updateDocument = async (values, filePath) => {
    setButtonLoading(true);
    const details = {
      documentName: values?.documentName,
      documentNumber: values?.documentNumber,
      documentType: values?.documentType?.value,
      documentVersion: 0,
      category: values?.category?.value,
      deliverableType: values.deliveryType,
      discipline: userDepartment?.value,
      documentDescription: values.documentDescription,
      projectId,
      filePath,
      originalStatus: "draft",
    };
    await axios
      .all([
        updateDocumentMaster(
          details,
          selectedDocument.publicId,
          selectedDocument.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            notifySuccess("Document Updated Successfully");
            onSuccessModal();
            setButtonLoading(false);
            navigate({
              pathname: "/documents/document-details",
              search: `?&documentId=${selectedDocument.publicId}`,
            });
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in updating Document");
      });
  };

  const onHierarchyView = () => {
    setHierarchyModal(!hierarchyModal);
  };

  const loadDebounceOptions = (inputValue, callback, type) => {
    deBounce(() => loadOptions(inputValue, callback, type), 1000);
  };

  const loadOptions = async (inputValue, callback, type) => {
    if (!inputValue) {
      callback(types);
    }

    let options = [];
    if (type === "doc_type") {
      let filter = [
        {
          key: "status",
          eq: "active",
        },
        {
          key: "docTypeName",
          iLike: inputValue,
        },
      ];
      options = await getDocumentTypes({ filters: filter });
    }
    if (type === "category") {
      let filter = [
        {
          key: "status",
          eq: "active",
        },
        {
          key: "name",
          iLike: inputValue,
        },
      ];
      options = await getDocumentCategories({ filters: filter });
    }
    if (type === "discipline") {
      let filter = [
        {
          key: "status",
          eq: "active",
        },
        {
          key: "deptName",
          iLike: inputValue,
        },
      ];
      options = await getDepartmentMastersByStatus({ filters: filter });
    }
    callback(options);
  };

  const calculateVersionNumber = () => {
    setVersionNumber(0);
  };

  const fileNameChecking = (oldFilename, newFileName) => {
    const fileNameParts = newFileName.split(".");
    const fileExtension = fileNameParts.pop();
    const fileNameWithoutExtension = fileNameParts.join(".");

    // const oldFileNameParts = oldFilename.split(".");
    // const oldFileExtension = oldFileNameParts.pop();
    // const oldFileNamePartsNameWithoutExtension = oldFileNameParts.join(".");
    // console.log(oldFilename);
    // if (oldFileExtension !== fileExtension) {
    //   return "File Format changed!";
    // }
    if (!fileNameWithoutExtension.includes(oldFilename)) {
      return "File Name not matches with the Document Number, Please select other File";
    }
    return "";
  };

  return (
    <div className="add-project-main">
      {pageLoading ? (
        <PageLoader />
      ) : (
        <>
          <div>
            <Formik
              initialValues={{
                documentName: selectedDocument?.documentName || "",
                documentNumber: selectedDocument?.documentNumber || "",
                documentVersion: selectedDocument?.documentVersion || 1,
                documentDescription:
                  selectedDocument?.documentDescription || "",
                uploadFile: "",
                discipline: userDepartment || "",
                category: selectedDocument?.category || "",
                deliveryType: selectedDocument?.deliverableType,
                documentType: selectedDocument?.documentType,
                plipId: selectedDocument?.plip?.plipId,
              }}
              validationSchema={documentInfoSchema}
              onSubmit={(values) => {
                uploadDocument(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setErrors,
                setTouched,
                setFieldValue,
              }) => (
                <div>
                  <div className="add-project-card-main">
                    <div>
                      <h1 className="add-project-card-header-main mb-1">
                        {projectId ? "Update" : "Create"} Project
                      </h1>
                      <p className="m-0 add-project-card-para-main">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                    </div>

                    <div className="add-project-tab-main">
                      <div
                        className={`add-project-tabs ${
                          addDetailsTabs === "document-info" ? "active" : ""
                        }`}
                        onClick={() =>
                          onTabChange({
                            type: "document-info",
                            setErrors,
                            setTouched,
                            values,
                          })
                        }
                      >
                        <p className="m-0">Document Info</p>
                      </div>
                      {/* <div
                        className={`add-project-tabs ${
                          addDetailsTabs === "upload-preview" ? "active" : ""
                        }`}
                      >
                        <p
                          className="m-0"
                          onClick={() =>
                            onTabChange({
                              type: "upload-preview",
                              setErrors,
                              setTouched,
                              values,
                            })
                          }
                        >
                          Upload
                        </p>
                      </div> */}
                    </div>
                  </div>

                  <div className="add-project-card-main mt-3">
                    <Form className="form" onSubmit={handleSubmit}>
                      {addDetailsTabs === "document-info" && (
                        <>
                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Upload document</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <input
                                placeholder="Enter Version"
                                onChange={(e) => {
                                  let file = e.target.files[0];

                                  setFieldValue("uploadFile", file);
                                  if (
                                    !documentId ||
                                    !selectedDocument?.documentNumber
                                  ) {
                                    let fileName = file.name;
                                    let docNumber = fileName.split(".")[0];
                                    setFieldValue("documentNumber", docNumber);
                                  }
                                  let fileName = e.target.files[0].name;
                                  let oldName = values.documentNumber;
                                  if (oldName) {
                                    let fileNameError = fileNameChecking(
                                      oldName,
                                      fileName
                                    );
                                    if (fileNameError) {
                                      notifyWarn(fileNameError);
                                    }
                                  }
                                }}
                                onBlur={handleBlur}
                                type="file"
                                name="fileInput"
                                id="fileInput"
                                hidden
                                ref={uploadRef}
                              />
                              <Button
                                onClick={() => {
                                  uploadRef.current.click();
                                  console.log("here");
                                }}
                                color="primary"
                              >
                                Select File
                              </Button>
                              {values.uploadFile && (
                                <p>{values.uploadFile.name}</p>
                              )}
                              <CustomErrorMessage
                                name="uploadFile"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Document Number</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter Document Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentNumber}
                                name="documentNumber"
                                id="documentNumber"
                                disabled={
                                  documentId && selectedDocument?.documentNumber
                                }
                              />
                              <CustomErrorMessage
                                name="documentNumber"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Document Name</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter Document Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentName}
                                name="documentName"
                                id="documentName"
                              />
                              <CustomErrorMessage
                                name="documentName"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Plip Id</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter plipId "
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.plipId}
                                name="plipId"
                                id="plipId"
                              />
                              <CustomErrorMessage
                                name="plipId"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Document Type</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <AsyncSelect
                                className="sop-custom-form-select"
                                id="documentType"
                                name="documentType"
                                placeholder="Select document Type"
                                onChange={(e) => {
                                  setFieldValue("documentType", e);
                                }}
                                value={values.documentType}
                                onBlur={handleBlur}
                                loadOptions={(input, callback) =>
                                  loadDebounceOptions(
                                    input,
                                    callback,
                                    "doc_type"
                                  )
                                }
                                defaultOptions={types}
                                cacheOptions
                              />
                              <CustomErrorMessage
                                name="documentType"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          {!selectedDocument?.ddr && (
                            <div className="row align-items-center form-group mb-3">
                              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <Label>Delivery Type</Label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                <div className="d-flex gap-1">
                                  <PrimaryInput
                                    name="deliveryType"
                                    placeholder="Delivery Type"
                                    value={values.deliveryType}
                                    disabled={true}
                                    onBlur={handleBlur}
                                  />

                                  <PrimaryButton
                                    title="Select"
                                    className="w-25"
                                    onClick={onHierarchyView}
                                  />
                                </div>
                                <CustomErrorMessage
                                  name="deliveryType"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          )}
                          <Modal
                            centered
                            show={hierarchyModal}
                            onHide={onHierarchyView}
                            className="add-department-modal-main delivery-hierarchy"
                          >
                            <Modal.Header
                              closeButton
                              className="add-department-modal-header"
                            >
                              <Modal.Title className="add-department-modal-title">
                                Select Delivery Type
                              </Modal.Title>
                            </Modal.Header>
                            {hierarchyModal && (
                              <Modal.Body className="add-department-modal-body">
                                <Formik
                                  initialValues={{
                                    checked: values.deliveryType,
                                  }}
                                  onSubmit={(fvalues) => {
                                    setFieldValue(
                                      "deliveryType",
                                      fvalues.checked
                                    );
                                    onHierarchyView();
                                    setExpanded([]);
                                  }}
                                >
                                  {({
                                    values: fValues,
                                    setFieldValue: fSet,
                                    handleSubmit: fSubmit,
                                  }) => (
                                    <>
                                      <CheckboxTree
                                        nodes={hierarchy}
                                        checked={[fValues.checked]}
                                        expanded={expanded}
                                        // onCheck={(checked) => {
                                        //   console.log(checked)
                                        //   setChecked(checked);
                                        // }}
                                        onExpand={(expanded) => {
                                          setExpanded(expanded);
                                        }}
                                        onClick={(e) => {
                                          console.log(e);
                                          fSet("checked", e.value);
                                        }}
                                        noCascade
                                      />
                                      <div className="mt-3">
                                        <div className="d-flex gap-2 justify-content-end">
                                          <Button
                                            className="back-icon-btn-main"
                                            onClick={onHierarchyView}
                                          >
                                            <span className="ms-2">Cancel</span>
                                          </Button>
                                          <PrimaryButton
                                            className="theme-btn-main w-25"
                                            title={"Submit"}
                                            onClick={fSubmit}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </Formik>
                              </Modal.Body>
                            )}
                          </Modal>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Document Version</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="number"
                                placeholder="Enter Version"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={versionNumber}
                                name="documentVersion"
                                id="documentVersion"
                                disabled={!documentId}
                              />
                              <CustomErrorMessage
                                name="documentVersion"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Discipline</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <Select
                                className="sop-custom-form-select"
                                id="discipline"
                                name="discipline"
                                placeholder="Select Discipline"
                                // onChange={(e) => {
                                //   setFieldValue("discipline", e);
                                // }}
                                value={userDepartment}
                                //   onBlur={handleBlur}
                                //  loadOptions={(input,callback)=>loadDebounceOptions(input,callback,"discipline")}
                                //   defaultOptions={departments}
                                //  cacheOptions
                                isDisabled
                              />
                              <CustomErrorMessage
                                name="discipline"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Category</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <AsyncSelect
                                className="sop-custom-form-select"
                                id="category"
                                name="category"
                                placeholder="Select Category"
                                onChange={(e) => {
                                  setFieldValue("category", e);
                                }}
                                value={values.category}
                                onBlur={handleBlur}
                                loadOptions={(input, callback) =>
                                  loadDebounceOptions(
                                    input,
                                    callback,
                                    "category"
                                  )
                                }
                                defaultOptions={categories}
                                cacheOptions
                              />
                              <CustomErrorMessage
                                name="category"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Description</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <textarea
                                type="text"
                                placeholder="Document Description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.documentDescription}
                                name="documentDescription"
                                id="documentDescription"
                                className="w-100 h-25 b-2"
                              />
                              <CustomErrorMessage
                                name="documentDescription"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="row">
                        <div className="col-4"></div>
                        <div className="mt-5 d-flex gap-2 align-items-center justify-content-end col-7">
                          {addDetailsTabs === "document-info" && (
                            <>
                              <div>
                                <Button onClick={() => navigate(-1)}>
                                  Cancel
                                </Button>
                              </div>
                              <div>
                                <PrimaryButton
                                  onClick={handleSubmit}
                                  title={
                                    buttonLoading ? <ButtonLoading /> : "Submit"
                                  }
                                />
                              </div>
                            </>
                          )}
                          {console.log(touched)}
                          {/* {addDetailsTabs === "upload-preview" && (
                            <>
                              <div>
                                <Button
                                  onClick={() =>
                                    setAddDetailsTabs("document-info")
                                  }
                                >
                                  Back
                                </Button>
                              </div>
                              <div>
                                <PrimaryButton
                                  type="submit"
                                  title={
                                    buttonLoading ? <ButtonLoading /> : "Submit"
                                  }
                                />
                              </div>
                            </>
                          )} */}
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </>
      )}

      <Modal
        centered
        show={successModal}
        onHide={onSuccessModal}
        className="add-department-modal-main"
        // selected={selectedProject}
      >
        <Modal.Body className="success-modal-content-main">
          <div className="text-center">
            <div>
              <img src={TickSquare} alt="check icon" />
            </div>

            <div className="mt-2">
              <p className="success-modal-content-para-main">
                New Project Added
              </p>
              <div className="d-flex gap-3 align-items-center justify-content-center">
                <Button
                  onClick={onSuccessModal}
                  className="primary-bordered-icon-btn"
                >
                  <span className="me-2">
                    <img src={CloseGreyIcon} alt="back icon" />
                  </span>
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={onSuccessModal}
                  className="primary-theme-icon-btn"
                >
                  <span>Continue</span>
                  <span className="ms-2">
                    <img src={NextWhiteIcon} alt="next icon" />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
