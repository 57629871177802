import { useState } from "react";
import nexticon from "../images/icons/next-arrow.svg";
import "./CustomPagination.scss";
import backIcon from "../images/icons/previous.svg";
import nextIcon from "../images/icons/arrow-right-grey.svg";
import back from "../images/icons/back.png";
import next from "../images/icons/right-arrow.png";

const Pagination = ({
  currentPage,
  rowsPerPage,
  totalCount,
  onPageClick,
  onPrev,
  onNext,
}) => {
  const [pageList, setPageList] = useState(1);


  let page = +currentPage;
  let limit = Math.ceil(totalCount / rowsPerPage);

  const onNextPage = () => {
    if (page < limit ) {
      onNext && onNext(page + 1);
      if (pageList+5 <= limit) {
        setPageList(pageList + 1);
      }
    }
  };
  const onPrevious = () => {
    if (page > 1) {
        onPrev && onPrev(page - 1);
      if ( pageList > 1 ) {
        setPageList(pageList - 1);
      }
    }
  };

  const onPage = (number) => {
    onPageClick && onPageClick(number);
  };

  const onLastPage = ()=>{
    onNext && onNext(limit);
    onPageClick && onPageClick(limit);
    setPageList(limit-4);
  }
  const onFirstPage = ()=>{
    onNext && onNext(1);
    onPageClick && onPageClick(1);
    setPageList(1);
  }

  return (
    <div className="nk-block-between-md g-3">
      <div className="g">
        <ul className="pagination justify-content-center justify-content-md-start">
          <li
            className={`page-item ${
              page === 1 || pageList === 1 ? "disabled" : ""
            }`}
            onClick={() => onFirstPage()}
          >
            <div className="page-link">
              <img style={{ width: "100%" }} src={back} alt="back icon" />
            </div>
          </li>
          <li
            className={`page-item ${
              page === 1 || pageList === 1 ? "disabled" : ""
            }`}
            onClick={() => onPrevious()}
          >
            <span className="page-link">
              <img style={{ width: "100%" }} src={backIcon} alt="back icon" />
            </span>
          </li>
          {limit <= 5
            ? [...Array(limit)].map((_, index) => {
                return (
                  <li
                    className={`page-item ${
                      page === index + 1 ? "active" : ""
                    }`}
                    onClick={() => onPage(index + 1)}
                  >
                    <span className="page-link">{index + 1}</span>
                  </li>
                );
              })
            : [...Array(5)].map((_, index) => {
                return (
                  <li
                    className={`page-item ${
                      page === pageList + index ? "active" : ""
                    }`}
                    onClick={() => onPage(pageList + index)}
                  >
                    <span className="page-link">{pageList + index}</span>
                  </li>
                );
              })}
         
          <li
            className={`page-item ${
              page === limit || pageList + 5 > limit ? "disabled" : ""
            }`}
            onClick={() => onNextPage()}
          >
            <span className="page-link">
              <img style={{ width: "100%" }} src={nextIcon} alt="next icon" />
            </span>
          </li>
          <li
            className={`page-item ${
              page === limit || pageList + 5 > limit ? "disabled" : ""
            }`}
            onClick={() => onLastPage()}
          >
            <span className="page-link">
              <img style={{ width: "100%" }} src={next} alt="next icon" />
            </span>
          </li>
        </ul>
      </div>
      {page === limit ||
        (pageList + 5 > limit && (
          <div className="g">
            <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
              <div
                className="d-flex gap-3 px-2 py-3 justify-content-between align-items-center border rounded right-next"
                onClick={() => onNextPage()}
              >
                <p className="m-0" style={{ color: "#fff" }}>
                  Next
                </p>
                <img src={nexticon} alt="next" />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Pagination;
