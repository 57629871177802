import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useCurrentPage from "../../../../hooks/useCurrentPage";
import axios from "axios";
import MainServices from "../../../../services/MainServices";
import moment from "moment";
import PageLoader from "../../../../assets/loaders/PageLoading";

import { notifySuccess, notifyWarn } from "../../../../assets/elements/Toast";
import { Button, Modal } from "react-bootstrap";
import "./IdcDetails.scss";
import Back_Arrow from "../../../../assets/images/icons/arrow-left.svg";
import editicon from "../../../../assets/images/icons/edit.svg";
import PermissionFunctions from "../../../../assets/functions/PermissionFunctions";
import { Formik } from "formik";
import useFeatureSetting from "../../../../hooks/useFeatureSetting";
import { Form } from "react-bootstrap";

import CustomErrorMessage from "../../../../assets/elements/ErrorMessage";

import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { Label } from "reactstrap";
import TimingFunctions from "../../../../assets/functions/TimingFunctions";
import { PrimaryButton } from "../../../../assets/buttons";

const IdcDetails = () => {
  const { grantPermission } = PermissionFunctions();
  const { updateCurrentPage } = useCurrentPage();
  const {
    getIdcMatrixById,
    getDepartmentMasters,
    getDesignationMasters,
    updateEventWorkflowReview,
    getEventWorkflowById,
    updateIdcMatrix,
    getWorkflowsBySlug,
  } = MainServices();
  const [pageLoading, setPageLoading] = useState(0);
  const [idcMatrix, setIdcMatrix] = useState([]);
  const [workflowMasters, setWorkflowMasters] = useState({});
  const location = useLocation();
  const [approvedLoading, setApprovedLoading] = useState(0);
  const [approvedModal, setApprovedModal] = useState(false);
  const [digitalSignatureModal, setDigitalSignatureModal] = useState(false);
  const [selectedIdc, setselectedIdc] = useState({});
  const [editIdcModal, seteditIdcModal] = useState(false);
  //url Params
  const publicId = new URLSearchParams(location.search).get("idcId");
  const refId = new URLSearchParams(location.search).get("idc");
  const workflowConcurrencystamp = new URLSearchParams(location.search).get(
    "workflowConcurrencystamp"
  );
  const { updateSettingContext, setting } = useFeatureSetting();
  const [buttonLoading, setButtonLoading] = useState(0);
  const [intiLoading, setIntiLoading] = useState(0);
  const [workflowAssign, setWorkflowAssign] = useState({});
  const edit = new URLSearchParams(location.search).get("edit");
  const [editInitiated, setEditInitiated] = useState(false);
  const [isWorkflowFlag, setIsWorkflowFlag] = useState({});
  const [workflowDataSet, setWorkflowDataSet] = useState("");
  const [workflowModal, setWorkflowModal] = useState(false);
  const [isStatusUpdate, setIsStatusUpdate] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const { deBounce } = TimingFunctions();

  useEffect(() => {
    updateCurrentPage({
      title: "Branch",
      subtitle: "Details",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    updateSettingContext("idcMatrix");
  }, []);

  useEffect(() => {
    if (publicId) {
      getIdcMatrix();
    }

    // eslint-disable-next-line
  }, [publicId]);

  useEffect(() => {
    getDepartmentMastersByStatus();
    getDesignationMastersByStatus();
  }, []);

  const getIdcMatrix = async () => {
    setPageLoading(true);
    const details = {};

    await axios
      .all([getIdcMatrixById(details, publicId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let idcMatrix = resData.data ? resData.data : {};
            let reviewers = idcMatrix.reviewer || [];
            reviewers = reviewers.map((eachR) => {
              let desgs = eachR.designations || [];
              desgs = desgs.map((eachD) => {
                return {
                  ...eachD,
                  label: eachD.desgName,
                  value: eachD.publicId,
                };
              });
              let department = {
                ...eachR.department,
                label: eachR?.department?.deptName,
                value: eachR?.department?.publicId,
              };
              return {
                ...eachR,
                designations: desgs,
                department,
              };
            });
            idcMatrix.reviewer = reviewers;
            setIdcMatrix(idcMatrix);
            if (edit === "true" && !editInitiated) {
              onEditIdc(idcMatrix);
              setEditInitiated(true);
            }
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting Departments");
      });
  };

  const getDepartmentMastersByStatus = async (filters) => {
    const details = {
      pageSize: 30,
      pageNumber: 1,
      filters,
    };
    let options = await axios
      .all([getDepartmentMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let departments = resData.data ? resData.data : [];
            departments = departments.map((_, index) => {
              return {
                ..._,
                label: _.deptName,
                value: _.publicId,
              };
            });
            !filters && setDepartments(departments);
            return departments;
          } else {
            return [];
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Departments");
      });
    return options;
  };

  const getDesignationMastersByStatus = async (filters) => {
    const details = {
      pageSize: 30,
      pageNumber: 1,
      filters,
    };
    let options = await axios
      .all([getDesignationMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let designations = resData.data ? resData.data : [];

            designations = designations.map((_, index) => {
              return {
                ..._,
                label: _.desgName,
                value: _.publicId,
              };
            });
            !filters && setDesignations(designations);
            return designations;
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Designations");
      });
    return options;
  };

  const getWorkflowById = async () => {
    setPageLoading(true);
    const details = {
      filters: [{ key: "publicId", eq: refId }],
      sorting: [{ key: "id", direction: "asc" }],
    };

    await axios
      .all([getEventWorkflowById(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let workflows = resData.data ? resData.data : [];
            setWorkflowMasters(workflows[0]);
            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting Event Workflow");
      });
  };

  const getIsWorkflowSlug = async () => {
    const details = {};
    await axios
      .all([getWorkflowsBySlug(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let isWorkflow = resData?.data ? resData?.data : {};
            isWorkflow = isWorkflow?.content
              ? JSON.parse(isWorkflow?.content)
              : {};
            setIsWorkflowFlag(isWorkflow);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in getting measurement types");
      });
  };

  const updateWorkflowStatus = async (values) => {
    setApprovedLoading(true);
    const details = {
      workflowStatus: values.status?.value,
      //   email: values.user_id,
      ...(values?.password && { password: values.password }),
      comments: values.comment,
    };
    await axios
      .all([
        updateEventWorkflowReview(details, refId, workflowConcurrencystamp),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            notifySuccess("Status changed successfully");
            setIsStatusUpdate(Math.random());
            setApprovedLoading(false);
            onApprovedStatusModal();
            getIdcMatrix();
            getWorkflowById();
            // getSopWorkflows();
          } else {
            message && notifyWarn(message);
            setApprovedLoading(false);
          }
        } else {
          setApprovedLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in status update");
      });
  };

  const updateIdc = async (values) => {
    setButtonLoading(true);
    let reviewers = values.reviewers || [];
    reviewers = reviewers.map((each) => {
      let desgs = each.designations || [];
      desgs = desgs.map((_) => _.publicId);
      return {
        department_id: each?.department?.publicId,
        designations: desgs,
      };
    });
    const details = {
      reviewer: reviewers,
    };

    await axios
      .all([
        updateIdcMatrix(
          details,
          selectedIdc.publicId,
          selectedIdc.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            getIdcMatrix();
            seteditIdcModal(false);

            setselectedIdc("");
            notifySuccess("Idc Matrix Updated Successfully");
            setButtonLoading(false);
          } else {
            message && notifyWarn(message);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in updating Branch Master");
      });
  };

  const updateIdcIntiated = async (values) => {
    setIntiLoading(true);
    const details = {
      branchName: values.branchName,
      branchDescription: values.branchDescription,
      branchCode: values.branchCode,
      ...(values?.password && { password: values?.password }),
      reinitiateWorkflowAssignId: workflowAssign.publicId,
      status: "reinitiated",
    };
    await axios
      .all([
        updateIdcMatrix(
          details,
          selectedIdc.publicId,
          selectedIdc.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData?.message;
          if (resData.success) {
            // getSopWorkflows();
            onEditIdc();
            getIdcMatrix();
            notifySuccess("Branch Master Updated Successfully");
            setIntiLoading(false);
          } else {
            message && notifyWarn(message);
            setIntiLoading(false);
          }
        } else {
          setIntiLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in updating Branch Master");
      });
  };
  //functions
  const onDigitalSignatureModal = () => {
    setDigitalSignatureModal(!digitalSignatureModal);
  };

  const handleWorkflowAssignUpdate = (updatedWorkflowAssign) => {
    setWorkflowAssign(updatedWorkflowAssign);
  };

  const onManageWorkflow = ({ values, setFieldValue }) => {
    setWorkflowDataSet({
      workflows: values?.workflows,
      setFieldValue,
    });
    setWorkflowModal((prev) => !prev);
  };

  const onApprovedStatusModal = () => {
    setApprovedModal(!approvedModal);
  };

  const onEditIdc = (selected) => {
    if (selected) {
      setselectedIdc(selected);
    } else {
      setselectedIdc("");
    }
    seteditIdcModal(!editIdcModal);
  };

  const navigate = useNavigate();

  const loadDebounceOptions = (input, callback, type) => {
    deBounce(() => {
      getOptions(input, callback, type);
    }, 500);
  };

  const getOptions = async (input, callback, type) => {
    if (type === "department") {
      if (!input) callback(departments);
      let filters = [{ key: "deptName", iLike: input }];
      let options = await getDepartmentMastersByStatus(filters);
      callback(options);
    } else {
      if (!input) callback(departments);
      let filters = [{ key: "desgName", iLike: input }];
      let options = await getDesignationMastersByStatus(filters);
      callback(options);
    }
  };
  return (
    <div className="idc-details-main">
      <div className="admin-popular-main">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="header">
            <p className="content-header-main mb-0">Idc Matrix Details</p>
          </div>
        </div>
        <div className="mb-3">
          <img
            src={Back_Arrow}
            alt="backArrow"
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />
        </div>
        {pageLoading ? (
          <PageLoader />
        ) : idcMatrix ? (
          <div className="content">
            <div className="table-main">
              {grantPermission("MasterCtrActivity_update") &&
                idcMatrix?.status !== "inactive" && (
                  <div
                    style={{ cursor: "pointer" }}
                    className="d-flex justify-content-end mb-3"
                  >
                    <img
                      src={editicon}
                      alt="Edit icon"
                      onClick={() => onEditIdc(idcMatrix)}
                    />
                  </div>
                )}

              <div className="row details-row">
                <div className="col-md-6 col-head fw-bold">Name</div>
                <div className="col-md-6 ">{idcMatrix?.deliverableTitle}</div>

                <div className="col-md-6 col-head fw-bold">Description</div>
                <div className="col-md-6 ">
                  {idcMatrix?.deliverableDefinition}
                </div>

                <div className="col-md-6 col-head fw-bold">Created At</div>
                <div className="col-md-6 ">
                  {moment(idcMatrix?.createdAt).format("DD-MM-YYYY")}
                </div>

                <div className="col-md-6 col-head fw-bold">Status</div>
                <div className="col-md-6 ">{idcMatrix?.status}</div>
              </div>

              {idcMatrix.reviewer && idcMatrix.reviewer.length > 0 && (
                <div className="reviewers">
                  <h5>Reviewers</h5>
                  {idcMatrix.reviewer.map((eachR) => (
                    <div className="each-reviewer mt-2">
                      <div className="department mb-2">
                        <b>{eachR?.department?.deptName}</b>
                      </div>
                      <div className="designations d-flex flex-wrap gap-2">
                        {eachR.designations &&
                          eachR.designations.length > 0 &&
                          eachR.designations.map((eachD) => (
                            <div className="each-designation bg-secondary rounded color-white p-1 text-light">
                              {eachD.desgName}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="no-data-found-main">No Idc Found</div>
        )}
      </div>
      <Modal
        centered
        show={editIdcModal}
        onHide={onEditIdc}
        className="add-department-modal-main"
        size="lg"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Update Idc Matrix
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              reviewers: selectedIdc?.reviewer || [],
            }}
            //validationSchema={branchSchema}
            onSubmit={(values) => {
              updateIdc(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between">
                  <p className="mb-4 add-department-modal-content-text">
                    Add Department
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      let reviewers = values.reviewers || [];
                      reviewers.push({
                        department: "",
                        designations: [],
                      });
                      setFieldValue(`reviewers`, reviewers);
                    }}
                    type="button"
                  >
                    Add
                  </button>
                </div>

                {values.reviewers &&
                  values.reviewers.length > 0 &&
                  values.reviewers.map((each, index) => (
                    <div>
                      <div className="mt-2 border p-1 rounded">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <b className="font-weight-bold text-black">
                            Department - {index + 1}
                          </b>
                          <AsyncSelect
                            className="custom-async-select mt-1"
                            id="department"
                            name="department"
                            placeholder="Select department "
                            onChange={(e) => {
                              let departments = values.reviewers || [];
                              departments = departments.map(
                                (each) => each.department.publicId
                              );
                              if (departments.includes(e.publicId)) {
                                notifyWarn(
                                  "Already this department is present"
                                );
                                return;
                              }
                              setFieldValue(`reviewers.${index}.department`, e);
                            }}
                            value={each.department}
                            onBlur={handleBlur}
                            loadOptions={(input, callback) =>
                              loadDebounceOptions(input, callback, "department")
                            }
                            defaultOptions={departments}
                            cacheOptions
                          />
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                          <b className="font-weight-bold text-black">
                            Designations
                          </b>
                          <AsyncSelect
                            className="custom-async-select mt-1"
                            id="designations"
                            name="designations"
                            placeholder="Select Designation "
                            onChange={(e) => {
                              setFieldValue(
                                `reviewers.${index}.designations`,
                                e
                              );
                            }}
                            value={each.designations}
                            onBlur={handleBlur}
                            loadOptions={(input, callback) =>
                              loadDebounceOptions(
                                input,
                                callback,
                                "designation"
                              )
                            }
                            defaultOptions={designations}
                            cacheOptions
                            isMulti
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="d-flex justify-content-end gap-2 mt-2">
                  <PrimaryButton
                    title="Cancel"
                    className="w-25"
                    onClick={onEditIdc}
                  />
                  <button
                    type="button"
                    title="Submit"
                    class="w-25 btn btn-success"
                    onClick={handleSubmit}
                    disabled={buttonLoading}
                  >
                    {buttonLoading ? "....Loading" : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default IdcDetails;
