export const downloadFileWithFileName = (filePath, fileName) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", fileName); // Specify the file name
    link.setAttribute("target", "_blank"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); 
  };


