import React, { useState, useEffect, useRef } from "react";
import "./ctrlist.scss";
import { InputGroup, Form, Modal, Dropdown } from "react-bootstrap";
import { Label, Button } from "reactstrap";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import IconButton from "../../../assets/elements/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../../../assets/loaders/PageLoading";
import { Table } from "reactstrap";
import moment from "moment";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/remove.svg";
import Pagination from "../../../assets/elements/CustomPagination";
import useCurrentPage from "../../../hooks/useCurrentPage";
import axios from "axios";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import { Formik } from "formik";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { PrimaryInput } from "../../../assets/FormElements";
import Select from "react-select";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import * as Yup from "yup";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import plusIcon from "../../../assets/images/icons/add.svg";
import { toast } from "react-toastify";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import MainServices from "../../../services/MainServices";
import useAuth from "../../../hooks/useAuth";
import { ButtonLoader, LazyLoader } from "../../../assets/loaders";
import viewicon from "../../../assets/images/icons/viewicon.svg";

export default function CTRList() {
  const { grantPermission } = PermissionFunctions();
  const [pageLoading, setPageLoading] = useState(false);
  const [CTRMaster, setCTRMaster] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { updateCurrentPage } = useCurrentPage();
  const [filters, setFilters] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [searchString, setSearchString] = useState("");
  const [totalCTRMaster, setTotalCTRMaster] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [createCTRModal, setCreateCTRModal] = useState(false);
  const [selectedCTR, setSelectedCTR] = useState();
  const [onRevision, setOnRevision] = useState(false);
  const [masterCTRRevisions, setMasterCTRRevisions] = useState();
  const {
    getCTRMasters,
    getDepartmentsMaster,
    addCTRMasters,
    deleteCTRMaster,
    updateCTRMaster,
    importMasterCTR,
    getQueueStatus,
    getCTRMastersRevisions,
  } = MainServices();
  const [documentDepartments, setDocumentDepartments] = useState([]);
  const [selectedDeptId, setSelectedDeptId] = useState("");
  const { deBounce } = TimingFunctions();
  const [deleteCTRModal, setDeleteCTRModal] = useState(false);
  const [isDisciplineIdChanging, setDisciplineIdChanging] = useState(false);
  const [importMasterCTRModal, setImportMasterCTRModal] = useState(false);
  const { user } = useAuth();
  const [importLoading, setImportLoading] = useState(false);
  let pCTRDTimeOutRef = useRef(null);

  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const addCTRSchema = Yup.object().shape({
    code: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Code is Required"),
    title: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Title is Required"),
    description: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Description is Required"),
    deptartment: Yup.mixed().required("Discipline Id is Required"),
  });
  const updateCTRSchema = Yup.object().shape({
    code: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Code is Required"),
    title: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Title is Required"),
    description: Yup.string()
      .test(
        "not-only-whitespace",
        " Field cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Description is Required"),
  });
  const MasterCTRFileSchema = Yup.object().shape({
    fileName: Yup.mixed().required("FileName is Required"),
  });

  useEffect(() => {
    getDepartmentsMasters();
  }, []);
  const getDepartmentsMasters = async () => {
    setPageLoading(true);
    const details = {
      pageSize: 100,
      pageNumber: 1,
    };

    await axios
      .all([getDepartmentsMaster(details)])
      .then(function (res) {
        let resData = res[0].data ? res[0].data : [];
        resData = resData.map((_) => {
          return {
            ..._,
            value: _.publicId,
            label: _.deptName,
          };
        });

        setDocumentDepartments(resData);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const allStatus = [
    { label: "All", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  useEffect(() => {
    updateCurrentPage({
      title: "Master",
      subtitle: "CTR",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      if (pCTRDTimeOutRef.current) {
        clearTimeout(pCTRDTimeOutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setSelectedStatus(filtersObj?.status?.status || "");
    setFilters(filtersObj);
    setSearchString(changedParams?.searchString);
    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 50,
    });

    getCTRMastersByStatus({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 50,
      filters,
      searchString: changedParams?.searchString || "",
    });
    // eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.filters,
    changedParams.searchString,
  ]);

  const getCTRMastersByStatus = async ({
    page,
    limit,
    filters,
    searchString,
  }) => {
    if (filters && Array.isArray(filters)) {
      filters = [...filters
    //    , { key: "status", eq: "active" }
      ];
    } else {
      filters = [{ key: "status", eq: "active" }];
    }
    setPageLoading(true);
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
      searchString,
      sorting: [{ key: "created_at", direction: "asc" }],
    };
    await axios
      .all([getCTRMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let CTRMAsters = resData.data ? resData.data : [];
            let total = resData?.totalCount || 0;
            setTotalCTRMaster(total);
            CTRMAsters = CTRMAsters.map((_, index) => {
              return {
                ..._,
                sNo: limit * (page - 1) + index + 1,
                deptartment: {
                  ..._.deptartment,
                  label: _?.deptartment?.deptName,
                  value: _?.deptartment?.publicId,
                },
              };
            });
            setCTRMaster(CTRMAsters);
            setPageLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Projects");
      });
  };

  const onCreateCTR = (selected) => {
    if (selected) {
      setSelectedCTR(selected);
    } else {
      setSelectedCTR("");
    }
    setCreateCTRModal(!createCTRModal);
  };

  const onDeleteCTR = (selected) => {
    if (selected) {
      setSelectedCTR(selected);
    } else {
      setSelectedCTR("");
    }
    setDeleteCTRModal(!deleteCTRModal);
  };

  const addMasterCTR = async (values) => {
    setButtonLoading(true);
    const details = {
      code: values?.code,
      title: values?.title,
      description: values?.description,
      deptId: values?.deptartment?.publicId,
    };
    await axios
      .all([addCTRMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];

          if (resData.success) {
            notifySuccess("CTR Master Added Successfully");
            getCTRMastersByStatus({
              page: 1,
              limit: 50,
              filters,
              searchString,
            });
            onCreateCTR();
            setButtonLoading(false);
          } else if (resData.details && resData.details.length > 0) {
            notifyWarn(resData.details[0].message);
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in adding CTR Master");
      });
  };

  const updateMasterCTR = async (values) => {
    setButtonLoading(true);
    const details = {
      code: values?.code,
      title: values?.title,
      description: values?.description,
      ...(isDisciplineIdChanging && { deptId: values?.deptartment?.publicId }),
    };

    await axios
      .all([
        updateCTRMaster(
          details,
          selectedCTR.publicId,
          selectedCTR.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getCTRMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
              searchString,
            });
            onCreateCTR();
            notifySuccess("CTR  Master Updated Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in deleting CTR Master");
      });
  };

  const deleteMasterCTR = async () => {
    setButtonLoading(true);
    const status=selectedCTR?.status==='active'?'inactive':'active'
    const details = {
      status,
    };

    await axios
      .all([
        deleteCTRMaster(
          details,
          selectedCTR.publicId,
          selectedCTR.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getCTRMastersByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
              searchString,
            });
            onDeleteCTR();
            notifySuccess("CTR  Master Deleted Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message)
          console.log("An error occurred in deleting CTR Master");
      });
  };

  const searchMasterCTR = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      //   code: { code: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: 50,
      filters: mfilters,
      searchString: e.target.value,
    });
    navigate({
      search,
    });
  };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      searchString,
    });
    navigate({
      search,
    });
  };

  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      searchString,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
      searchString,
    });
    navigate({ search });
  };
  const onImportMasterCTR = () => {
    setImportMasterCTRModal(!importMasterCTRModal);
  };

  const getUploadStatus = async (jobId) => {
    const details = {};
    await axios
      .all([getQueueStatus(details, jobId)])
      .then((res) => {
        if (res[0]) {
          let status = res[0]?.data?.import_status;

          console.log("status", status);

          if (status === "completed") {
            toast.success("Master CTR imported successfully");
            clearTimeout(pCTRDTimeOutRef.current);
            getCTRMastersByStatus({ page: 1, limit: 50, filters });
            setImportLoading(false);
          } else {
            pCTRDTimeOutRef.current = setTimeout(() => {
              getCTRMastersByStatus({ page: 1, limit: 50, filters });
              getUploadStatus(jobId);
            }, 3000);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const upload = async (values) => {
    setButtonLoading(true);
    const details = {};
    await axios
      .all([importMasterCTR(values.fileName, details, user.token)])
      .then((res) => {
        if (res[0]) {
          setButtonLoading(false);
          onImportMasterCTR();
          console.log("import master ctr status", res);

          let jobId = res[0]?.data?.data?.jobId;
          if (jobId) {
            console.log("jobId", jobId);
            setImportLoading(true);
            pCTRDTimeOutRef.current = setTimeout(() => {
              getUploadStatus(jobId);
            }, 1000);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const downloadFile = (filePath) => {
    const link = document.createElement("a");
    link.href =
      "https://files.appsarizona.com/galfar-apis/server/exports/demo/Master-CTR.xlsx";
    link.setAttribute("download", "sample_Master_CTR_file.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  const onRevisionModal = (selected) => {
    if (selected) {
      setSelectedCTR(selected);
      getCTRMastersRevisionsById(selected);
    } else {
      setSelectedCTR("");
    }

    setOnRevision(!onRevision);
  };

  const getCTRMastersRevisionsById = async (selected) => {
    let details = {};

    await axios
      .all([getCTRMastersRevisions(details, selected?.publicId)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0].data ? res[0].data : [];
          resData.unshift(selected);
          resData = resData.map((each, index) => {
            return {
              ...each,
              sNo: index + 1,
            };
          });
          setMasterCTRRevisions(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="ctr-list-main">
      <div className="ctr-list-header-section">
        <div className="ctr-list-heading">
          CTR List {`(${totalCTRMaster || 0})`}
        </div>
        <div className="d-flex gap-3">
          {importLoading && <ButtonLoader color="black" />}
          <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchMasterCTR(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>

          <div className="product-list-header-flex" style={{ width: "8rem" }}>
            <select
              class="form-control form-select custom-status-select"
              id="fv-topics"
              name="status"
              data-placeholder="Select a option"
              required
              onChange={onStatusChange}
              value={selectedStatus}
            >
              <option label="Select Status" value=""></option>
              {allStatus.map((each) => (
                <option value={each.value} key={each.value}>
                  {each.label}
                </option>
              ))}
            </select>
          </div>
          {grantPermission("MasterCTR_write") && (
            <Dropdown className="trainer-type-dropdown-main">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="trainer-type-dropdown"
              >
                <span className="me-2">
                  <img src={plusIcon} alt="plus icon" />
                </span>
                <span>Add Master CTR</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => onCreateCTR()}>
                  Add Master CTR
                </Dropdown.Item>
                <Dropdown.Item onClick={onImportMasterCTR}>
                  Import Master CTR
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : CTRMaster && CTRMaster.length > 0 ? (
        <div className="content mt-3">
          <div className="table-main">
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr>
                  <th>S.No.</th>
                  <th style={{ width: "12%" }}>Discipline</th>
                  <th>Discipline Code</th>
                  <th style={{ width: "8%" }}>Code</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Revisions</th>

                  {(grantPermission("MasterCTR_delete") ||
                    grantPermission("MasterCTR_update")) && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {CTRMaster.map((each) => {
                  return (
                    <tr>
                      <td>{each?.sNo}</td>
                      <td>{each?.deptartment?.deptName}</td>
                      <td>{each?.deptartment?.deptCode}</td>
                      <td>{each?.code}</td>
                      <td>{each?.title}</td>
                      <td>{each?.description}</td>
                      <td>
                        {each?.isRevision ? (
                          <img
                            src={viewicon}
                            onClick={() => onRevisionModal(each)}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          "-"
                        )}
                      </td>

                      {(grantPermission("MasterCTR_delete") ||
                        grantPermission("MasterCTR_update")) && (
                        <td className="options">
                          <div className="d-flex gap-2">
                            {grantPermission("MasterCTR_update") &&
                              each?.status === "active" && (
                                <div
                                  onClick={() => onCreateCTR(each)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={editicon} alt="Edit icon" />
                                </div>
                              )}
                            {grantPermission("MasterCTR_delete") && (
                              <div
                                onClick={() => onDeleteCTR(each)}
                                style={{ cursor: "pointer" }}
                              >
                                <img src={removeicon} alt="Remove icon" />
                              </div>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="no-data-found-main">No CTR Found</div>
      )}
      {CTRMaster && CTRMaster.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalCTRMaster}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
              onRowsPerPage={onRowsPerPage}
            />
          </div>
        </div>
      )}
      <Modal
        centered
        show={createCTRModal}
        onHide={onCreateCTR}
        className="add-department-modal-main"
        selected={selectedCTR}
        size="lg"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Create A New CTR
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              code: selectedCTR?.code || "",
              title: selectedCTR?.title || "",
              description: selectedCTR?.description || "",
              deptartment: selectedCTR?.deptartment || "",
            }}
            validationSchema={selectedCTR ? updateCTRSchema : addCTRSchema}
            onSubmit={(values) => {
              selectedCTR ? updateMasterCTR(values) : addMasterCTR(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setErrors,
              setTouched,
              setFieldValue,
            }) => (
              <div>
                <div className="add-project-card-main mt-3">
                  <Form className="form" onSubmit={handleSubmit}>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Code</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          type="text"
                          placeholder="Enter Code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code}
                          name="code"
                          id="code"
                        />
                        <CustomErrorMessage
                          name="code"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Title</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          type="text"
                          placeholder="Enter Title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          name="title"
                          id="title"
                        />
                        <CustomErrorMessage
                          name="title"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Description</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          type="text"
                          placeholder="Enter Description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          name="description"
                          id="description"
                        />
                        <CustomErrorMessage
                          name="description"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>

                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>Discipline</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <Select
                          className="sop-custom-form-select"
                          id="deptartment"
                          name="deptartment"
                          placeholder="Select Discipline"
                          onChange={(e) => {
                            setFieldValue("deptartment", e);
                            setDisciplineIdChanging(true);
                          }}
                          value={values.deptartment}
                          onBlur={handleBlur}
                          options={documentDepartments}
                        />
                        <CustomErrorMessage
                          name="deptartment"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className="d-flex gap-2 justify-content-end">
                        <Button
                          className="back-icon-btn-main"
                          onClick={onCreateCTR}
                        >
                          <span className="ms-2">Back</span>
                        </Button>
                        <div className="mt-5"></div>
                        <Button className="theme-btn-main" type="submit">
                          {buttonLoading ? (
                            <ButtonLoading />
                          ) : selectedCTR ? (
                            "Update"
                          ) : (
                            "Save"
                          )}{" "}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={deleteCTRModal}
        onHide={onDeleteCTR}
        className="add-department-modal-main"
        selected={selectedCTR}
        size="md"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
          {selectedCTR?.status==='active'?'Inactivate':'Activate'}  CTR
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <div className="mt-3">
            <div
              style={{ fontWeight: 600 }}
            >{`Are You Sure you want to make this  " ${selectedCTR?.code}" ${selectedCTR?.status==='active'?'inactive':'active'}`} </div>
            <div className="d-flex gap-2 justify-content-end mt-3">
              <Button className="back-icon-btn-main" onClick={onDeleteCTR}>
                <span className="ms-2">Back</span>
              </Button>
              <div className="mt-5"></div>
              <Button className="theme-btn-main" onClick={deleteMasterCTR}>
              {buttonLoading ? <ButtonLoading /> : `${selectedCTR?.status==='active'?'Inactivate':'Activate'}`}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={importMasterCTRModal}
        onHide={onImportMasterCTR}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            {`Import  Master CTR`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <p>Select Master CTR File</p>
          <Formik
            initialValues={{
              fileName: "",
            }}
            validationSchema={MasterCTRFileSchema}
            onSubmit={(values) => {
              upload(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group col-8 mt-3">
                    <PrimaryInput
                      type="file"
                      onChange={(e) =>
                        setFieldValue("fileName", e.target.files[0])
                      }
                      onBlur={handleBlur}
                      value={values.file}
                      name="fileName"
                      id="fileName"
                      placeholder="Enter file"
                      accept=".xlsx,.xls"
                    />
                    <CustomErrorMessage
                      name="fileName"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <span
                      onClick={() => downloadFile()}
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Download Sample File
                    </span>
                  </div>

                  <div className="mt-3">
                    <div className="d-flex gap-2">
                      <Button
                        className="back-icon-btn-main"
                        onClick={onImportMasterCTR}
                      >
                        <span className="ms-2">Back</span>
                      </Button>
                      <Button className="theme-btn-main" type="submit">
                        {buttonLoading ? <ButtonLoading /> : "Import File"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={onRevision}
        onHide={onRevisionModal}
        className="add-department-modal-main"
        selected={selectedCTR}
        size="xl"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Revisions of {selectedCTR?.code}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <div className="d-flex justify-content-between mt-3 mb-3">
            <div>
              <span style={{ fontWeight: "700" }}> Discipline:</span>{" "}
              {selectedCTR?.deptartment?.deptName}
            </div>
            <div>
              <span style={{ fontWeight: "700" }}>Discipline Code: </span>{" "}
              {selectedCTR?.deptartment?.deptCode}
            </div>
            <div>
              <span style={{ fontWeight: "700" }}>CTR Code:</span>{" "}
              {selectedCTR?.code}
            </div>
          </div>
          <Table responsive bordered className="table table-bordered">
            <thead>
              <tr>
                <th>S.No.</th>

                <th>Title</th>
                <th>Description</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {masterCTRRevisions &&
                masterCTRRevisions.length > 0 &&
                masterCTRRevisions.map((each) => {
                  return (
                    <tr>
                      <td>{each?.sNo}</td>

                      <td>{each?.title}</td>
                      <td>{each?.description}</td>
                      <td style={{ width: "20%" }}>
                        {moment(each?.createdAt).format("DD-MM-YYYY hh:mm")}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>

          <button className="btn btn-primary" onClick={onRevisionModal}>
            Back
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
